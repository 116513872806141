import { useRecoilState } from "recoil";
import { AlertInfoState } from "../../../index";
import { useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";

const SnackbarForInfo = () => {
  const [infoMessage, setInfoMessage] = useRecoilState(AlertInfoState);
  const handleClose = () => {
    setInfoMessage({ message: "" });
  };

  useEffect(() => {
    if (infoMessage.message) {
            // Automatically close the Snackbar after 3 seconds (3000 milliseconds)
            const timer = setTimeout(() => {
                handleClose();
            }, 3000);

            // Clear the timer when the component unmounts or if errorState changes
            return () => clearTimeout(timer);
    }
  }, [infoMessage]);

  return (
    <div
      style={{
        position: "fixed",
        top: 50,
        right: 50,
        background: infoMessage.color ? infoMessage.color : "#456cb0",
        opacity: "70%",
        color: "white",
        zIndex: 99999,
        padding: "10px",
        minWidth: "100px",
        maxWidth: "300px",
        whiteSpace: "normal",
        wordBreak: "break-all",
        borderRadius: "5px",
        display: infoMessage.message ? "flex" : "none",
        justifyContent: "space-between",
      }}
    >
      <InfoIcon></InfoIcon>
      <div
        style={{
          marginTop: "2px",
          marginLeft: "10px",
        }}
      >
        {infoMessage.message ? infoMessage.message : ""}
      </div>
    </div>
  );
};

export default SnackbarForInfo;
