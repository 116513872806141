import React, { useState } from "react";

export const Tab = ({ tabMenuTitle, setItem }) => {
  const [currentTab, clickTab] = useState(0);

  const selectMenuHandler = (index) => {
    clickTab(index);
    setItem(tabMenuTitle[index].name);
  };

  return (
    <>
      <ul className="tab_menu_wrap">
        {tabMenuTitle.map((el, index) => (
          <li
            key={index}
            className={index === currentTab ? "submenu focused" : "submenu"}
            onClick={() => selectMenuHandler(index)}
          >
            {el.name}
          </li>
        ))}
      </ul>
      <div className="desc"></div>
    </>
  );
};

export default Tab;
