import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Grid } from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import jwt_decode from "jwt-decode";
import request from "../config/Axios";

const ErrorPage = () => {
  let navigate = useNavigate();
    useEffect(() => {
        navigate("/");
    }, []);
  return (
      <></>
    // <Grid
    //   container
    //   direction="column"
    //   alignItems="center"
    //   justifyContent="center"
    //   spacing={2}
    // >
    //   <Grid item>
    //     <div style={{ display: "flex" }}>
    //       <WarningAmberIcon
    //         style={{ marginTop: "50px", display: "flex", alignItems: "center" }}
    //         fontSize="large"
    //         color="error"
    //       />
    //       <div
    //         type="text"
    //         style={{
    //           marginTop: "50px",
    //           display: "flex",
    //           alignItems: "center",
    //           marginLeft: "10px",
    //           fontSize: "24px",
    //         }}
    //       >
    //         404 Not Found
    //       </div>
    //     </div>
    //   </Grid>
    //   <Grid item style={{ marginTop: "20px" }}>
    //     <div>요청하신 페이지를 찾을 수 없습니다.</div>
    //     <div style={{ marginTop: "10px", marginBottom: "10px" }}>
    //       입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.
    //     </div>
    //   </Grid>
    // </Grid>
  );
};

export default ErrorPage;
