import React, { useRef, useEffect } from "react";

export const Modal = ({ open, close, title, content, size = "md" }) => {
  const modalRef = useRef(null);

  const handleMouseDown = (event) => {
    const target = event.target;
    if (open && modalRef.current && !modalRef.current.contains(target)) {
      close();
    }
  };

  const handleKeyDown = (event) => {
    if (open && event.key === "Escape") {
      close();
    }
  };

  useEffect(() => {
    // 모달이 열려 있을 때만 ESC 키 이벤트를 리스닝하도록 설정합니다.
    if (open) {
      window.addEventListener("keydown", handleKeyDown);
      modalRef.current.scrollTop = 0;
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거합니다.
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open]);

  return (
    <div onMouseDown={handleMouseDown}>
      <input
        className="modal_state"
        type="checkbox"
        checked={open}
        onChange={() => {}}
      />
      <div className="modal">
        <label className="modal_bg"></label>
        <div
          className={
            size === "sm" ? "modal_inner modal_inner_sm" : "modal_inner"
          }
          ref={modalRef}
        >
          <label className="modal_close" onClick={close}></label>
          <div className="popup">
            <div className="modal_title">{title}</div>
            <div className="wrapper"></div>
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
