import Table from "../../organisms/Table/Table";

const SelectOrgModal = ({
  rows,
  orgType,
  selectOrg,
  getTotalRows,
  search,
  onChangeSearchValue,
  onClickSearchButton,
  page,
  limit,
  totalPages,
  changePage,
  sortParams,
  setSortParams,
}) => {
  const orgColumns = [
    {
      name: "org_name",
      header: orgType === "HOSP" ? "기관명" : "기업명",
      width: "30%",
      customBody: (row) => (
        <td
          key="org_name"
          style={{ cursor: "pointer" }}
          onClick={() => {
            selectOrg(row);
          }}
        >
          {row.org_name}
        </td>
      ),
    },
    {
      name: "org_id",
      header: orgType === "HOSP" ? "기관아이디" : "기업아이디",
      width: "20%",
      customBody: (row) => (
        <td
          key="org_id"
          style={{ cursor: "pointer" }}
          onClick={() => {
            selectOrg(row);
          }}
        >
          {row.org_id}
        </td>
      ),
    },
    {
      name: "org_type",
      header: "유형",
      width: "10%",
      customBody: (row) => (
        <td
          key="org_type"
          style={{ cursor: "pointer" }}
          onClick={() => {
            selectOrg(row);
          }}
        >
          {row.org_type === "HOSP" ? (
            <span className="badge badge_blue">{row.org_type}</span>
          ) : row.org_type === "CORP" ? (
            <span className="badge badge_green">{row.org_type}</span>
          ) : (
            <span className="badge badge_black">{row.org_type}</span>
          )}
        </td>
      ),
    },
    {
      name: "biz_no",
      header: "사업자 번호",
      width: "20%",
      customBody: (row) => (
        <td
          key="biz_no"
          style={{ cursor: "pointer" }}
          onClick={() => {
            selectOrg(row);
          }}
        >
          {row.biz_no}
        </td>
      ),
    },
    {
      name: "president_name",
      header: "대표자",
      width: "20%",
      customBody: (row) => (
        <td
          key="president_name"
          style={{ cursor: "pointer" }}
          onClick={() => {
            selectOrg(row);
          }}
        >
          {row.president_name}
        </td>
      ),
    },
    {
      name: "dtx_code",
      header: "DTx 코드",
      width: "*",
      customHeader: () => <th key="dtx_code" style={{ display: "none" }}></th>,
      customBody: () => <td key="dtx_code" style={{ display: "none" }}></td>,
    },
  ];

  return (
    <Table
      rows={rows}
      columns={orgColumns}
      getTotalRows={getTotalRows}
      search={search}
      onChangeSearchValue={onChangeSearchValue}
      onClickSearchButton={onClickSearchButton}
      page={page}
      limit={limit}
      totalPages={totalPages}
      changePage={changePage}
      sortParams={sortParams}
      setSortParams={setSortParams}
    ></Table>
  );
};

export default SelectOrgModal;
