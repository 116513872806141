import React from "react";
import DynamicTable from "../../organisms/DynamicTable/DynamicTable";

export const CreateLocalCodeInModal = ({
  localCodeData,
  localCodeRows,
  localCodeColumns,
  totalLocalCodeElements,
  closeModal,
  modifyLocalCode,
}) => {
  return (
    <>
      <div className="wrapper">
        {Object.keys(localCodeData).length !== 0 && (
          <ul className="form_wrap mb50">
            <li>
              <label className="name">기관 ID</label>
              <input
                type="text"
                name="org_id"
                value={localCodeData.org_id}
                disabled
              />
            </li>
            <li>
              <label className="name">DTx 코드</label>
              <input
                type="text"
                name="dtx_code"
                value={localCodeData.dtx_code}
                disabled
              />
            </li>
            <li>
              <label className="name">기관명</label>
              <input
                type="text"
                name="hosp_name"
                value={localCodeData.hosp_name}
                disabled
              />
            </li>
            <li>
              <label className="name">Dtx명</label>
              <input
                type="text"
                name="dtx_name"
                value={localCodeData.dtx_name}
                disabled
              />
            </li>
          </ul>
        )}
        <DynamicTable
          rows={localCodeRows}
          columns={localCodeColumns}
          totalElements={totalLocalCodeElements}
        ></DynamicTable>
      </div>
      <div className="btn_wrap">
        <div className="left"></div>
        <div className="center"></div>
        <div className="right">
          <button className="btn btn_gray_line" onClick={closeModal}>
            취소
          </button>
          <button className="btn btn_black" onClick={modifyLocalCode}>
            저장
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateLocalCodeInModal;
