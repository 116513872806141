import React, {useEffect, useState} from "react";
import request from "../config/Axios";
import History from "../components/organisms/History/History";
import {Table} from "../components/organisms/Table/Table";
import Modal from "../components/organisms/Modal/Modal";
import Input from "../components/atoms/Input/Input";
import CreateDTxAPPModal from "../components/templates/CreateDTxAPPModal/CreateDTxAPPModal";
import {AlertErrorState, AlertInfoState} from "..";
import {useRecoilState} from "recoil";
import {
    ButtonPlaceHolder,
    DefaultModalPageSize,
    DefaultPageSize,
    DefaultSortParams,
    MessageCollection,
    MessageColor,
    OrgModalName,
    UnSelectOrgMsg,
    checkNumberWithin365WithSpaces,
    isNumber,
    isRegularStringLength,
    isURL,
} from "../components/Default/DefaultValue";
import SelectOrgModal from "../components/templates/SelectOrgModal/SelectOrgModal";

const ManageDtxApp = () => {
    const [refresh, setRefresh] = useState(false); // 검색에서 페이지 새로고침 되니까 있는게 좋음
    const [modalRefresh, setModalRefresh] = useState(false);
    const [alart, setAlert] = useRecoilState(AlertErrorState);
    const [message, setMessage] = useRecoilState(AlertInfoState);
    const [errorMessage, setErrorMessage] = useState([]);

    // 제목, 브레드크럼
    const title = "DTx앱 정보";
    const breadcrumb = [
        {name: "HOME", link: "./"},
        {name: "DTx 관리"},
        {name: "DTx 정보", link: "./ManageDtxApp"},
    ];

    const pageSize = DefaultPageSize;

    // 모달
    const [modalType, setModalType] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const closeModal = () => {
        _setTurnRows([]);
        setErrorMessage([]);
        setOpenModal((openModal) => !openModal);
    };

    // 서브 모달
    const [openSubModal, setOpenSubModal] = useState(false);
    const closeSubModal = () => {
        setOpenSubModal((openModal) => !openModal);
    };

    //Alert 모달
    const [openAlert, setOpenAlert] = useState("");
    const closeAlert = () => {
        setOpenAlert((openModal) => !openModal);
    };

    // 모달 테이블 관련 변수
    const [totalElements, setTotalElements] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(5);
    const [sortParams, setSortParams] = useState(DefaultSortParams.DEFAULT);

    // 서브 모달 테이블 관련 변수
    const [subModalType, setSubModalType] = useState("");
    const [modalTotalElements, setModalTotalElements] = useState(0);
    const [modalPage, setModalPage] = useState(1);
    const [modalTotalPages, setModalTotalPages] = useState(5);
    const [modalSortParams, setModalSortParams] = useState(
        DefaultSortParams.DEFAULT
    );

    // 검색조회부분
    const [tableSearchItems, setTableSearchItems] = useState([]);
    // 서브 모달 검색 조회
    const [modalTableSearchItems, setModalTableSearchItems] = useState([
        {
            name: ["orgName"],
            placeholder: ["기관명"],
            value: "",
            type: "text",
        },
    ]);

    // ENUM
    const [stateEnum, setStateEnum] = useState({});
    const [marketEnum, setMarketEnum] = useState({});

    const initAppData = {
        dtx_code: "자동으로 생성됩니다.",
        org_name: "",
        dtx_name: "",
        total_turn: 0,
        enable: "true",
        turn_info_list: {},
        install_info: {},
    };

    const initTurnData = {
        turn_no: "",
        edi_code: "",
        use_period: "",
        prcp_valid_period: "",
        unit_purchase_price: "",
        unit_sales_price: "",
    };

    const initInstallData = {
        app_market: Object.values(marketEnum)[0],
        install_url: "",
        desc: "",
        temp_id: "",
    };

    const [totalTurnElements, setTotalTurnElements] = useState(0);
    const [totalInstallElements, setTotalInstallElements] = useState(0);
    const [appData, setAppData] = useState(initAppData);

    // td 내용
    const [turnRows, _setTurnRows] = useState([]);
    const [rows, _setRows] = useState([]);
    const [orgRows, _setOrgRows] = useState([]);
    const [installRows, _setInstallRows] = useState([]);

    // header,body 변경 th 내용
    const columns = [
        {
            name: "dtx_code",
            header: "DTx코드",
            width: "12%",
        },
        {
            name: "dtx_name",
            header: "DTx명",
            width: "21%",
        },
        {
            name: "org_name",
            header: "DTx기업",
            width: "20%",
        },
        {
            name: "total_turn",
            header: "총 회차",
            width: "8%",
        },
        {
            name: "enable",
            header: "사용 상태",
            width: "9%",
            customBody: (row) => (
                <td key={"enable"}>
          <span
              className={
                  row.enable === "true" ? "badge badge_blue" : "badge badge_red"
              }
          >
            {row.enable === "true" ? "사용" : "중지"}
          </span>
                </td>
            ),
        },
        {
            name: "create_at",
            header: "등록일",
            width: "15%",
        },
        {
            name: "appInfo",
            header: "앱 정보",
            width: "7%",
            sort: false,
            customBody: (row) => (
                <td key={"appInfo"}>
                    <label
                        className="btn btn_sm btn_gray_line"
                        onClick={() => {
                            openEditModal(row.dtx_code);
                        }}
                    >
                        수정
                    </label>
                </td>
            ),
        },
        {
            name: "installInfo",
            header: "설치 정보",
            width: "8%",
            sort: false,
            customBody: (row) => (
                <td key={"installInfo"}>
                    <label
                        className="btn btn_sm btn_gray_line"
                        onClick={() => {
                            openEditInstallModal(row.dtx_code);
                        }}
                    >
                        수정
                    </label>
                </td>
            ),
        },
    ];

    const turnColumns = [
        {
            name: "turn_no",
            header: "회차",
            width: "11.5%",
            customBody: (row) => (
                <td key="turn_no">
                    <Input
                        type="text"
                        style={{textAlign: "center"}}
                        className="w45"
                        disabled
                        value={row.turn_no}
                        onChange={(event) => onChangeSettings({event, row}, "turn_no")}
                    />
                </td>
            ),
        },
        {
            name: "edi_code",
            header: "EDI",
            width: "19.5%",
            customBody: (row) => (
                <td key="edi_code">
                    <Input
                        type="text"
                        name="edi_code"
                        className="w110"
                        value={row.edi_code}
                        onBlur={onChangeBlur}
                        disabled={modalType === "modifyApps" ? true : false}
                        onChange={(event) => onChangeSettings({event, row}, "edi_code")}
                    />
                </td>
            ),
        },
        {
            name: "use_period",
            header: "사용기간(일)",
            width: "17.25%",
            customBody: (row) => (
                <td key="use_period">
                    <Input
                        type="text"
                        className="w90"
                        value={row.use_period}
                        onChange={(event) => onChangeSettings({event, row}, "use_period")}
                    />
                </td>
            ),
        },
        {
            name: "prcp_valid_period",
            header: "유효기간(일)",
            width: "17.25%",
            customBody: (row) => (
                <td key="prcp_valid_period">
                    <Input
                        type="text"
                        className="w90"
                        value={row.prcp_valid_period}
                        onChange={(event) =>
                            onChangeSettings({event, row}, "prcp_valid_period")
                        }
                    />
                </td>
            ),
        },
        {
            name: "unit_purchase_price",
            header: "매입단가(원)",
            width: "17.25%",
            customBody: (row) => (
                <td key="unit_purchase_price">
                    <Input
                        type="text"
                        className="w90"
                        value={row.unit_purchase_price}
                        onChange={(event) =>
                            onChangeSettings({event, row}, "unit_purchase_price")
                        }
                    />
                </td>
            ),
        },
        {
            name: "unit_sales_price",
            header: "매출단가(원)",
            width: "17.25%",
            customBody: (row) => (
                <td key="unit_sales_price">
                    <Input
                        type="text"
                        className="w90"
                        value={row.unit_sales_price}
                        onChange={(event) =>
                            onChangeSettings({event, row}, "unit_sales_price")
                        }
                    />
                </td>
            ),
        },
    ];

    const installColumns = [
        {
            name: "app_market",
            header: "마켓",
            width: "30%",
            customBody: (row) => (
                <td key="app_market">
                    <select
                        className={"w180"}
                        name="app_market"
                        value={row.app_market}
                        onChange={(event) =>
                            onChangeInstallSettings({event, row}, "app_market")
                        }
                    >
                        {Object.entries(marketEnum).map((market) => {
                            return (
                                <option key={market[0]} value={market[1]}>
                                    {market[0]}
                                </option>
                            );
                        })}
                    </select>
                </td>
            ),
        },
        {
            name: "install_url",
            header: "URL",
            width: "30%",
            customBody: (row) => (
                <td key="install_url">
                    <Input
                        type="text"
                        className={"w210"}
                        value={row.install_url}
                        name={"install_url"}
                        onBlur={onChangeBlur}
                        placeholder={"ex) https://example.com"}
                        onChange={(event) =>
                            onChangeInstallSettings({event, row}, "install_url")
                        }
                    />
                </td>
            ),
        },
        {
            name: "desc",
            header: "설명",
            width: "30%",
            customBody: (row) => (
                <td key="desc">
                    <Input
                        type="text"
                        className={"w210"}
                        value={row.desc}
                        onChange={(event) =>
                            onChangeInstallSettings({event, row}, "desc")
                        }
                    />
                </td>
            ),
        },
        {
            name: "add_rows",
            width: "10%",
            customHeader: () => (
                <th key="add_rows">
                    <div className="action_container">
                        <button className="btn btn_xs btn_black" onClick={addInstall}>
                            행추가
                        </button>
                    </div>
                </th>
            ),
            customBody: (row) => (
                <td key={"delete_rows"} className="action_container">
                    <button
                        className="btn btn_xs btn_gray"
                        onClick={() => {
                            deleteInstall(row.temp_id);
                        }}
                    >
                        행삭제
                    </button>
                </td>
            ),
        },
        {
            name: "temp_id",
            header: "임시 아이디",
            customHeader: () => <th key="temp_id" style={{display: "none"}}></th>,
            customBody: () => <td key="temp_id" style={{display: "none"}}></td>,
        },
    ];

    const onChangeModalSearchValue = (event) => {
        if (event.key === "Enter") {
            onClickModalSearchButton();
        }
        const {name, value} = event.target;
        setModalTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === name) {
                    return {...item, value};
                }
                return item;
            })
        );
    };

    const onClickModalSearchButton = () => {
        setModalPage(1);
        setModalRefresh((prev) => !prev);
    };

    const selectOrg = (row) => {
        if (subModalType === "CORP2") {
            setTableSearchItems((prevState) =>
                prevState.map((item) => {
                    if (item.name.join(",") === "corpFilter") {
                        return {...item, value: row.org_id, display: row.org_name};
                    }
                    return item;
                })
            );
        } else {
            setAppData((prevState) => ({
                ...prevState,
                org_id: row.org_id,
                org_name: row.org_name,
            }));
        }
        closeSubModal();
    };

    const onChangeSettings = ({event, row}, name) => {
        const {value} = event.target;
        if (name === "unit_purchase_price" || name === "unit_sales_price") {
            if (isNumber(value) === true) {
                const updatedRows = turnRows.map((item) =>
                    item.turn_no === row.turn_no ? {...item, [name]: value} : item
                );
                _setTurnRows(updatedRows);
            }
        } else if (name === "use_period" || name === "prcp_valid_period") {
            if (checkNumberWithin365WithSpaces(value) === true) {
                const updatedRows = turnRows.map((item) =>
                    item.turn_no === row.turn_no ? {...item, [name]: value} : item
                );
                _setTurnRows(updatedRows);
            }
        } else {
            const updatedRows = turnRows.map((item) =>
                item.turn_no === row.turn_no ? {...item, [name]: value} : item
            );
            _setTurnRows(updatedRows);
        }
    };

    const onChangeInstallSettings = ({event, row}, name) => {
        const {value} = event.target;
        const updatedRows = installRows.map((item) =>
            item.temp_id === row.temp_id ? {...item, [name]: value} : item
        );

        _setInstallRows(updatedRows);
    };

    const addInstall = () => {
        if (totalInstallElements === 30) {
            return;
        }
        const maxTempId = installRows.reduce(
            (max, item) => (item.temp_id > max ? item.temp_id : max),
            -1
        );

        _setInstallRows((prevState) => [
            ...prevState,
            {
                ...initInstallData,
                temp_id: maxTempId + 1,
            },
        ]);
        setTotalInstallElements((prev) => prev + 1);
    };

    const deleteInstall = (id) => {
        const updatedInstallRows = installRows.filter(
            (item) => item.temp_id !== id
        );
        _setInstallRows(updatedInstallRows);
        setTotalInstallElements((prev) => prev - 1);
    };

    const saveInstall = async () => {
        const updateInstallRows = installRows.map((item) => {
            const {temp_id, ...rest} = item;
            return rest;
        });
        let flag = true;
        updateInstallRows.map((data) => {
            if (!isURL(data.install_url)) {
                flag = false;
            }
        });
        if (flag === false) {
            setMessage({
                message: MessageCollection.CHECKURL,
                color: MessageColor.RED,
            });
            return;
        }

        if (openAlert !== true) {
            setOpenAlert(true);
            return;
        }

        const dtx_code = appData.dtx_code;
        try {
            await request.put(`apps-install/${dtx_code}`, updateInstallRows);

            setRefresh((prev) => !prev);
            setMessage({message: MessageCollection.MODIFY});
            closeAlert();
            closeModal();
        } catch (error) {
            setAlert(error);
        }
    };

    const changeTurn = (value) => {
        if (value <= 0 && appData.total_turn === 0) {
            return;
        }
        if (appData.total_turn >= 30 && value === 1) {
            return;
        }

        setAppData((prevState) => ({
            ...prevState,
            total_turn: appData.total_turn + value,
        }));
        if (value > 0) {
            _setTurnRows((prevState) => [
                ...prevState,
                {...initTurnData, turn_no: turnRows.length + value},
            ]);
        } else {
            const updatedTurnRows = [...turnRows];
            updatedTurnRows.pop();
            _setTurnRows(updatedTurnRows);
        }

        setTotalTurnElements(turnRows.length + value);
    };

    const createApp = async () => {
        const refinedAppData = {...appData};

        refinedAppData.turn_info_list = turnRows;

        const org_id = refinedAppData.org_id;

        if (refinedAppData.org_id === undefined || refinedAppData.org_id === "") {
            setErrorMessage(["org_id", "공백일 수 없습니다"]);
            return;
        }

        if (refinedAppData.dtx_name === "") {
            setErrorMessage(["dtx_name", "공백일 수 없습니다"]);
            return;
        }
        if (!isRegularStringLength(refinedAppData.dtx_name, 4, 32)) {
            setErrorMessage(["dtx_name", "4글자 이상 32글자 이하로 작성해주세요"]);
            return;
        }
        if (refinedAppData.total_turn === 0) {
            setErrorMessage(["total_turn", "최소 1개 이상의 회차를 입력해주세요"]);
            return;
        }
        if (openAlert !== true) {
            setOpenAlert(true);
            return;
        }

        delete refinedAppData.dtx_code;
        delete refinedAppData.org_name;
        if (refinedAppData.enable === "true") {
            refinedAppData.enable = true;
        } else if (refinedAppData.enable === "false") {
            refinedAppData.enable = false;
        }

        const edi_code = [];
        refinedAppData.turn_info_list.map((data) => {
            if (data.edi_code.length > 0) {
                edi_code.push(data.edi_code)
            }
            ;
        });

        if (edi_code.length > 0) {
            edi_code.map(async (data) => {
                try {
                    await request.get(`apps/edi-is-duplicated/${data}`);
                } catch (error) {
                    setAlert(error);
                }
            });
        }

        delete refinedAppData.org_id;
        delete refinedAppData.install_info;

        try {
            await request.post(`apps/${org_id}`, {...refinedAppData});

            setPage(1);
            setTableSearchItems((prevState) =>
                prevState.map((item, index) => {
                    if (index === 0 || index === 1) {
                        return {...item, value: ""};
                    }
                    return item;
                })
            );
            setRefresh((prev) => !prev);
            setMessage({message: MessageCollection.CREATE});
            closeAlert();
            closeModal();
        } catch (error) {
            setAlert(error);
        }
    };

    const modifyApp = async () => {
        const refinedAppData = {...appData};

        if (refinedAppData.dtx_name === "") {
            setErrorMessage(["dtx_name", "공백일 수 없습니다"]);
            return;
        }
        if (!isRegularStringLength(refinedAppData.dtx_name, 4, 32)) {
            setErrorMessage(["dtx_name", "4글자 이상 32글자 이하로 작성해주세요"]);
            return;
        }

        refinedAppData.turn_info_list = turnRows;
        const dtx_code = refinedAppData.dtx_code;

        delete refinedAppData.dtx_code;
        delete refinedAppData.org_name;
        if (refinedAppData.enable === "true") {
            refinedAppData.enable = true;
        } else if (refinedAppData.enable === "false") {
            refinedAppData.enable = false;
        }

        if (openAlert !== true) {
            setOpenAlert(true);
            return;
        }

        try {
            await request.put(`apps/${dtx_code}`, refinedAppData);
            setRefresh((prev) => !prev);
            setMessage({message: MessageCollection.MODIFY});
            closeAlert();
            closeModal();
        } catch (error) {
            setAlert(error);
        }
    };

    // 입력창값이 변경될때
    const onChangeSearchValue = (event) => {
        if (event.key === "Enter") {
            onClickSearchButton();
        }
        const {name, value} = event.target;
        setTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === name) {
                    return {...item, value};
                }
                return item;
            })
        );
    };

    // 검색 영역에서 검색버튼 눌렀을때 뜨는 알림창
    const onClickSearchButton = () => {
        if (tableSearchItems[0].value === "") {
            setMessage({message: UnSelectOrgMsg, color: "#48b878"});
        }
        setPage(1);
        setRefresh((prev) => !prev);
    };
    // 테이블에다가 사용자 등록하는 모달 버튼
    const tableButton = {
        name: "DTx 등록",
        onClick: () => {
            setModalType("createApps");
            setErrorMessage([]);
            setAppData(initAppData);
            setSubModalType("CORP");
            setOpenModal(true);
        },
    };

    const onChangeAppData = (event) => {
        const {name, value} = event.target;
        if (name === "total_turn") {
            _setTurnRows((prevState) => [
                ...prevState,
                {...initTurnData, turn_no: turnRows.length + 1},
            ]);
            setTotalTurnElements(turnRows.length + 1);
        }
        setAppData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const openEditModal = async (id) => {
        const selectedAppsDataResponse = await request.get(`apps/${id}`);

        const selectedAppData = Object.keys(appData).reduce((obj, key) => {
            obj[key] =
                selectedAppsDataResponse.data[key] !== null
                    ? selectedAppsDataResponse.data.data[key]
                    : "";
            return obj;
        }, {});

        _setTurnRows(selectedAppData.turn_info_list);
        setTotalTurnElements(
            selectedAppData.turn_info_list === null
                ? 0
                : selectedAppData.turn_info_list.length
        );
        setAppData(selectedAppData);
        setModalType("modifyApps");
        setSubModalType("CORP");
        setOpenModal(true);
    };

    const openEditInstallModal = async (id) => {
        const selectedAppsInstallResponse = await request.get(`apps-install/${id}`);

        const selectedAppData = Object.keys(appData).reduce((obj, key) => {
            obj[key] =
                selectedAppsInstallResponse.data[key] !== null
                    ? selectedAppsInstallResponse.data.data[key]
                    : "";
            return obj;
        }, {});

        if (selectedAppData.install_info === null) {
            _setInstallRows([]);
            setTotalInstallElements(0);
        } else {
            for (let i = 0; i < selectedAppData.install_info.length; i++) {
                selectedAppData.install_info[i].temp_id = i;
            }

            _setInstallRows(selectedAppData.install_info);
            setTotalInstallElements(selectedAppData.install_info.length);
        }
        setAppData(selectedAppData);
        setModalType("modifyInstall");
        setOpenModal(true);
    };

    const openSelectOrgModal = () => {
        setModalPage(1);
        setModalTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === "orgName") {
                    return {...item, value: ""};
                }
                return item;
            })
        );
        setOpenSubModal(true);
    };

    const onClickOrgFilter = () => {
        setModalPage(1);
        setSubModalType("CORP2");
        setModalTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === "orgName") {
                    return {...item, value: ""};
                }
                return item;
            })
        );
        setOpenSubModal(true);
    };

    const onClickOrgFilterForRemove = () => {
        setTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === "corpFilter") {
                    return {...item, value: "*", display: ButtonPlaceHolder.CORP};
                }
                return item;
            })
        );
    };

    const onChangeBlur = async (event) => {
        const {name, value} = event.target;
        let checkDuplicated = [];

        if (name === "edi_code" && value.length > 0) {

            //여러개 한번에 등록할때
            if (turnRows.length > 1) {
                turnRows.forEach((row) => {
                    if (!checkDuplicated.includes(row.edi_code)) {
                        checkDuplicated.push(row.edi_code);
                    } else {
                        if (row.edi_code.length > 0) {
                            row.edi_code = "";
                            setMessage({
                                message:
                                    "중복된 EDI를 사용할 수 없습니다. 다른 EDI 명을 사용해주세요.",
                                color: MessageColor.RED,
                            });
                        }
                    }
                })
            }

            const checkDuplicateEid = await request.get(`apps/edi-is-duplicated/${value}`);
            if (checkDuplicateEid === undefined) {
                let checkidx;
                turnRows.forEach((row, idx) => {
                    if (row.edi_code === value) checkidx = idx;
                })
                turnRows[checkidx].edi_code = ""
                setMessage({
                    message:
                        "해당 EDI는 이미 등록 되어있습니다. 다른 EDI 명을 사용해주세요.",
                    color: MessageColor.RED,
                });
            }
        }
    };

    useEffect(() => {
        (async () => {
            setTableSearchItems([
                {
                    name: ["corpFilter"],
                    type: "labelButton",
                    onClick: onClickOrgFilter,
                    onClickRemove: onClickOrgFilterForRemove,
                    display: ButtonPlaceHolder.CORP,
                    value: "*",
                },
                {
                    name: ["code", "name"],
                    placeholder: ["DTx코드, DTx명"],
                    value: "",
                    type: "text",
                },
            ]);

            setStateEnum({사용: true, 중지: false});

            const marketResponse = await request.get(`commons/type/market`);
            const updateMarketEnum = {};
            marketResponse.data.data.map((data) => {
                updateMarketEnum[data] = data;
            });
            setMarketEnum(updateMarketEnum);
        })();
    }, []);


    useEffect(() => {
        (async () => {
            const organsResponse = await request.get("organs", {
                params: {
                    page: modalPage - 1,
                    size: DefaultModalPageSize,
                    sort: modalSortParams,
                    name: modalTableSearchItems[0].value.trim(),
                    type: "CORP",
                },
            });
            _setOrgRows(organsResponse.data.data.content);
            setModalTotalPages(organsResponse.data.data.totalPages);
            setModalTotalElements(organsResponse.data.data.totalElements);
        })();
    }, [openSubModal, modalPage, modalSortParams, modalRefresh]);

    useEffect(() => {
        (async () => {
            const appsData = await request.get(`apps`, {
                params: {
                    page: page - 1,
                    size: pageSize,
                    id:
                        tableSearchItems[0] === undefined ? "*" : tableSearchItems[0].value,
                    search_word_like:
                        tableSearchItems[1] === undefined
                            ? "*"
                            : tableSearchItems[1].value.trim(),
                    sort: sortParams,
                },
            });
            _setRows(appsData.data.data.content);
            setTotalPages(appsData.data.data.totalPages);
            setTotalElements(appsData.data.data.totalElements);
        })();
    }, [refresh, page, sortParams]);

    // 최종 화면 나오는 곳
    return (
        <>
            <div className="wrapper">
                {<History title={title} breadcrumb={breadcrumb}/>}
                <hr/>
                {
                    <Table
                        rows={rows}
                        columns={columns}
                        getTotalRows={totalElements}
                        search={tableSearchItems}
                        onChangeSearchValue={onChangeSearchValue}
                        onClickSearchButton={onClickSearchButton}
                        button={tableButton}
                        page={page}
                        totalPages={totalPages}
                        changePage={setPage}
                        sortParams={sortParams}
                        setSortParams={setSortParams}
                    />
                }
            </div>
            {
                <Modal
                    open={openModal}
                    close={closeModal}
                    title={
                        modalType === "createApps"
                            ? "DTx 등록"
                            : modalType === "modifyApps"
                                ? "DTx앱 정보 수정"
                                : modalType === "modifyInstall"
                                    ? "DTx앱 설치정보 수정"
                                    : ""
                    }
                    content={
                        <CreateDTxAPPModal
                            appData={appData}
                            onChangeAppData={onChangeAppData}
                            installRows={installRows}
                            installColumns={installColumns}
                            totalInstallElements={totalInstallElements}
                            totalTurnElements={totalTurnElements}
                            saveInstall={saveInstall}
                            changeTurn={changeTurn}
                            stateEnum={stateEnum}
                            turnRows={turnRows}
                            turnColumns={turnColumns}
                            createApp={createApp}
                            modifyApp={modifyApp}
                            closeModal={closeModal}
                            openSelectOrgModal={openSelectOrgModal}
                            modalType={modalType}
                            errorMessage={errorMessage}
                        />
                    }
                />
            }
            {
                <Modal
                    open={openSubModal}
                    close={closeSubModal}
                    title={
                        subModalType === "CORP"
                            ? ` DTx 등록 - ${OrgModalName.CORP}`
                            : OrgModalName.CORP
                    }
                    content={
                        <SelectOrgModal
                            rows={orgRows}
                            orgType={"CORP"}
                            selectOrg={selectOrg}
                            getTotalRows={modalTotalElements}
                            search={modalTableSearchItems}
                            onChangeSearchValue={onChangeModalSearchValue}
                            onClickSearchButton={onClickModalSearchButton}
                            page={modalPage}
                            limit={DefaultModalPageSize}
                            totalPages={modalTotalPages}
                            changePage={setModalPage}
                            sortParams={modalSortParams}
                            setSortParams={setModalSortParams}
                        ></SelectOrgModal>
                    }
                />
            }
            {
                <Modal
                    size={"sm"}
                    open={openAlert}
                    close={closeAlert}
                    title={"DTx앱 정보"}
                    content={
                        <div>
                            <div style={{marginBottom: "30px"}}>정말 저장하시겠습니까?</div>
                            <div style={{display: "flex", justifyContent: "right"}}>
                                <button className="btn btn_gray_line" onClick={closeAlert}>
                                    취소
                                </button>
                                <button
                                    style={{marginLeft: "30px"}}
                                    className="btn btn_black"
                                    onClick={
                                        modalType === "createApps"
                                            ? createApp
                                            : modalType === "modifyApps"
                                                ? modifyApp
                                                : modalType === "modifyInstall"
                                                    ? saveInstall
                                                    : () => {
                                                    }
                                    }
                                >
                                    확인
                                </button>
                            </div>
                        </div>
                    }
                />
            }
        </>
    );
};

export default ManageDtxApp;
