import {Outlet} from "react-router-dom";
import {useNavigate, useLocation} from "react-router-dom";
import logo from "../assets/images/header_logo.png";
import React, {useEffect, useState} from "react";
import request from "../config/Axios";
import {useRecoilState} from "recoil";
import jwt_decode from "jwt-decode";
import {UserDataState} from "..";
import Snackbar from "../components/organisms/Snackbar/Snackbar";
import AxiosHandler from "../config/AxiosHandler";

const LayOuts = () => {
    AxiosHandler();
  let navigate = useNavigate();

  const {pathname} = useLocation();
  const [userData, setUserData] = useRecoilState(UserDataState);
  const [jwtToken, setJwtToken] = useState(null);


  const logout = async () => {
    await request.post(`auth/logout`);
      localStorage.removeItem("jwt");
      localStorage.removeItem("name");
      localStorage.removeItem("uuid");
    navigate("/login");
  };

  useEffect(() => {
    (async () => {
      if (localStorage.getItem("jwt") === null) {
        navigate("/login");
      } else {
        setJwtToken(jwt_decode(localStorage.getItem("jwt").split(" ")[1]).role);
      }
      window.scrollTo(0, 0);
    })();
  }, [pathname]);

  useEffect(() => {
    (async () => {
      const jwtDecode = jwt_decode(localStorage.getItem("jwt"));
      const user_id = jwtDecode.sub;
      const userDataResponse = await request.get(`users/${user_id}`);
      setUserData(userDataResponse.data.data);
    })();
  }, []);

  return (
      <>
          <Snackbar error={{}}></Snackbar>
        <header className="header">
          <div className="header_container">
            <h1
                className="header_logo"
                onClick={() => {
                  navigate("/");
                }}
            >
              <img src={logo} alt="CONNECT DTx"/>
            </h1>
            <ul className="header_user">
              <li>
                {userData.org_type === "HOSP" ? (
                    <span className="badge_main badge_blue">
                  {userData.org_name}
                </span>
                ) : userData.org_type === "CORP" ? (
                    <span className="badge_main badge_green">
                  {userData.org_name}
                </span>
                ) : (
                    <span className="badge_main badge_black">
                  {userData.org_name}
                </span>
                )}
              </li>
              <li style={{marginLeft: "5px"}} className="user">
                <span>{localStorage.getItem("name")}</span> 님
              </li>
              {/* <!-- <li className="link"><a href="../sub/login.html">로그인</a></li> --> */}
              <li className="link" onClick={logout}>
                <button onClick={logout}>로그아웃</button>
              </li>
            </ul>
          </div>
        </header>
        {/* <!-- // header -->
    <!-- container --> */}
        <div id="container" className="container">
          {/* <!-- side-menu --> */}
          <div className="side_menu">
            <ul>
              <li className="dep_1">
                <i className="icon menu_1"></i>
                <p
                    onClick={() => {
                      navigate("/");
                    }}
                >
                  HOME
                </p>
              </li>
              <li className="dep_1">
                <i className="icon menu_2"></i>
                <p
                    onClick={() => {
                      navigate("/ManageOrganization");
                    }}
                >
                  플랫폼 운영
                </p>
                <ul>
                  <li
                      className="dep_2"
                      style={
                        pathname === "/ManageOrganization"
                            ? {fontWeight: "600"}
                            : {}
                      }
                      onClick={() => {
                        navigate("/ManageOrganization");
                      }}
                  >
                    기관 정보(의료&DTx)
                  </li>
                  <li
                      className="dep_2"
                      style={
                        pathname === "/ManageUser" ? {fontWeight: "600"} : {}
                      }
                      onClick={() => {
                        navigate("/ManageUser");
                      }}
                  >
                    사용자 정보
                  </li>
                </ul>
              </li>
              <li className="dep_1">
                <i className="icon menu_3"></i>
                <p
                    onClick={() => {
                      navigate("/ManageDtxApp");
                    }}
                >
                  DTx 관리
                </p>
                <ul>
                  <li
                      className="dep_2"
                      style={
                        pathname === "/ManageDtxApp" ? {fontWeight: "600"} : {}
                      }
                      onClick={() => {
                        navigate("/ManageDtxApp");
                      }}
                  >
                    DTx앱 정보
                  </li>
                  <li
                      className="dep_2"
                      style={
                        pathname === "/ManageHospLocalCode"
                            ? {fontWeight: "600"}
                            : {}
                      }
                      onClick={() => {
                        navigate("/ManageHospLocalCode");
                      }}
                  >
                    병원 별 로컬코드
                  </li>
                  <li
                      className="dep_2"
                      style={
                        pathname === "/ManageDtxPrice" ? {fontWeight: "600"} : {}
                      }
                      onClick={() => {
                        navigate("/ManageDtxPrice");
                      }}
                  >
                    병원 별 DTx 단가
                  </li>
                </ul>
              </li>
              <li className="dep_1">
                <i className="icon menu_4 "></i>
                <p
                    onClick={() => {
                      navigate("/InquirePrcpView");
                    }}
                >
                  데이터 관리
                </p>
                <ul>
                  <li
                      className="dep_2"
                      style={
                        pathname === "/InquirePrcpView" ? {fontWeight: "600"} : {}
                      }
                      onClick={() => {
                        navigate("/InquirePrcpView");
                      }}
                  >
                    처방&진료 정보
                  </li>
                  <li
                      className="dep_2"
                      style={
                        pathname === "/InquireDtxUsage" ? {fontWeight: "600"} : {}
                      }
                      onClick={() => {
                        navigate("/InquireDtxUsage");
                      }}
                  >
                    DTx사용 정보
                  </li>
                </ul>
              </li>
              <li className="dep_1">
                <i className="icon menu_5"></i>
                <p
                    onClick={() => {
                      navigate("/UserSelfAuth");
                    }}
                >
                  동의서 관리
                </p>
                <ul>
                  <li
                      className="dep_2"
                      style={
                        pathname === "/UserSelfAuth" ? {fontWeight: "600"} : {}
                      }
                      onClick={() => {
                        navigate("/UserSelfAuth");
                      }}
                  >
                    본인 확인
                  </li>
                  <li
                      className="dep_2"
                      style={
                        pathname === "/PersonalInfoAgreement"
                            ? {fontWeight: "600"}
                            : {}
                      }
                      onClick={() => {
                        navigate("/PersonalInfoAgreement");
                      }}
                  >
                    정보제공 동의
                  </li>
                </ul>
              </li>
              <li className="dep_1">
                <i className="icon menu_6"></i>
                <p
                    onClick={() => {
                      navigate("/HospSettlement");
                    }}
                >
                  정산 관리
                </p>
                <ul>
                  <li
                      className="dep_2"
                      style={
                        pathname === "/HospSettlement" ? {fontWeight: "600"} : {}
                      }
                      onClick={() => {
                        navigate("/HospSettlement");
                      }}
                  >
                    의료기관
                  </li>
                  <li
                      className="dep_2"
                      style={
                        pathname === "/DtxCorpSettlement"
                            ? {fontWeight: "600"}
                            : {}
                      }
                      onClick={() => {
                        navigate("/DtxCorpSettlement");
                      }}
                  >
                    DTx 기업
                  </li>
                </ul>
              </li>
              <li className="dep_1">
                <i className="icon menu_7"></i>
                <p
                    onClick={() => {
                      navigate("/OprLog");
                    }}
                >
                  이력 관리
                </p>
                <ul>
                  <li
                      className="dep_2"
                      style={pathname === "/OprLog" ? {fontWeight: "600"} : {}}
                      onClick={() => {
                        navigate("/OprLog");
                      }}
                  >
                    운영 로그
                  </li>
                  <li
                      className="dep_2"
                      style={pathname === "/AuditLog" ? {fontWeight: "600"} : {}}
                      onClick={() => {
                        navigate("/AuditLog");
                      }}
                  >
                    감사 로그
                  </li>
                </ul>
              </li>
              <li className="dep_1">
                <i className="icon menu_8"></i>
                <p
                    onClick={() => {
                      navigate("/SystemConfig");
                    }}
                >
                  설정
                </p>
                <ul>
                  <li
                      className="dep_2"
                      style={
                        pathname === "/SystemConfig" ? {fontWeight: "600"} : {}
                      }
                      onClick={() => {
                        navigate("/SystemConfig");
                      }}
                  >
                    시스템 설정
                  </li>
                </ul>
              </li>
            </ul>
            <small className="copyright">
              © connectDTx. All rights reserved.
            </small>
          </div>
          {/* <!-- side-menu -->
      <!-- content_wrap --> */}
          <Outlet></Outlet>
        </div>
      </>
  );
};

export default LayOuts;
