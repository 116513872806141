import React, {useEffect, useState} from "react";
import request from "../config/Axios";
import History from "../components/organisms/History/History";
import {Table} from "../components/organisms/Table/Table";
import Modal from "../components/organisms/Modal/Modal";
import CreateUserInModal from "../components/templates/CreateUserInModal/CreateUserInModal";
import {useRecoilState} from "recoil";
import {AlertErrorState, AlertInfoState} from "..";
import {
    DefaultModalPageSize,
    DefaultPageSize,
    DefaultSortParams,
    MessageCollection,
    MessageColor,
    OrgModalName,
    checkMaxLength,
    isValidEmail,
    isValidId,
    isValidPhoneNumber,
} from "../components/Default/DefaultValue";

const ManageUser = () => {
    const [refresh, setRefresh] = useState(false); // 검색에서 페이지 새로고침 되니까 있는게 좋음
    const [modalRefresh, setModalRefresh] = useState(false);
    const [alart, setAlert] = useRecoilState(AlertErrorState);
    const [message, setMessage] = useRecoilState(AlertInfoState);
    const [errorMessage, setErrorMessage] = useState([]);

    // 제목, 브레드크럼
    const title = "사용자 정보";
    const breadcrumb = [
        {name: "HOME", link: "./"},
        {name: "플랫폼 운영"},
        {name: "사용자 정보", link: "./ManageUser"},
    ];

    const pageSize = DefaultPageSize;

    // 모달
    const [modalType, setModalType] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const closeModal = () => {
        setErrorMessage([]);
        setOpenModal((openModal) => !openModal);
    };

    // 서브 모달
    const [subModalType, setSubModalType] = useState("");
    const [openSubModal, setOpenSubModal] = useState(false);
    const closeSubModal = () => {
        setOpenSubModal((openModal) => !openModal);
    };

    //Alert 모달
    const [openAlert, setOpenAlert] = useState("");
    const closeAlert = () => {
        setOpenAlert((openModal) => !openModal);
    };

    // 테이블 관련 변수
    const [totalElements, setTotalElements] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(5);
    const [sortParams, setSortParams] = useState(DefaultSortParams.DEFAULT);

    // 모달 테이블 관련 변수
    const [modalTotalElements, setModalTotalElements] = useState(0);
    const [modalPage, setModalPage] = useState(1);
    const [modalTotalPages, setModalTotalPages] = useState(5);
    const [modalSortParams, setModalSortParams] = useState(
        DefaultSortParams.DEFAULT
    );

    // 검색 조회
    const [tableSearchItems, setTableSearchItems] = useState([
        {
            name: ["id", "name", "email"],
            placeholder: ["아이디", " 이름", " 이메일"],
            value: "",
            type: "text",
        },
    ]);
    // 서브 모달 검색 조회
    const [modalTableSearchItems, setModalTableSearchItems] = useState([
        {
            name: ["orgName"],
            placeholder: ["기관명"],
            value: "",
            type: "text",
        },
    ]);

    // ENUM
    const [roleEnum, setRoleEnum] = useState({});
    const [newRoleEnum, setNewRoleEnum] = useState({});

    // td 내용
    const [rows, _setRows] = useState([]);
    const [orgRows, _setOrgRows] = useState([]);

    // header,body 변경 th 내용
    const columns = [
        {
            name: "id",
            header: "아이디",
            width: "13%",
            customBody: (row) => <td key="id">{row.id}</td>,
        },

        {
            name: "name",
            header: "이름",
            width: "9%",
        },
        {
            name: "email",
            header: "이메일",
            width: "17%",
        },
        {
            name: "mobile_num",
            header: "전화번호",
            width: "10%",
        },
        {
            name: "role",
            header: "권한",
            width: "11%",
            customBody: (row) => (
                <td key="role">
                    <span className="badge badge_black">{row.role}</span>
                </td>
            ),
        },
        {
            name: "org_name",
            header: "소속",
            width: "18%",
        },
        {
            name: "create_at",
            header: "등록일",
            width: "15%",
        },
        {
            name: "update",
            header: "수정",
            width: "7%",
            sort: false,
            customBody: (row) => (
                <td key={"update"}>
                    <label
                        className="btn btn_sm btn_gray_line"
                        onClick={() => {
                            openEditModal(row.id);
                        }}
                    >
                        수정
                    </label>
                </td>
            ),
        },
    ];

    const orgColumns = [
        {
            name: "org_name",
            header: "기관명",
            width: "30%",
            customBody: (row) => (
                <td
                    key="org_name"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        selectOrg(row);
                    }}
                >
                    {row.org_name}
                </td>
            ),
        },
        {
            name: "org_id",
            header: "기관명",
            width: "20%",
            customBody: (row) => (
                <td
                    key="org_id"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        selectOrg(row);
                    }}
                >
                    {row.org_id}
                </td>
            ),
        },
        {
            name: "org_type",
            header: "유형",
            width: "10%",
            customBody: (row) => (
                <td
                    key="org_type"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        selectOrg(row);
                    }}
                >
                    {row.org_type === "HOSP" ? (
                        <span className="badge badge_blue">{row.org_type}</span>
                    ) : row.org_type === "CORP" ? (
                        <span className="badge badge_green">{row.org_type}</span>
                    ) : (
                        <span className="badge badge_black">{row.org_type}</span>
                    )}
                </td>
            ),
        },
        {
            name: "biz_no",
            header: "사업자 번호",
            width: "20%",
            customBody: (row) => (
                <td
                    key="biz_no"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        selectOrg(row);
                    }}
                >
                    {row.biz_no}
                </td>
            ),
        },
        {
            name: "president_name",
            header: "대표자",
            width: "20%",
            customBody: (row) => (
                <td
                    key="president_name"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        selectOrg(row);
                    }}
                >
                    {row.president_name}
                </td>
            ),
        },
        {
            name: "dtx_code",
            header: "DTx 코드",
            width: "*",
            customHeader: () => <th key="dtx_code" style={{display: "none"}}></th>,
            customBody: () => <td key="dtx_code" style={{display: "none"}}></td>,
        },
    ];

    // User 초기 값
    const initUserData = {
        id: "",
        role: Object.values(roleEnum)[0],
        name: "",
        email: "",
        mobile_num: "",
        org_id: "",
        org_name: "",
    };

    const [userData, setUserData] = useState(initUserData);

    const openEditModal = async (id) => {
        const userResponse = await request.get(`users/${id}`);
        const selectedUserData = Object.keys(userData).reduce((obj, key) => {
            obj[key] =
                userResponse.data[key] !== null ? userResponse.data.data[key] : "";
            return obj;
        }, {});

        const updatedRoleEnum = {};
        let updatedRoleValue = "";
        const updatedUserdata = {...userData};

        if (selectedUserData.org_id.substr(0, 4) === "HOSP") {
            for (const key in roleEnum) {
                if (roleEnum[key] === "ROLE_HOSPITAL") {
                    updatedRoleEnum[key] = roleEnum[key];
                    updatedRoleValue = "ROLE_HOSPITAL";
                    selectedUserData.role = updatedRoleValue;
                    setUserData(updatedUserdata);
                }
            }
        }
        if (selectedUserData.org_id.substr(0, 4) === "CORP") {
            for (const key in roleEnum) {
                if (roleEnum[key] === "ROLE_DTX_CORP") {
                    updatedRoleEnum[key] = roleEnum[key];
                    updatedRoleValue = "ROLE_DTX_CORP";
                    selectedUserData.role = updatedRoleValue;
                    setUserData(updatedUserdata);
                }
            }
        }
        if (selectedUserData.org_id.substr(0, 4) === "PLAT") {
            for (const key in roleEnum) {
                if (
                    roleEnum[key] === "ROLE_ADMIN" ||
                    roleEnum[key] === "ROLE_PLATFORM_MANAGER"
                ) {
                    updatedRoleEnum[key] = roleEnum[key];
                }
            }
        }
        setNewRoleEnum(updatedRoleEnum);
        setUserData(selectedUserData);
        setModalType("modifyUser");
        setOpenModal(true);
    };

    const createUser = async () => {
        setErrorMessage([]);

        if (!isValidId(userData.id)) {
            setErrorMessage([
                "id",
                "6~20자의 영문 대소문자 + 숫자만 사용 가능합니다",
            ]);
            return;
        }
        if (userData.org_id === "") {
            setErrorMessage(["org_id", "소속 기관을 선택해주세요."]);
            return;
        }
        if (userData.name === "") {
            setErrorMessage(["name", "이름은 공백일 수 없습니다."]);
            return;
        }
        if (!checkMaxLength(userData.name, 32)) {
            setErrorMessage(["name", "이름은 32자 이하로 입력해주세요."]);
            return;
        }
        if (!isValidEmail(userData.email)) {
            setErrorMessage(["email", "올바른 이메일 형식을 입력해주세요."]);
            return;
        }
        if (!isValidPhoneNumber(userData.mobile_num)) {
            setErrorMessage(["mobile_num", "올바른 전화번호 형식을 입력해주세요."]);
            return;
        }
        if (openAlert !== true) {
            setOpenAlert(true);
            return;
        }
        const refinedUserData = {...userData};
        delete refinedUserData.org_name;

        try {
            await request.post(`users`, {
                ...refinedUserData,
            });
            setTableSearchItems((prevState) =>
                prevState.map((item, index) => {
                    if (index === 0) {
                        return {...item, value: ""};
                    }
                    return item;
                })
            );
            setPage(1);
            setMessage({message: MessageCollection.CREATE});
            setRefresh((prev) => !prev);
            closeAlert();
            closeModal();
        } catch (error) {
            if (error.response.status === 400) {
                let matchFound = false;
                Object.keys(initUserData).forEach((orderKey) => {
                    if (matchFound) {
                        return;
                    }
                    Object.entries(error.response.data.detail).forEach((data) => {
                        for (const [key, value] of Object.entries(data[1])) {
                            if (orderKey === key) {
                                setErrorMessage([key, value]);
                                matchFound = true;
                                return;
                            }
                        }
                    });
                });
            } else {
                setAlert(error);
            }
        }
    };

    const modifyUser = async () => {
        setErrorMessage([]);
        if (userData.name === "") {
            setErrorMessage(["name", "이름은 공백일 수 없습니다."]);
            return;
        }
        if (!checkMaxLength(userData.name, 32)) {
            setErrorMessage(["name", "이름은 32자 이하로 입력해주세요."]);
            return;
        }
        if (!isValidEmail(userData.email)) {
            setErrorMessage(["email", "올바른 이메일 형식을 입력해주세요."]);
            return;
        }
        if (!isValidPhoneNumber(userData.mobile_num)) {
            setErrorMessage(["mobile_num", "올바른 전화번호 형식을 입력해주세요."]);
            return;
        }
        if (openAlert !== true) {
            setOpenAlert(true);
            return;
        }
        const refinedUserData = {...userData};
        delete refinedUserData.org_name;
        try {
            await request.put(`users`, {
                ...refinedUserData,
            });
            setMessage({message: MessageCollection.MODIFY});
            closeAlert();
            closeModal();
            setRefresh((refresh) => !refresh);
        } catch (error) {
            setAlert(error);
        }
    };

    const selectOrg = (row) => {
        const updatedRoleEnum = {};
        let updatedRoleValue = "";
        if (row.org_id.substr(0, 4) === "HOSP") {
            for (const key in roleEnum) {
                if (roleEnum[key] === "ROLE_HOSPITAL") {
                    updatedRoleEnum[key] = roleEnum[key];
                    updatedRoleValue = "ROLE_HOSPITAL";
                }
            }
        }
        if (row.org_id.substr(0, 4) === "CORP") {
            for (const key in roleEnum) {
                if (roleEnum[key] === "ROLE_DTX_CORP") {
                    updatedRoleEnum[key] = roleEnum[key];
                    updatedRoleValue = "ROLE_DTX_CORP";
                }
            }
        }
        if (row.org_id.substr(0, 4) === "PLAT") {
            for (const key in roleEnum) {
                if (
                    roleEnum[key] === "ROLE_ADMIN" ||
                    roleEnum[key] === "ROLE_PLATFORM_MANAGER"
                ) {
                    updatedRoleEnum[key] = roleEnum[key];
                    updatedRoleValue = "ROLE_ADMIN";
                }
            }
        }
        setNewRoleEnum(updatedRoleEnum);
        const updatedUserdata = {...userData};
        updatedUserdata.role = updatedRoleValue;
        setUserData((prevState) => ({
            ...prevState,
            org_id: row.org_id,
            org_name: row.org_name,
            role: updatedRoleValue,
        }));
        closeSubModal();
    };

    // 입력창값이 변경될때
    const onChangeSearchValue = (event) => {
        if (event.key === "Enter") {
            onClickSearchButton();
        }
        const {name, value} = event.target;
        setTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === name) {
                    return {...item, value};
                }
                return item;
            })
        );
    };

    const onChangeModalSearchValue = (event) => {
        if (event.key === "Enter") {
            onClickModalSearchButton();
        }
        const {name, value} = event.target;
        setModalTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === name) {
                    return {...item, value};
                }
                return item;
            })
        );
    };

    // 검색 영역에서 검색버튼 눌렀을때 뜨는 알림창
    const onClickSearchButton = () => {
        setPage(1);
        setRefresh((prev) => !prev);
    };
    const onClickModalSearchButton = () => {
        setModalPage(1);
        setModalRefresh((prev) => !prev);
    };

    // 테이블에다가 사용자 등록하는 모달 버튼
    const tableButton = {
        name: "사용자 등록",
        onClick: () => {
            setModalType("createUser");
            setUserData(initUserData);
            setOpenModal(true);
        },
    };

    const onChangeUserData = (event) => {
        const {name, value} = event.target;
        setUserData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const openResetPasswordModal = () => {
        setSubModalType("resetPassword");
        setOpenSubModal(true);
    };

    const openSelectOrgModal = () => {
        setModalPage(1);
        setSubModalType("selectOrg");
        setOpenSubModal(true);
    };

    const resetPassword = async () => {
        try {
            await request.put(`users-pwd-reset/${userData.id}`);
            closeSubModal();
            setMessage({message: MessageCollection.NORMAL});
            closeModal();
        } catch (error) {
            setAlert(error);
        }
    };

    const onChangeBlur = async (event) => {
        const {name, value} = event.target;
        if (name === "id" && value.length > 0) {
            try {
                const checkDuplicateId = await request.get(`users-is-duplicate/${value}`);
                if (checkDuplicateId === undefined) {
                    setUserData((prev) => ({...prev, id: ""}));
                    setMessage({
                        message: "해당 아이디는 이미 등록되어있습니다.",
                        color: MessageColor.RED,
                    });
                }
            } catch (error) {
                setAlert(error);
            }
        }
    };

    useEffect(() => {
        (async () => {
            const updateRoleEnum = {};
            const updateRoleCodeToNameObject = {};
            const roleEnum = await request.get(`commons/role/user`);
            roleEnum.data.data.map((data) => {
                updateRoleEnum[data.desc] = data.role;
                updateRoleCodeToNameObject[data.role] = data.desc;
            });

            setRoleEnum(updateRoleEnum);
            setRefresh((prev) => !prev);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const organsResponse = await request.get("organs", {
                params: {
                    page: modalPage - 1,
                    size: DefaultModalPageSize,
                    sort: modalSortParams,
                    name: modalTableSearchItems[0].value.trim(),
                },
            });
            _setOrgRows(organsResponse.data.data.content);
            setModalTotalPages(organsResponse.data.data.totalPages);
            setModalTotalElements(organsResponse.data.data.totalElements);
        })();
    }, [openModal, modalPage, modalSortParams, modalRefresh]);

    useEffect(() => {
        (async () => {
            const usersData = await request.get(`users`, {
                params: {
                    page: page - 1,
                    size: pageSize,
                    search_word_like: tableSearchItems[0].value.trim().trim(),
                    sort: sortParams,
                },
            });
            if (usersData.data.data.content.length !== 0) {
                const updateRoleCodeToNameObject = {};
                Object.entries(roleEnum).map((arr) => {
                    updateRoleCodeToNameObject[arr[1]] = arr[0];
                });
                const mappedRowsData = usersData.data.data.content.map((data) => {
                    return {
                        ...data,
                        role: updateRoleCodeToNameObject[data.role],
                    };
                });
                _setRows(mappedRowsData);
            } else {
                _setRows(usersData.data.data.content);
            }
            setTotalPages(usersData.data.data.totalPages);
            setTotalElements(usersData.data.data.totalElements);
        })();
    }, [refresh, page, sortParams]);

    // 최종 화면 나오는 곳
    return (
        <>
            <div className="wrapper">
                {<History title={title} breadcrumb={breadcrumb}/>}
                <hr/>
                {
                    <Table
                        rows={rows}
                        columns={columns}
                        getTotalRows={totalElements}
                        search={tableSearchItems}
                        onChangeSearchValue={onChangeSearchValue}
                        onClickSearchButton={onClickSearchButton}
                        button={tableButton}
                        page={page}
                        totalPages={totalPages}
                        changePage={setPage}
                        sortParams={sortParams}
                        setSortParams={setSortParams}
                    />
                }
            </div>
            {
                <Modal
                    open={openModal}
                    close={closeModal}
                    title={modalType === "modifyUser" ? "사용자 수정" : "사용자 등록"}
                    content={
                        <CreateUserInModal
                            userData={userData}
                            onChangeUserData={onChangeUserData}
                            onChangeBlur={onChangeBlur}
                            roleEnum={newRoleEnum}
                            ignoreRole={[
                                "ROLE_PASSWORD_EXPIRED",
                                "ROLE_ACTIVE",
                                "ROLE_INACTIVE",
                            ]}
                            closeModal={closeModal}
                            createUser={createUser}
                            modifyUser={modifyUser}
                            openResetPasswordModal={openResetPasswordModal}
                            openSelectOrgModal={openSelectOrgModal}
                            modalType={modalType}
                            errorMessage={errorMessage}
                        />
                    }
                />
            }
            {
                <Modal
                    size={subModalType === "resetPassword" ? "sm" : ""}
                    open={openSubModal}
                    close={closeSubModal}
                    title={
                        subModalType === "resetPassword"
                            ? "패스워드 초기화"
                            : `사용자 등록 - ${OrgModalName.ALL}`
                    }
                    content={
                        subModalType === "resetPassword" ? (
                            <div>
                                <div style={{marginBottom: "30px"}}>
                                    승인 시 등록된 이메일로 새 비밀번호가 전송 됩니다.
                                </div>
                                <div style={{display: "flex", justifyContent: "right"}}>
                                    <button className="btn btn_gray_line" onClick={closeSubModal}>
                                        취소
                                    </button>
                                    <button
                                        style={{marginLeft: "30px"}}
                                        className="btn btn_black"
                                        onClick={resetPassword}
                                    >
                                        확인
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <Table
                                rows={orgRows}
                                columns={orgColumns}
                                getTotalRows={modalTotalElements}
                                search={modalTableSearchItems}
                                onChangeSearchValue={onChangeModalSearchValue}
                                onClickSearchButton={onClickModalSearchButton}
                                page={modalPage}
                                limit={DefaultModalPageSize}
                                totalPages={modalTotalPages}
                                changePage={setModalPage}
                                sortParams={modalSortParams}
                                setSortParams={setModalSortParams}
                            ></Table>
                        )
                    }
                />
            }
            {
                <Modal
                    size={"sm"}
                    open={openAlert}
                    close={closeAlert}
                    title={"사용자 정보"}
                    content={
                        <div>
                            <div style={{marginBottom: "30px"}}>정말 저장하시겠습니까?</div>
                            <div style={{display: "flex", justifyContent: "right"}}>
                                <button className="btn btn_gray_line" onClick={closeAlert}>
                                    취소
                                </button>
                                <button
                                    style={{marginLeft: "30px"}}
                                    className="btn btn_black"
                                    onClick={modalType === "modifyUser" ? modifyUser : createUser}
                                >
                                    확인
                                </button>
                            </div>
                        </div>
                    }
                />
            }
        </>
    );
};

export default ManageUser;
