import axios from "axios";

const requestForLogin = axios.create({
  timeout: 30000,
  baseURL: process.env.REACT_APP_BACKEND_LOGIN_URL,
  responseType: "json",
  withCredentials: false,
});

export default requestForLogin;
