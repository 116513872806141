import React, { useEffect, useState } from "react";
import request from "../config/Axios";
import History from "../components/organisms/History/History";
import { Table } from "../components/organisms/Table/Table";
import Modal from "../components/organisms/Modal/Modal";
import {
  ButtonPlaceHolder,
  DefaultModalPageSize,
  DefaultPageSize,
  DefaultSortParams,
  MessageCollection,
  OrgModalName,
  UnSelectOrgMsg,
  isNumber,
} from "../components/Default/DefaultValue";
import { AlertErrorState, AlertInfoState } from "..";
import { useRecoilState } from "recoil";
import CreateDTxPriceInModal from "../components/templates/CreateDTxPriceInModal/CreateDTxPriceInModal.js";
import SelectOrgModal from "../components/templates/SelectOrgModal/SelectOrgModal";

const ManageDtxPrice = () => {
  const [refresh, setRefresh] = useState(false); // 검색에서 페이지 새로고침 되니까 있는게 좋음
  const [modalRefresh, setModalRefresh] = useState(false);
  const [alart, setAlert] = useRecoilState(AlertErrorState);
  const [message, setMessage] = useRecoilState(AlertInfoState);

  // 제목, 브레드크럼
  const title = "병원 별 DTx 단가";
  const breadcrumb = [
    { name: "HOME", link: "./" },
    { name: "DTx 관리" },
    { name: "병원 별 DTx 단가", link: "./ManageDtxPrice" },
  ];

  const pageSize = DefaultPageSize;

  // 모달
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal((openModal) => !openModal);
  };

  // 서브 모달
  const [openSubModal, setOpenSubModal] = useState(false);
  const closeSubModal = () => {
    setOpenSubModal((openModal) => !openModal);
  };

  //Alert 모달
  const [openAlert, setOpenAlert] = useState("");
  const closeAlert = () => {
    setOpenAlert((openModal) => !openModal);
  };

  // 테이블 관련 변수
  const [totalElements, setTotalElements] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const [sortParams, setSortParams] = useState(DefaultSortParams.DEFAULT);

  // 모달 테이블 관련 변수
  const [subModalType, setSubModalType] = useState("");
  const [modalTotalElements, setModalTotalElements] = useState(0);
  const [modalPage, setModalPage] = useState(1);
  const [modalTotalPages, setModalTotalPages] = useState(5);
  const [modalSortParams, setModalSortParams] = useState(
    DefaultSortParams.DEFAULT
  );

  // 검색조회부분
  const [tableSearchItems, setTableSearchItems] = useState([]);

  const [dtxData, setDtxData] = useState({});

  // td 내용
  const [rows, _setRows] = useState([]);
  const [orgRows, _setOrgRows] = useState([]);
  const [commonRows, _setCommonRows] = useState([]);
  const [detailPriceRows, _setDetailPriceRows] = useState([]);

  // header,body 변경 th 내용
  const columns = [
    {
      name: "dtx_code",
      header: "DTx코드",
      width: "12%",
    },
    {
      name: "dtx_name",
      header: "DTx명",
      width: "25%",
    },
    {
      name: "org_name",
      header: "DTx기업",
      width: "24%",
    },
    {
      name: "total_turn",
      header: "총 회차",
      width: "8%",
    },
    {
      name: "enable",
      header: "사용 상태",
      width: "9%",
      customBody: (row) => (
        <td key={"enable"}>
          <span
            className={
              row.enable === "true" ? "badge badge_blue" : "badge badge_red"
            }
          >
            {row.enable === "true" ? "사용" : "중지"}
          </span>
        </td>
      ),
    },
    {
      name: "create_at",
      header: "등록일",
      width: "15%",
    },
    {
      name: "update",
      header: "수정",
      width: "7%",
      sort: false,
      customBody: (row) => (
        <td key={"update"}>
          <label
            className="btn btn_sm btn_gray_line"
            onClick={() => {
              openEditModal(row.dtx_code);
            }}
          >
            수정
          </label>
        </td>
      ),
    },
  ];

  const commonColumns = [
    {
      name: "set_no",
      header: "회차",
      width: "15%",
      sort: false,
    },
    {
      name: "price",
      header: "단가",
      width: "80%",
      sort: false,
    },
  ];

  const detailPriceColums = [
    {
      name: "turn_no",
      header: "회차",
      width: "5%",
      sort: false,
    },
    {
      name: "local_code",
      header: "처방코드",
      width: "25%",
      sort: false,
    },
    {
      name: "desc",
      header: "설명",
      width: "40%",
      sort: false,
    },
    {
      name: "price",
      header: "단가",
      width: "30%",
      sort: false,
      customBody: (row) => (
        <td key="price">
          <input
            type="text"
            className="w130"
            name="price"
            value={row.price}
            onChange={(event) => onChangeSettings({ event, row }, "price")}
          />
        </td>
      ),
    },
  ];

  const detailLocalCodePriceColums = [
    {
      name: "set_no",
      header: "Set",
      width: "5%",
      sort: false,
      rowSpan: dtxData.total_turn,
      customBody: (row) => (
        <td
          rowSpan={dtxData.total_turn}
          style={{ border: "1px solid #e9ecf0" }}
          key="set_no"
        >
          {row.set_no}
        </td>
      ),
    },
    {
      name: "turn_no",
      header: "회차",
      width: "5%",
      sort: false,
    },
    {
      name: "local_code",
      header: "처방코드",
      width: "25%",
      sort: false,
    },
    {
      name: "desc",
      header: "설명",
      width: "40%",
      sort: false,
    },
    {
      name: "price",
      header: "단가",
      width: "30%",
      sort: false,
      customBody: (row) => (
        <td key="price">
          <input
            type="text"
            className="w130"
            name="price"
            value={row.price}
            onChange={(event) => onChangeSettings({ event, row }, "price")}
          />
        </td>
      ),
    },
  ];

  const onChangeSettings = ({ event, row }, name) => {
    const { value } = event.target;
    if (isNumber(value) === true) {
      const updatedRows = detailPriceRows.map((item) =>
        item.set_no === row.set_no && item.turn_no === row.turn_no
          ? { ...item, [name]: value }
          : item
      );
      _setDetailPriceRows(updatedRows);
    }
  };

  const openEditModal = async (id) => {
    const dtxDetailsResponse = await request.get(`apps/${id}`);
    setDtxData(dtxDetailsResponse.data.data);
    const commonPricesResponse = await request.get(`prices/${id}`, {
      params: { code: id },
    });
    _setCommonRows(commonPricesResponse.data.data);

    const detailPricesResponse = await request.get(
      `prices/hosp/${tableSearchItems[0].value}/dtx/${id}`
    );

    const refinedEdiData =
      detailPricesResponse.data.data.edi_code_price_list.map((item) => ({
        ...item,
        set_no: "",
        local_code: item.edi_code,
        desc: "EDI Code",
      }));
    refinedEdiData.push(
      ...detailPricesResponse.data.data.local_code_price_list
    );
    _setDetailPriceRows(refinedEdiData);

    setOpenModal(true);
  };

  const modifyPrices = async () => {
    const refinedDetailPrice = {
      turn_count: dtxData.total_turn,
      edi_code_price_list: [],
      local_code_price_list: [],
    };
    detailPriceRows.map((data) => {
      if (data.edi_code !== undefined) {
        refinedDetailPrice.edi_code_price_list.push(data);
      } else {
        refinedDetailPrice.local_code_price_list.push(data);
      }
    });

    if (openAlert !== true) {
      setOpenAlert(true);
      return;
    }

    try {
      await request.put(
        `prices/hosp/${tableSearchItems[0].value}/dtx/${dtxData.dtx_code}`,
        refinedDetailPrice
      );
      setRefresh((prev) => !prev);
      setMessage({ message: MessageCollection.MODIFY });
      closeAlert();
      closeModal();
    } catch (error) {
      setAlert(error);
    }
  };

  // 입력창값이 변경될때
  const onChangeSearchValue = (event) => {
    if (event.key === "Enter") {
      onClickSearchButton();
    }
    const { name, value } = event.target;
    setTableSearchItems((prevState) =>
      prevState.map((item) => {
        if (item.name.join(",") === name) {
          return { ...item, value };
        }
        return item;
      })
    );
  };

  // 검색 영역에서 검색버튼 눌렀을때 뜨는 알림창
  const onClickSearchButton = () => {
    if (tableSearchItems[0].value === "") {
      setMessage({ message: UnSelectOrgMsg, color: "#48b878" });
    }
    setPage(1);
    setRefresh((prev) => !prev);
  };

  const selectOrg = (row) => {
    const refineTableSearchItems = [...tableSearchItems];

    if (subModalType === "HOSP") {
      refineTableSearchItems[0].display = row.org_name;
      refineTableSearchItems[0].value = row.org_id;
    }
    if (subModalType === "CORP") {
      refineTableSearchItems[1].display = row.org_name;
      refineTableSearchItems[1].value = row.org_id;
    }
    setTableSearchItems(refineTableSearchItems);
    closeSubModal();
  };

  const onClickOrgFilter = (value) => {
    setModalPage(1);
    setSubModalType(value);
    setModalTableSearchItems((prevState) =>
      prevState.map((item) => {
        if (item.name.join(",") === "orgName") {
          return { ...item, value: "" };
        }
        return item;
      })
    );
    setOpenSubModal(true);
  };

  const onClickOrgFilterForRemove = (value) => {
    if (value === "HOSP") {
      setTableSearchItems((prevState) =>
        prevState.map((item) => {
          if (item.name.join(",") === "hospFilter") {
            return { ...item, value: "", display: ButtonPlaceHolder.HOSP_R };
          }
          return item;
        })
      );
    }
    if (value === "CORP") {
      setTableSearchItems((prevState) =>
        prevState.map((item) => {
          if (item.name.join(",") === "corpFilter") {
            return { ...item, value: "", display: ButtonPlaceHolder.CORP };
          }
          return item;
        })
      );
    }
  };

  const [modalTableSearchItems, setModalTableSearchItems] = useState([
    {
      name: ["orgName"],
      placeholder: ["기관명"],
      value: "",
      type: "text",
    },
  ]);

  const onChangeModalSearchValue = (event) => {
    if (event.key === "Enter") {
      onClickModalSearchButton();
    }
    const { name, value } = event.target;
    setModalTableSearchItems((prevState) =>
      prevState.map((item) => {
        if (item.name.join(",") === name) {
          return { ...item, value };
        }
        return item;
      })
    );
  };

  const onClickModalSearchButton = () => {
    setModalPage(1);
    setModalRefresh((prev) => !prev);
  };

  useEffect(() => {
    (async () => {
      let searchItems = [
        {
          name: ["hospFilter"],
          type: "labelButton",
          onClick: () => {
            onClickOrgFilter("HOSP");
          },
          onClickRemove: () => {
            onClickOrgFilterForRemove("HOSP");
          },
          display: ButtonPlaceHolder.HOSP_R,
          value: "",
        },
        {
          name: ["corpFilter"],
          type: "labelButton",
          onClick: () => {
            onClickOrgFilter("CORP");
          },
          onClickRemove: () => {
            onClickOrgFilterForRemove("CORP");
          },
          display: ButtonPlaceHolder.CORP,
          value: "",
        },
        {
          name: ["dtxCode", "dtxName"],
          placeholder: ["DTx코드, DTx명"],
          value: "",
          type: "text",
        },
      ];

      setTableSearchItems(searchItems);

      _setRows([]);
      setTotalPages(0);
      setTotalElements(0);
    })();
  }, []);

  useEffect(() => {
    if (subModalType !== "") {
      (async () => {
        const organsResponse = await request.get("organs", {
          params: {
            page: modalPage - 1,
            size: DefaultModalPageSize,
            sort: modalSortParams,
            type: subModalType,
            name: modalTableSearchItems[0].value.trim(),
          },
        });
        _setOrgRows(organsResponse.data.data.content);
        setModalTotalPages(organsResponse.data.data.totalPages);
        setModalTotalElements(organsResponse.data.data.totalElements);
      })();
    }
  }, [openSubModal, modalPage, modalSortParams, modalRefresh]);

  useEffect(() => {
    if (tableSearchItems.length > 0 && tableSearchItems[0].value !== "") {
      (async () => {
        const pricesData = await request.get(`prices`, {
          params: {
            page: page - 1,
            size: pageSize,
            sort: sortParams,
            hosp_id: tableSearchItems[0].value,
            corp_id: tableSearchItems[1].value,
            search_word_like: tableSearchItems[2].value.trim(),
          },
        });
        _setRows(pricesData.data.data.content);
        setTotalPages(pricesData.data.data.totalPages);
        setTotalElements(pricesData.data.data.totalElements);
      })();
    }
  }, [refresh, page, sortParams]);

  // 최종 화면 나오는 곳
  return (
    <>
      <div className="wrapper">
        {<History title={title} breadcrumb={breadcrumb} />}
        <hr />
        {
          <Table
            rows={rows}
            columns={columns}
            getTotalRows={totalElements}
            search={tableSearchItems}
            onChangeSearchValue={onChangeSearchValue}
            onClickSearchButton={onClickSearchButton}
            page={page}
            totalPages={totalPages}
            changePage={setPage}
            sortParams={sortParams}
            setSortParams={setSortParams}
          />
        }
      </div>
      {
        <Modal
          open={openModal}
          close={closeModal}
          title={"병원 별 DTx 단가 수정"}
          content={
            <CreateDTxPriceInModal
              dtxData={dtxData}
              commonRows={commonRows}
              commonColumns={commonColumns}
              detailPriceRows={detailPriceRows}
              detailPriceColums={detailPriceColums}
              detailLocalCodePriceColums={detailLocalCodePriceColums}
              closeModal={closeModal}
              modifyPrices={modifyPrices}
            />
          }
        />
      }
      {
        <Modal
          open={openSubModal}
          close={closeSubModal}
          title={
            subModalType === "HOSP" ? OrgModalName.HOSP : OrgModalName.CORP
          }
          content={
            <SelectOrgModal
              rows={orgRows}
              orgType={subModalType}
              selectOrg={selectOrg}
              getTotalRows={modalTotalElements}
              search={modalTableSearchItems}
              onChangeSearchValue={onChangeModalSearchValue}
              onClickSearchButton={onClickModalSearchButton}
              page={modalPage}
              limit={DefaultModalPageSize}
              totalPages={modalTotalPages}
              changePage={setModalPage}
              sortParams={modalSortParams}
              setSortParams={setModalSortParams}
            ></SelectOrgModal>
          }
        />
      }
      {
        <Modal
          size={"sm"}
          open={openAlert}
          close={closeAlert}
          title={"DTx앱 정보"}
          content={
            <div>
              <div style={{ marginBottom: "30px" }}>정말 저장하시겠습니까?</div>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <button className="btn btn_gray_line" onClick={closeAlert}>
                  취소
                </button>
                <button
                  style={{ marginLeft: "30px" }}
                  className="btn btn_black"
                  onClick={modifyPrices}
                >
                  확인
                </button>
              </div>
            </div>
          }
        />
      }
    </>
  );
};

export default ManageDtxPrice;
