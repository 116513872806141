import React, { useEffect, useState } from "react";
import request from "../config/Axios";
import History from "../components/organisms/History/History";
import { Table } from "../components/organisms/Table/Table";
import Modal from "../components/organisms/Modal/Modal";
import moment from "moment";
import {
  ButtonPlaceHolder,
  DefaultModalPageSize,
  DefaultPageSize,
  DefaultSearchDay,
  DefaultSortParams,
  MessageCollection,
  MessageColor,
  OrgModalName,
  UnSelectOrgMsg,
  copyToClipboard,
} from "../components/Default/DefaultValue";

import { useRecoilState } from "recoil";
import { AlertErrorState, AlertInfoState } from "..";
import SelectOrgModal from "../components/templates/SelectOrgModal/SelectOrgModal";

const HospSettlement = () => {
  const [refresh, setRefresh] = useState(false); // 검색에서 페이지 새로고침 되니까 있는게 좋음
  const [modalRefresh, setModalRefresh] = useState(false);
  const [message, setMessage] = useRecoilState(AlertInfoState);
  const [alart, setAlert] = useRecoilState(AlertErrorState);

  // 제목, 브레드크럼
  const title = "의료기관";
  const breadcrumb = [
    { name: "HOME", link: "./" },
    { name: "정산 관리" },
    { name: "의료기관", link: "./HospSettlement" },
  ];

  const pageSize = DefaultPageSize;

  // 테이블 관련 변수
  const [totalElements, setTotalElements] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const [sortParams, setSortParams] = useState(DefaultSortParams.DEFAULT);

  // 모달 테이블 관련 변수
  const [modalTotalElements, setModalTotalElements] = useState(0);
  const [modalPage, setModalPage] = useState(1);
  const [modalTotalPages, setModalTotalPages] = useState(5);
  const [modalSortParams, setModalSortParams] = useState(
    DefaultSortParams.DEFAULT
  );

  // 모달
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal((openModal) => !openModal);
  };

  //Alert 모달
  const [openAlert, setOpenAlert] = useState("");
  const closeAlert = () => {
    setOpenAlert((openModal) => !openModal);
  };

  // 검색조회부분
  const [tableSearchItems, setTableSearchItems] = useState([]);

  // 서브 모달 검색 조회
  const [modalTableSearchItems, setModalTableSearchItems] = useState([
    {
      name: ["orgName"],
      placeholder: ["기관명"],
      value: "",
      type: "text",
    },
  ]);

  const [searchFilter, setSearchFilter] = useState(-1);
  const [mode, setMode] = useState("single");
  const [checkedIdsSet, setCheckedIdsSet] = useState(new Set());

  // td 내용
  const [rows, _setRows] = useState([]);
  const [orgRows, _setOrgRows] = useState([]);

  // header,body 변경 th 내용
  const columns = [
    {
      name: `billStatus`,
      header: "정산상태",
      width: "10%",
    },

    {
      name: "prcp_recv_dt",
      header: "처방일",
      width: "15%",
    },
    {
      name: "dtx_name",
      header: "DTx명",
      width: "25%",
    },
    {
      name: "sales_price",
      header: "정산금액",
      width: "25%",
    },
    {
      name: "prcp_seq",
      header: "처방번호",
      width: "25%",
      customBody: (row) => (
        <td key="prcp_seq">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="ellipsis">{row.prcp_seq}</div>
            <div
              className="attachIcon"
              style={{ width: "18px" }}
              onClick={() => {
                copyToClipboard(row.prcp_seq);
                setMessage({ message: MessageCollection.COPY });
              }}
            ></div>
          </div>
        </td>
      ),
    },
  ];

  // 입력창값이 변경될때
  const onChangeSearchValue = (event) => {
    if (event.key === "Enter") {
      onClickSearchButton();
    }
    const { name, value } = event.target;
    setTableSearchItems((prevState) =>
      prevState.map((item) => {
        if (item.name.join(",") === name) {
          return { ...item, value };
        }
        return item;
      })
    );
    if (name === "startDate") {
      setTableSearchItems((prevState) =>
        prevState.map((item) => {
          if (item.name.join(",") === "endDate") {
            return {
              ...item,
              value:
                item.value > moment(value).add(30, "day").format("YYYY-MM-DD")
                  ? moment(value).add(30, "day").format("YYYY-MM-DD")
                  : item.value < value
                  ? value
                  : item.value,
            };
          }
          return item;
        })
      );
    }
    if (name === "endDate") {
      setTableSearchItems((prevState) =>
        prevState.map((item) => {
          if (item.name.join(",") === "startDate") {
            return {
              ...item,
              value:
                item.value <
                moment(value).subtract(30, "day").format("YYYY-MM-DD")
                  ? moment(value).subtract(30, "day").format("YYYY-MM-DD")
                  : item.value > value
                  ? value
                  : item.value,
            };
          }
          return item;
        })
      );
    }
  };

  const selectOrg = (row) => {
    setTableSearchItems((prevState) =>
      prevState.map((item) => {
        if (item.name.join(",") === "hospFilter") {
          return { ...item, value: row.org_id, display: row.org_name };
        }
        return item;
      })
    );
    closeModal();
  };

  // 검색 영역에서 검색버튼 눌렀을때 뜨는 알림창
  const onClickSearchButton = () => {
    if (tableSearchItems[2].value === "") {
      setMessage({ message: UnSelectOrgMsg, color: "#48b878" });
    }
    setPage(1);
    setRefresh((prev) => !prev);
  };

  const onClickModalSearchButton = () => {
    setModalPage(1);
    setModalRefresh((prev) => !prev);
  };

  const onClickOrgFilter = () => {
    setModalPage(1);
    setModalTableSearchItems((prevState) =>
      prevState.map((item) => {
        if (item.name.join(",") === "orgName") {
          return { ...item, value: "" };
        }
        return item;
      })
    );
    setOpenModal(true);
  };

  const onClickOrgFilterForRemove = () => {
    setTableSearchItems((prevState) =>
      prevState.map((item) => {
        if (item.name.join(",") === "hospFilter") {
          return { ...item, value: "", display: ButtonPlaceHolder.HOSP_R };
        }
        return item;
      })
    );
  };

  const onChangeModalSearchValue = (event) => {
    if (event.key === "Enter") {
      onClickModalSearchButton();
    }
    const { name, value } = event.target;
    setModalTableSearchItems((prevState) =>
      prevState.map((item) => {
        if (item.name.join(",") === name) {
          return { ...item, value };
        }
        return item;
      })
    );
  };

  const calcButton = {
    name:
      searchFilter === 1
        ? "선택정산요청"
        : searchFilter === 2
        ? "선택정산완료"
        : "",
    onClick: () => {
      setMode("single");
      checkedIdsSet.size
        ? setOpenAlert(true)
        : setMessage({
            message: "선택된 정산 항목이 없습니다.",
            color: MessageColor.RED,
          });
    },
  };

  const calcButton2 = {
    name:
      searchFilter === 1
        ? "전체정산요청"
        : searchFilter === 2
        ? "전체정산완료"
        : "",
    onClick: () => {
      setMode("all");
      totalElements
        ? setOpenAlert(true)
        : setMessage({
            message: "선택된 정산 항목이 없습니다.",
            color: MessageColor.RED,
          });
    },
  };

  const modifySettlement = async () => {
    try {
      let phicodeList = [];
      if (mode === "single") {
        phicodeList = Array.from(checkedIdsSet);
      }
      if (mode === "all") {
        const phiCodeResponse = await request.get(`settlements/hosp`, {
          params: {
            page: 0,
            size: 9999999,
            start_date: moment(tableSearchItems[0].value).format("YYYYMMDD"),
            end_date: moment(tableSearchItems[1].value).format("YYYYMMDD"),
            id: tableSearchItems[2].value,
            status: tableSearchItems[3].value,
          },
        });
        phiCodeResponse.data.data.content.map((data) => {
          phicodeList.push(data.phi_code);
        });
      }

      await request.put(
        `settlements/organs/${tableSearchItems[2].value}/status/${searchFilter}`,
        phicodeList
      );
      setMessage({ message: MessageCollection.MODIFY });
      setRefresh((prev) => !prev);
      closeAlert();
    } catch (error) {
      setAlert(error);
    }
  };

  useEffect(() => {
    (async () => {
      const updateStatusFilter = { 전체: "*" };
      const statusFilter = await request.get(`commons/status/bills`);
      statusFilter.data.data.map((data) => {
        if ([0, 1, 2, 3, 90].includes(data.code)) {
          updateStatusFilter[data.hospDesc] = data.code;
        }
      });

      let searchItems = [
        {
          name: ["startDate"],
          value: moment(new Date())
            .subtract(DefaultSearchDay, "day")
            .format("YYYY-MM-DD"),
          type: "date",
          label: "처방일",
        },
        {
          name: ["endDate"],
          value: moment(new Date()).format("YYYY-MM-DD"),
          type: "date",
        },
        {
          name: ["hospFilter"],
          type: "labelButton",
          onClick: onClickOrgFilter,
          onClickRemove: onClickOrgFilterForRemove,
          display: ButtonPlaceHolder.HOSP_R,
          value: "",
        },
        {
          name: ["filter"],
          filter: updateStatusFilter,
          value: Object.values(updateStatusFilter)[0],
          type: "select",
        },
      ];

      setTableSearchItems(searchItems);
      setRefresh((prev) => !prev);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const organsResponse = await request.get("organs", {
        params: {
          page: modalPage - 1,
          size: DefaultModalPageSize,
          sort: modalSortParams,
          name: modalTableSearchItems[0].value.trim(),
          type: "HOSP",
        },
      });
      _setOrgRows(organsResponse.data.data.content);
      setModalTotalPages(organsResponse.data.data.totalPages);
      setModalTotalElements(organsResponse.data.data.totalElements);
    })();
  }, [openModal, modalPage, modalSortParams, modalRefresh]);

  useEffect(() => {
    if (tableSearchItems.length > 0) {
      (async () => {
        //유효하지 않은 날짜 체크
        if(!moment(tableSearchItems[0].value).isValid() || !moment(tableSearchItems[1].value).isValid()){
          return;
        }
        const codesData = await request.get(`settlements/hosp`, {
          params: {
            page: page - 1,
            size: pageSize,
            sort: sortParams,
            start_date: moment(tableSearchItems[0].value).format("YYYYMMDD"),
            end_date: moment(tableSearchItems[1].value).format("YYYYMMDD"),
            id: tableSearchItems[2].value,
            status: tableSearchItems[3].value,
          },
        });
        const mappedRowsData = codesData.data.data.content.map((data) => {
          return {
            ...data,
            billStatus: data.bill_status.hospDesc,
          };
        });
        _setRows(mappedRowsData);
        setTotalPages(codesData.data.data.totalPages);
        setTotalElements(codesData.data.data.totalElements);

        setSearchFilter(parseInt(tableSearchItems[3].value));
        setCheckedIdsSet(new Set());
      })();
    }
  }, [refresh, page, sortParams]);

  // 최종 화면 나오는 곳
  return (
    <>
      <div className="wrapper">
        {<History title={title} breadcrumb={breadcrumb} />}
        <hr />
        {
          <Table
            rows={rows}
            columns={columns}
            getTotalRows={totalElements}
            search={tableSearchItems}
            onChangeSearchValue={onChangeSearchValue}
            onClickSearchButton={onClickSearchButton}
            button={
              searchFilter === 1 || searchFilter === 2 ? calcButton : null
            }
            button2={
              searchFilter === 1 || searchFilter === 2 ? calcButton2 : null
            }
            checkbox={
              searchFilter === 1 || searchFilter === 2 ? "phi_code" : undefined
            }
            checkMode={"multiple"}
            checkedIdsSet={checkedIdsSet}
            setCheckedIdsSet={setCheckedIdsSet}
            page={page}
            totalPages={totalPages}
            changePage={setPage}
            sortParams={sortParams}
            setSortParams={setSortParams}
          />
        }
      </div>
      {
        <Modal
          open={openModal}
          close={closeModal}
          title={OrgModalName.HOSP}
          content={
            <SelectOrgModal
              rows={orgRows}
              orgType={"HOSP"}
              selectOrg={selectOrg}
              getTotalRows={modalTotalElements}
              search={modalTableSearchItems}
              onChangeSearchValue={onChangeModalSearchValue}
              onClickSearchButton={onClickModalSearchButton}
              page={modalPage}
              limit={DefaultModalPageSize}
              totalPages={modalTotalPages}
              changePage={setModalPage}
              sortParams={modalSortParams}
              setSortParams={setModalSortParams}
            ></SelectOrgModal>
          }
        />
      }
      {
        <Modal
          size={"sm"}
          open={openAlert}
          close={closeAlert}
          title={"정산 관리"}
          content={
            <div>
              <div style={{ marginBottom: "30px" }}>정말 적용하시겠습니까?</div>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <button className="btn btn_gray_line" onClick={closeAlert}>
                  취소
                </button>
                <button
                  style={{ marginLeft: "30px" }}
                  className="btn btn_black"
                  onClick={modifySettlement}
                >
                  확인
                </button>
              </div>
            </div>
          }
        />
      }
    </>
  );
};

export default HospSettlement;
