import React from "react";

export const History = ({ title, breadcrumb }) => {
  return (
    <div className="page_info_wrap">
      {/* 페이지 제목 */}
      <div className="page_title">{title}</div>
      {/* 브레드크럼 */}
      <ul className="breadcrumbs">
        {breadcrumb &&
          breadcrumb.map((data, index) => {
            const linkStyle =
              data.link && breadcrumb.length - 1 === index
                ? { fontWeight: "400", textDecoration: "underline" }
                : data.link
                ? { textDecoration: "underline" }
                : {};
            return (
              <li key={data.name}>
                <a style={linkStyle} href={data.link}>
                  {data.name}
                </a>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default History;
