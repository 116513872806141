import React, { Fragment, useEffect, useState } from "react";

export const DynamicTable = ({
  columns, // th 내용
  rows, //td 내용
  button, // 모달용 버튼들
  totalElements,
}) => {
  return (
    <Fragment>
      {/* 전체 목록 수, 등록 모달 버튼 등 */}
      <div className="btn_wrap">
        {totalElements !== undefined && (
          <div className="left mt30">전체 목록 : {totalElements}개</div>
        )}
        <div className="left"></div>
        <div className="center"></div>
        <div className="right">
          {button && (
            <label className="btn btn_black ml5" onClick={button.onClick}>
              {button.name}
            </label>
          )}
        </div>
      </div>
      {/* 테이블 */}
      <table className="table_basic mt30">
        <colgroup>
          {columns &&
            columns.map((column, index) => {
              return <col key={index} width={column.width}></col>;
            })}
        </colgroup>
        <thead>
          <tr>
            {columns &&
              columns.map((column, index) => {
                if (column.customHeader !== undefined) {
                  return column.customHeader(column);
                } else {
                  return <th key={index}>{column.header}</th>;
                }
              })}
          </tr>
        </thead>
        <tbody>
          {rows && rows.length > 0 ? (
            rows.map((row, index) => {
              return (
                <tr key={index}>
                  {columns.map((column, index2) => {
                    if (column.rowSpan !== undefined) {
                      if (index === 0) {
                        return column.customBody(row);
                      }
                      if (index % column.rowSpan === 0) {
                        return column.customBody(row);
                      }
                    } else if (column.customBody !== undefined) {
                      return column.customBody(row);
                    } else {
                      return (
                        <td key={index2}>
                          <span>{row[column.name]}</span>
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={columns.length}>데이터 없음</td>
            </tr>
          )}
        </tbody>
      </table>
    </Fragment>
  );
};

export default DynamicTable;
/*
example


const [rows, _setRows] = useState([]);

const DynamicInitData = {
  createAt: "2023-07-18 14:15:43",
  email: "",
  phone: "01066666666",
  name: "",
  org_name: "PLAT000000",
  orgName: null,
  orgType: "PLAT",
  role: "ROLE_ADMIN",
  create_at: "2023-07-18 14:15:43",
  userId: `test`,
};

const addButton = {
  name: "데이터 추가",
  onClick: () => {
    _setRows((prevState) => [...prevState, DynamicInitData]);
  },
};

const onChangeSettings = ({ event, row }, name) => {
  const { value } = event.target;

  const updatedRows = rows.map((item) =>
    item.id === row.id ? { ...item, [name]: value } : item
  );

  _setRows(updatedRows);
};

const dynamicColumns = [
  {
    name: "id",
    header: "key(producer)",
    width: "10%",
    customBody: (row) => (
      <td key="userId">
        <input type="text" value={row.userId} disabled />
      </td>
    ),
  },

  {
    name: "name",
    header: "Value",
    width: "8%",
    customBody: (row) => (
      <td key="name">
        <input
          type="text"
          value={row.name}
          onChange={(event) => onChangeSettings({ event, row }, "name")}
        />
      </td>
    ),
  },
  {
    name: "email",
    header: "Description",
    width: "15%",
    customBody: (row) => (
      <td key="email">
        <input
          type="text"
          value={row.email}
          onChange={(event) => onChangeSettings({ event, row }, "email")}
        />
      </td>
    ),
  },
  {
    name: "create_at",
    header: "Last Update",
    width: "15%",
  },
  {
    name: "update",
    header: "Save",
    width: "8%",
    customBody: (row) => (
      <td key={"update"}>
        <label
          className="btn btn_sm btn_gray_line"
          onClick={() => {
            openEditModal(row.userId);
          }}
        >
          저장
        </label>
      </td>
    ),
  },
];

return(
  {
    <DynamicTable
      rows={rows}
      columns={dynamicColumns}
      button={addButton}
    />
  }
)

*/
