import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../components/organisms/Card/Card";
import { Table } from "../components/organisms/Table/Table";
import request from "../config/Axios";
import {
  MessageCollection,
  copyToClipboard,
} from "../components/Default/DefaultValue";
import { useRecoilState } from "recoil";
import { AlertInfoState } from "..";

const Mainpage = () => {
  let navigate = useNavigate();
  const [message, setMessage] = useRecoilState(AlertInfoState);

  // td 내용
  const [oprRows, _setOprRows] = useState([]);
  const [auditRows, _setAuditRows] = useState([]);

  // 값이 바뀌어야 하는것은 무조건 Set..써서  useState()~~~
  const [cardListArray, setCardListArray] = useState([
    { name: "의료기관", count: 0, sub: " 기관" },
    { name: "DTx 기업", count: 0, sub: " 업체" },
    { name: "의료기관 정산대기/정산요청", count: 0, sub: " 건" },
    { name: "DTx 기업 정산대기/정산요청", count: 0, sub: " 건" },
  ]);

  // ENUM
  const [logNameTypeEnum, setLogNameTypeEnum] = useState({});

  // header,body 변경
  // th 내용
  const columns = [
    {
      name: "log_type",
      header: "타입",
      width: "8%",
      sort: false,
      customBody: (row) => (
        <td key="log_type">
          <span>{logNameTypeEnum[row.log_type]}</span>
        </td>
      ),
    },
    {
      name: "log_code",
      header: "코드",
      width: "8%",
      sort: false,
    },
    {
      name: "result_code",
      header: "결과",
      width: "8%",
      sort: false,
      customBody: (row) => (
        <td key="role">
          <span
            className={
              row.result_code === "SUCCESS"
                ? "badge badge_green"
                : row.result_code === "FAIL"
                ? "badge badge_red"
                : "badge badge_black"
            }
          >
            {row.result_code === "SUCCESS"
              ? "성공"
              : row.result_code === "FAIL"
              ? "실패"
              : "알수 없음"}
          </span>
        </td>
      ),
    },
    {
      name: "src_ip",
      header: "아이피",
      width: "8%",
      sort: false,
    },
    {
      name: "log_date_time",
      header: "시간",
      width: "15%",
      sort: false,
    },
    {
      name: "contents",
      header: "내용",
      width: "53%",
      sort: false,
      customBody: (row) => (
        <td key="contents">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                width: "700px",
              }}
              className="ellipsis_log"
              title={row.contents}
            >
              {row.contents}
            </div>
            <div
              className="attachIcon"
              style={{ width: "18px" }}
              onClick={() => {
                copyToClipboard(JSON.stringify(row.contents));
                setMessage({ message: MessageCollection.COPY });
              }}
            ></div>
          </div>
        </td>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      const dashboardResponse = await request.get(`dashboard`);

      dashboardResponse.data.data.logs.opr_log.map((data) => {
        data["contents"] = JSON.stringify(data.contents);
      });
      dashboardResponse.data.data.logs.audit_log.map((data) => {
        data["contents"] = JSON.stringify(data.contents);
      });

      _setOprRows(dashboardResponse.data.data.logs.opr_log.slice(0, 5));
      _setAuditRows(dashboardResponse.data.data.logs.audit_log.slice(0, 5));

      const inputList = [];
      inputList.push(dashboardResponse.data.data.organs.hosp);
      inputList.push(dashboardResponse.data.data.organs.corp);
      inputList.push(
        `${dashboardResponse.data.data.settlements.wait} / ${dashboardResponse.data.data.settlements.hosp_request}`
      );
      inputList.push(
        `${dashboardResponse.data.data.settlements.corp_wait} / ${dashboardResponse.data.data.settlements.corp_request}`
      );
      const refinedCardListArray = [...cardListArray];
      for (let i = 0; i < cardListArray.length; i++) {
        refinedCardListArray[i].count = inputList[i];
      }

      const updatedLogNameTypeEnum = {};
      const logNameType = await request.get(`commons/log/type`);
      logNameType.data.data.map((data) => {
        updatedLogNameTypeEnum[data.type] = data.desc;
      });
      setLogNameTypeEnum(updatedLogNameTypeEnum);
      setCardListArray(refinedCardListArray);
    })();
  }, []);

  // 최종 화면 나오는 곳
  return (
    <>
      <div className="wrapper">
        {<Card cardListArray={cardListArray} />}
        <span
          className="wrapper_sub_title"
          onClick={() => {
            navigate("/OprLog");
          }}
        >
          운영로그
          <span className="icon_right_arrow"></span>
        </span>
        <hr />
        {
          <Table
            rows={oprRows}
            columns={columns}
            hideHeader
            getTotalRows={0}
            overflow
          />
        }
        <div style={{ marginBottom: "30px" }}></div>
        <span
          className="wrapper_sub_title"
          onClick={() => {
            navigate("/AuditLog");
          }}
        >
          감사로그
          <span className="icon_right_arrow"></span>
        </span>
        <hr />
        {
          <Table
            rows={auditRows}
            columns={columns}
            hideHeader
            getTotalRows={0}
            overflow
          />
        }
      </div>
    </>
  );
};

export default Mainpage;
