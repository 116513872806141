import React from "react";
import Input from "../../atoms/Input/Input";

export const CreateUserInModal = ({
  userData,
  onChangeUserData,
  onChangeBlur,
  roleEnum,
  ignoreRole,
  closeModal,
  modifyUser,
  createUser,
  openResetPasswordModal,
  openSelectOrgModal,
  modalType,
  errorMessage,
}) => {
  return (
    <>
      <ul className="form_wrap">
        <li>
          <label className="name">아이디</label>
          <Input
            type="text"
            name="id"
            value={userData.id}
            onChange={onChangeUserData}
            onBlur={onChangeBlur}
            required
            errorMessage={errorMessage}
            disabled={modalType === "modifyUser" ? true : false}
          />
        </li>
        <li>
          <label className="name">소속</label>
          <div style={{ marginBottom: "0px" }} className="search_box">
            <div key={userData.org_id} className="search_wrap">
              <Input
                type="text"
                name={"org_id"}
                value={userData.org_name}
                className="input_search_modal"
                placeholder={"소속기관 선택"}
                style={{
                  backgroundColor: "#FAFAFA",
                  color: "#666670",
                  cursor: "pointer",
                }}
                onClick={openSelectOrgModal}
                onChange={() => {}}
                errorMessage={errorMessage}
              />
              <button
                className="btn_search"
                onClick={openSelectOrgModal}
              ></button>
            </div>
          </div>
        </li>
        <li>
          <label className="name">권한</label>
          {userData.org_id === "" ? (
            <input type="text" className="input_search_modal" disabled></input>
          ) : (
            <select
              className="js-searchBox"
              name="role"
              style={{ width: "580px" }}
              value={userData.role}
              onChange={onChangeUserData}
            >
              {Object.entries(roleEnum).map((role) => {
                if (!ignoreRole.includes(role[1])) {
                  return (
                    <option key={role[0]} value={role[1]}>
                      {role[0]}
                    </option>
                  );
                }
              })}
            </select>
          )}
        </li>
        <li>
          <label className="name">이름</label>
          <Input
            type="text"
            name="name"
            value={userData.name}
            onChange={onChangeUserData}
            required
            errorMessage={errorMessage}
          />
        </li>
        <li>
          <label className="name">이메일</label>
          <Input
            type="email"
            name="email"
            value={userData.email}
            onChange={onChangeUserData}
            required
            errorMessage={errorMessage}
          />
        </li>
        <li>
          <label className="name">전화번호</label>
          <Input
            type="tel"
            name="mobile_num"
            value={userData.mobile_num === null ? "" : userData.mobile_num}
            onChange={onChangeUserData}
            required
            placeholder={"ex) 000-0000-0000"}
            errorMessage={errorMessage}
          />
        </li>
      </ul>
      <div className="btn_wrap">
        <div className="left">
          {modalType === "modifyUser" && (
            <button className="btn btn_black" onClick={openResetPasswordModal}>
              패스워드 초기화
            </button>
          )}
        </div>
        <div className="center"></div>
        <div className="right">
          <button className="btn btn_gray_line" onClick={closeModal}>
            취소
          </button>
          <button
            className="btn btn_black"
            onClick={modalType === "modifyUser" ? modifyUser : createUser}
          >
            저장
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateUserInModal;
