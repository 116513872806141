import React, { useEffect, useState } from "react";
import request from "../config/Axios";
import History from "../components/organisms/History/History";
import { Table } from "../components/organisms/Table/Table";
import Tab from "../components/organisms/Tab/Tab";
import Input from "../components/atoms/Input/Input";
import { useRecoilState } from "recoil";
import { AlertErrorState, AlertInfoState } from "..";
import { MessageCollection } from "../components/Default/DefaultValue";

const SystemConfig = () => {
  const [refresh, setRefresh] = useState(false); // 검색에서 페이지 새로고침 되니까 있는게 좋음
  const [alart, setAlert] = useRecoilState(AlertErrorState);
  const [message, setMessage] = useRecoilState(AlertInfoState);

  // 제목, 브레드크럼
  const title = "시스템 설정";
  const breadcrumb = [
    { name: "HOME", link: "./" },
    { name: "설정" },
    { name: "시스템 설정", link: "./SystemConfig" },
  ];

  // 탭 내용들
  const [tabMenuTitle, setTabMenuTitle] = useState([{}]);

  const [tabValue, setTabValue] = useState(Object.values(tabMenuTitle)[0].name);

  // td 내용
  const [rows, _setRows] = useState([]);
  const [getTotalRows, setGetTotalRows] = useState(0);

  // th 내용
  const columns = [
    {
      name: "key",
      header: "key",
      width: "20%",
      sort: false,
    },
    {
      name: "value",
      header: "Value",
      width: "*",
      sort: false,
      customBody: (row) => (
        <td key="value">
          <Input
            type="text"
            className="w400"
            value={row.value}
            onChange={(event) => onChangeSettings({ event, row }, "value")}
          />
        </td>
      ),
    },
    {
      name: "description",
      header: "Description",
      width: "*",
      sort: false,
      customBody: (row) => (
        <td key="description">
          <Input
            type="text"
            className="w400"
            value={row.description}
            onChange={(event) =>
              onChangeSettings({ event, row }, "description")
            }
          />
        </td>
      ),
    },
    {
      name: "last_update",
      header: "Last Update",
      width: "*",
      sort: false,
    },
    {
      name: "save",
      header: "Save",
      width: "*",
      sort: false,
      customBody: (row) => (
        <td key={"save"}>
          <label
            className="btn btn_sm btn_gray_line"
            onClick={() => {
              modifySystemConfig(row);
            }}
          >
            수정
          </label>
        </td>
      ),
    },
  ];

  const modifySystemConfig = async (info) => {
    try {
      await request.put(`configs`, [info]);
      setMessage({ message: MessageCollection.MODIFY });
      setRefresh((prev) => !prev);
    } catch (error) {
      setAlert(error);
    }
  };

  const onChangeSettings = ({ event, row }, name) => {
    const { value } = event.target;
    const updatedRows = rows.map((item) =>
      item.key === row.key ? { ...item, [name]: value } : item
    );
    _setRows(updatedRows);
  };

  useEffect(() => {
    (async () => {
      const updatedTabMenuTitle = new Set();
      const configResponse = await request.get(`configs`);
      configResponse.data.data.map((data) => {
        updatedTabMenuTitle.add(data.service);
      });
      const transformedArray = Array.from(updatedTabMenuTitle).map((item) => ({
        name: item,
      }));
      setTabMenuTitle(transformedArray);
      setTabValue(Object.values(transformedArray)[0].name);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const configResponse = await request.get(`configs`);
      const filterdConfigRows = configResponse.data.data.filter((data) => {
        return data.service === tabValue;
      });
      const refinedConfigRows = filterdConfigRows.map((obj) => {
        return Object.keys(obj).reduce((acc, key) => {
          acc[key] = obj[key] === null ? "" : obj[key];
          return acc;
        }, {});
      });

      _setRows(refinedConfigRows);
      setGetTotalRows(filterdConfigRows.length);
    })();
  }, [tabValue, refresh]);

  // 최종 화면 나오는 곳
  return (
    <>
      <div className="wrapper">
        {<History title={title} breadcrumb={breadcrumb} />}
        <hr />
        {<Tab tabMenuTitle={tabMenuTitle} setItem={setTabValue} />}
        {
          <Table
            rows={rows}
            columns={columns}
            getTotalRows={getTotalRows}
            hidePagenation
          ></Table>
        }
      </div>
    </>
  );
};

export default SystemConfig;
