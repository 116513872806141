import React, {useEffect, useState} from "react";
import request from "../config/Axios";
import History from "../components/organisms/History/History";
import {Table} from "../components/organisms/Table/Table";
import Modal from "../components/organisms/Modal/Modal";
import {
    ButtonPlaceHolder,
    DefaultModalPageSize,
    DefaultPageSize,
    DefaultSortParams,
    MessageCollection,
    MessageColor,
    OrgModalName,
    UnSelectOrgMsg,
    checkMaxLength,
} from "../components/Default/DefaultValue";
import {AlertErrorState, AlertInfoState} from "..";
import {useRecoilState} from "recoil";
import CreateLocalCodeInModal from "../components/templates/CreateLocalCodeInModal/CreateLocalCodeInModal";
import SelectOrgModal from "../components/templates/SelectOrgModal/SelectOrgModal";

const ManageHospLocalCode = () => {
    const [refresh, setRefresh] = useState(false); // 검색에서 페이지 새로고침 되니까 있는게 좋음
    const [modalRefresh, setModalRefresh] = useState(false);
    const [alart, setAlert] = useRecoilState(AlertErrorState);
    const [message, setMessage] = useRecoilState(AlertInfoState);

    // 제목, 브레드크럼
    const title = "병원 별 로컬코드";
    const breadcrumb = [
        {name: "HOME", link: "./"},
        {name: "DTx 관리"},
        {name: "병원 별 로컬코드", link: "./ManageHospLocalCode"},
    ];

    const pageSize = DefaultPageSize;

    // 모달
    const [openModal, setOpenModal] = useState(false);
    const closeModal = () => {
        setOpenModal((openModal) => !openModal);
    };

    // 서브 모달
    const [subModalType, setSubModalType] = useState("");
    const [openSubModal, setOpenSubModal] = useState(false);
    const closeSubModal = () => {
        setOpenSubModal((openModal) => !openModal);
    };

    //Alert 모달
    const [openAlert, setOpenAlert] = useState("");
    const closeAlert = () => {
        setOpenAlert((openModal) => !openModal);
    };

    // 테이블 관련 변수
    const [totalElements, setTotalElements] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(5);
    const [sortParams, setSortParams] = useState(DefaultSortParams.DEFAULT);

    // 모달 테이블 관련 변수
    const [modalTotalElements, setModalTotalElements] = useState(0);
    const [modalPage, setModalPage] = useState(1);
    const [modalTotalPages, setModalTotalPages] = useState(5);
    const [modalSortParams, setModalSortParams] = useState(
        DefaultSortParams.DEFAULT
    );

    // 검색 조회
    const [tableSearchItems, setTableSearchItems] = useState([]);

    // 서브 모달 검색 조회
    const [modalTableSearchItems, setModalTableSearchItems] = useState([
        {
            name: ["orgName"],
            placeholder: ["기관명"],
            value: "",
            type: "text",
        },
    ]);

    const [selectedTurnNo, setSeletedTurnNo] = useState(0);
    const [localCodeData, setLocalCodeData] = useState({});
    const [localCodeRows, _setLocalCodeRows] = useState([]);
    const [totalLocalCodeElements, setTotalLocalCodeElements] = useState(0);

    const [selectedLocalCodeSet, setSelectedLocalCodeSet] = useState([]);

    const initLocalCodeRowData = {
        set_no: "",
        turn_no: "",
        local_prcp_code: "",
        desc: "",
        dtx_code: "",
        org_id: "",
    };

    // td 내용
    const [rows, _setRows] = useState([]);
    const [orgRows, _setOrgRows] = useState([]);

    // header,body 변경 th 내용
    const columns = [
        {
            name: "dtx_code",
            header: "DTx코드",
            width: "12%",
        },

        {
            name: "dtx_name",
            header: "DTx명",
            width: "25%",
        },
        {
            name: "org_name",
            header: "DTx기업",
            width: "24%",
        },
        {
            name: "total_turn",
            header: "총 회차",
            width: "8%",
        },
        {
            name: "enable",
            header: "사용 상태",
            width: "9%",
            customBody: (row) => (
                <td key={"enable"}>
          <span
              className={
                  row.enable === "true" ? "badge badge_blue" : "badge badge_red"
              }
          >
            {row.enable === "true" ? "사용" : "중지"}
          </span>
                </td>
            ),
        },
        {
            name: "create_at",
            header: "등록일",
            width: "15%",
        },
        {
            name: "update",
            header: "수정",
            width: "7%",
            sort: false,
            customBody: (row) => (
                <td key={"update"}>
                    <label
                        className="btn btn_sm btn_gray_line"
                        onClick={() => {
                            openEditModal(row.dtx_code);
                        }}
                    >
                        수정
                    </label>
                </td>
            ),
        },
    ];

    const localCodeColumns = [
        {
            name: "set_no",
            header: "No",
            width: "11.5%",
            rowSpan: selectedTurnNo,
            customBody: (row) => (
                <td
                    rowSpan={selectedTurnNo}
                    style={{
                        border: "1px solid #e9ecf0",
                        backgroundColor: "#fff",
                    }}
                    key="set_no"
                >
                    <input
                        type="text"
                        style={{textAlign: "center"}}
                        className="w45"
                        disabled
                        value={row.set_no}
                        onChange={(event) => onChangeSettings({event, row}, "set_no")}
                    />
                </td>
            ),
        },
        {
            name: "turn_no",
            header: "회차",
            width: "11.5%",
            customBody: (row) => (
                <td key="turn_no">
                    <input
                        style={{textAlign: "center"}}
                        className="w45"
                        disabled
                        value={row.turn_no}
                        onChange={(event) => onChangeSettings({event, row}, "turn_no")}
                    />
                </td>
            ),
        },
        {
            name: "local_prcp_code",
            header: "로컬코드",
            width: "32%",
            customBody: (row) => (
                <td key="local_prcp_code">
                    <input
                        type="text"
                        className="w180"
                        name={`${row.set_no}/${row.turn_no}`}
                        value={row.local_prcp_code}
                        onBlur={onChangeBlur}
                        placeholder="ex) 로컬코드 값"
                        onChange={(event) =>
                            onChangeSettings({event, row}, "local_prcp_code")
                        }
                    />
                </td>
            ),
        },
        {
            name: "desc",
            header: "설명",
            width: "32%",
            customBody: (row) => (
                <td key="desc">
                    <input
                        type="text"
                        className="w180"
                        value={row.desc}
                        onChange={(event) => onChangeSettings({event, row}, "desc")}
                    />
                </td>
            ),
        },
        {
            name: "add_rows",
            width: "13%",
            customHeader: () => (
                <th key="add_rows">
                    <div className="action_container">
                        <button className="btn btn_xs btn_black" onClick={addLocalCode}>
                            행추가
                        </button>
                    </div>
                </th>
            ),
            rowSpan: selectedTurnNo,
            customBody: (row) => (
                <td
                    rowSpan={selectedTurnNo}
                    key={"delete_rows"}
                    className="action_container"
                    style={{
                        backgroundColor: "#fff",
                    }}
                >
                    <button
                        className="btn btn_xs btn_gray"
                        onClick={() => {
                            deleteLocalCode(row.set_no);
                        }}
                    >
                        행삭제
                    </button>
                </td>
            ),
        },
    ];

    const onChangeBlur = async (event) => {
        const {name,value} = event.target;
        if (selectedLocalCodeSet.includes(value.trim())) {
            return;
        }
        if(value.length >0) {
            // try {
             const checkDuplicateCode =  await request.get(`codes/code-is-duplicated/${value.trim()}`)
             if(checkDuplicateCode === undefined){
                 setMessage({
                     message:
                         "해당 로컬코드는 이미 등록 되어있습니다. 다른 로컬코드 명을 사용해주세요.",
                     color: MessageColor.RED,
                 });
                 const uniqueVal = name.split("/");
                 const updatedRows = localCodeRows.map((item) =>
                     item.set_no === parseInt(uniqueVal[0]) &&
                     item.turn_no === parseInt(uniqueVal[1])
                         ? {...item, local_prcp_code: ""}
                         : item
                 );
                 _setLocalCodeRows(updatedRows);
             }
            }
    };

    const openEditModal = async (id) => {
        const localCodeResponse = await request.get(
            `codes/hosp/${tableSearchItems[0].value}/dtx/${id}`
        );
        setTotalLocalCodeElements(localCodeResponse.data.data.local_code_set_size);
        setLocalCodeData(localCodeResponse.data.data);
        setSeletedTurnNo(parseInt(localCodeResponse.data.data.total_turn));
        _setLocalCodeRows(localCodeResponse.data.data.local_code_set);

        const tempLocalCodeSet = [];
        localCodeResponse.data.data.local_code_set.map((data) => {
            tempLocalCodeSet.push(data.local_prcp_code);
        });
        setSelectedLocalCodeSet(tempLocalCodeSet);
        setOpenModal(true);
    };

    const onChangeSettings = ({event, row}, name) => {
        const {value} = event.target;
        if (name === "local_prcp_code") {
            if (checkMaxLength(value, 32) === true) {
                const updatedRows = localCodeRows.map((item) =>
                    item.set_no === row.set_no && item.turn_no === row.turn_no
                        ? {...item, [name]: value.trim()}
                        : item
                );
                _setLocalCodeRows(updatedRows);
            }
        } else {
            const updatedRows = localCodeRows.map((item) =>
                item.set_no === row.set_no && item.turn_no === row.turn_no
                    ? {...item, [name]: value}
                    : item
            );
            _setLocalCodeRows(updatedRows);
        }
    };

    const modifyLocalCode = async () => {
        let flag = true;
        localCodeRows.map((data) => {
            if (data.local_prcp_code === "") {
                flag = false;
            }
        });
        if (flag === false) {
            setMessage({
                message: "로컬코드는 빈값일 수 없습니다.",
                color: MessageColor.RED,
            });
            return;
        }
        const uniqueSet = new Set();
        localCodeRows.map((data) => {
            uniqueSet.add(data.local_prcp_code);
        });
        if (uniqueSet.size !== localCodeRows.length) {
            setMessage({
                message: "중복된 로컬코드 값이 있습니다.",
                color: MessageColor.RED,
            });
            return;
        }

        const requestData = {
            total_turn: localCodeRows.length,
            local_code_set: localCodeRows,
        };

        if (openAlert !== true) {
            setOpenAlert(true);
            return;
        }

        try {
            await request.put(
                `codes/hosp/${tableSearchItems[0].value}/dtx/${localCodeData.dtx_code}`,
                {...requestData}
            );
            setMessage({message: MessageCollection.MODIFY});
            closeAlert();
            closeModal();
        } catch (error) {
            setAlert(error);
        }
    };

    const addLocalCode = () => {
        const maxTempId = localCodeRows.reduce(
            (max, item) => (item.set_no > max ? item.set_no : max),
            0
        );
        _setLocalCodeRows((prevState) => {
            const newData = [];
            for (let turn_no = 1; turn_no <= selectedTurnNo; turn_no++) {
                newData.push({
                    ...initLocalCodeRowData,
                    set_no: maxTempId + 1,
                    turn_no: turn_no,
                });
            }
            return [...prevState, ...newData];
        });

        setTotalLocalCodeElements(localCodeRows.length + 1);
    };

    const deleteLocalCode = (id) => {
        const updatedLocalCodeRows = localCodeRows.filter(
            (item) => item.set_no !== id
        );
        _setLocalCodeRows(updatedLocalCodeRows);

        setTotalLocalCodeElements(localCodeRows.length - 1);
    };

    // 입력창값이 변경될때
    const onChangeSearchValue = (event) => {
        if (event.key === "Enter") {
            onClickSearchButton();
        }
        const {name, value} = event.target;
        setTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === name) {
                    return {...item, value};
                }
                return item;
            })
        );
    };

    // 검색 영역에서 검색버튼 눌렀을때 뜨는 알림창
    const onClickSearchButton = () => {
        if (tableSearchItems[0].value === "") {
            setMessage({message: UnSelectOrgMsg, color: "#48b878"});
        }
        setPage(1);
        setRefresh((prev) => !prev);
    };

    const selectOrg = (row) => {
        const refineTableSearchItems = [...tableSearchItems];

        if (subModalType === "HOSP") {
            refineTableSearchItems[0].display = row.org_name;
            refineTableSearchItems[0].value = row.org_id;
        }
        if (subModalType === "CORP") {
            refineTableSearchItems[1].display = row.org_name;
            refineTableSearchItems[1].value = row.org_id;
        }
        setTableSearchItems(refineTableSearchItems);
        closeSubModal();
    };

    const onClickOrgFilter = (value) => {
        setModalPage(1);
        setSubModalType(value);
        setModalTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === "orgName") {
                    return {...item, value: ""};
                }
                return item;
            })
        );
        setOpenSubModal(true);
    };

    const onClickOrgFilterForRemove = (value) => {
        if (value === "HOSP") {
            setTableSearchItems((prevState) =>
                prevState.map((item) => {
                    if (item.name.join(",") === "hospFilter") {
                        return {...item, value: "", display: ButtonPlaceHolder.HOSP_R};
                    }
                    return item;
                })
            );
        }
        if (value === "CORP") {
            setTableSearchItems((prevState) =>
                prevState.map((item) => {
                    if (item.name.join(",") === "corpFilter") {
                        return {...item, value: "", display: ButtonPlaceHolder.CORP};
                    }
                    return item;
                })
            );
        }
    };

    const onChangeModalSearchValue = (event) => {
        if (event.key === "Enter") {
            onClickModalSearchButton();
        }
        const {name, value} = event.target;
        setModalTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === name) {
                    return {...item, value};
                }
                return item;
            })
        );
    };

    const onClickModalSearchButton = () => {
        setModalPage(1);
        setModalRefresh((prev) => !prev);
    };

    useEffect(() => {
        (async () => {
            let searchItems = [
                {
                    name: ["hospFilter"],
                    type: "labelButton",
                    onClick: () => {
                        onClickOrgFilter("HOSP");
                    },
                    onClickRemove: () => {
                        onClickOrgFilterForRemove("HOSP");
                    },
                    display: ButtonPlaceHolder.HOSP_R,
                    value: "",
                },
                {
                    name: ["corpFilter"],
                    type: "labelButton",
                    onClick: () => {
                        onClickOrgFilter("CORP");
                    },
                    onClickRemove: () => {
                        onClickOrgFilterForRemove("CORP");
                    },
                    display: ButtonPlaceHolder.CORP,
                    value: "",
                },
                {
                    name: ["dtxCode", "dtxName"],
                    placeholder: ["DTx코드, DTx명"],
                    value: "",
                    type: "text",
                },
            ];

            setTableSearchItems(searchItems);

            _setRows([]);
            setTotalPages(0);
            setTotalElements(0);
        })();
    }, []);

    useEffect(() => {
        if (subModalType !== "") {
            (async () => {
                const organsResponse = await request.get("organs", {
                    params: {
                        page: modalPage - 1,
                        size: DefaultModalPageSize,
                        sort: modalSortParams,
                        type: subModalType,
                        name: modalTableSearchItems[0].value.trim(),
                    },
                });
                _setOrgRows(organsResponse.data.data.content);
                setModalTotalPages(organsResponse.data.data.totalPages);
                setModalTotalElements(organsResponse.data.data.totalElements);
            })();
        }
    }, [openSubModal, modalPage, modalSortParams, modalRefresh]);

    useEffect(() => {
        if (tableSearchItems.length > 0 && tableSearchItems[0].value !== "") {
            (async () => {
                const codesData = await request.get(`codes`, {
                    params: {
                        page: page - 1,
                        size: pageSize,
                        sort: sortParams,
                        hosp_id: tableSearchItems[0].value,
                        corp_id: tableSearchItems[1].value,
                        search_word_like: tableSearchItems[2].value.trim(),
                    },
                });
                _setRows(codesData.data.data.content);

                setTotalPages(codesData.data.data.totalPages);
                setTotalElements(codesData.data.data.totalElements);
            })();
        }
    }, [refresh, page, sortParams]);

    // 최종 화면 나오는 곳
    return (
        <>
            <div className="wrapper">
                {<History title={title} breadcrumb={breadcrumb}/>}
                <hr/>
                {
                    <Table
                        rows={rows}
                        columns={columns}
                        getTotalRows={totalElements}
                        search={tableSearchItems}
                        onChangeSearchValue={onChangeSearchValue}
                        onClickSearchButton={onClickSearchButton}
                        page={page}
                        totalPages={totalPages}
                        changePage={setPage}
                        sortParams={sortParams}
                        setSortParams={setSortParams}
                    />
                }
            </div>
            {
                <Modal
                    open={openModal}
                    close={closeModal}
                    title={"병원 별 로컬코드 수정"}
                    content={
                        <CreateLocalCodeInModal
                            localCodeData={localCodeData}
                            localCodeRows={localCodeRows}
                            localCodeColumns={localCodeColumns}
                            totalLocalCodeElements={totalLocalCodeElements}
                            closeModal={closeModal}
                            modifyLocalCode={modifyLocalCode}
                        />
                    }
                />
            }
            {
                <Modal
                    open={openSubModal}
                    close={closeSubModal}
                    title={
                        subModalType === "HOSP" ? OrgModalName.HOSP : OrgModalName.CORP
                    }
                    content={
                        <SelectOrgModal
                            rows={orgRows}
                            orgType={subModalType}
                            selectOrg={selectOrg}
                            getTotalRows={modalTotalElements}
                            search={modalTableSearchItems}
                            onChangeSearchValue={onChangeModalSearchValue}
                            onClickSearchButton={onClickModalSearchButton}
                            page={modalPage}
                            limit={DefaultModalPageSize}
                            totalPages={modalTotalPages}
                            changePage={setModalPage}
                            sortParams={modalSortParams}
                            setSortParams={setModalSortParams}
                        ></SelectOrgModal>
                    }
                />
            }
            {
                <Modal
                    size={"sm"}
                    open={openAlert}
                    close={closeAlert}
                    title={"병원 별 DTx 단가"}
                    content={
                        <div>
                            <div style={{marginBottom: "30px"}}>정말 저장하시겠습니까?</div>
                            <div style={{display: "flex", justifyContent: "right"}}>
                                <button className="btn btn_gray_line" onClick={closeAlert}>
                                    취소
                                </button>
                                <button
                                    style={{marginLeft: "30px"}}
                                    className="btn btn_black"
                                    onClick={modifyLocalCode}
                                >
                                    확인
                                </button>
                            </div>
                        </div>
                    }
                />
            }
        </>
    );
};

export default ManageHospLocalCode;
