import React from "react";
import Input from "../../atoms/Input/Input";
import DynamicTable from "../../organisms/DynamicTable/DynamicTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export const CreateDTxAPPModal = ({
  appData,
  onChangeAppData,
  installRows,
  installColumns,
  totalInstallElements,
  totalTurnElements,
  saveInstall,
  changeTurn,
  stateEnum,
  turnRows,
  turnColumns,
  createApp,
  modifyApp,
  closeModal,
  openSelectOrgModal,
  modalType,
  errorMessage,
}) => {
  return modalType === "modifyInstall" ? (
    <>
      <ul className="form_wrap mb50">
        <li>
          <label className="name">DTx코드</label>
          <Input
            type="text"
            name="dtx_code"
            value={appData.dtx_code}
            disabled
            errorMessage={errorMessage}
          />
        </li>
        <li style={{ marginBottom: "0px" }}>
          <label className="name">DTx명</label>
          <Input
            type="text"
            name="dtx_name"
            value={appData.dtx_name}
            disabled
            errorMessage={errorMessage}
          />
        </li>
        <li>
          <DynamicTable
            rows={installRows}
            columns={installColumns}
            totalElements={totalInstallElements}
          ></DynamicTable>
        </li>
      </ul>

      <div style={{ marginTop: "10px", fontWeight: "500" }}>
        * Redirect가 포함될 경우 다른 설치경로는 무시됩니다.
      </div>
      <div style={{ marginTop: "5px", fontWeight: "400", marginLeft: "12px" }}>
        <span>URL에 다음 파라미터를 사용할 수 있습니다.</span>
        <span style={{ color: "red" }}>__PHICODE__</span>
      </div>
      <div style={{ marginTop: "5px", fontWeight: "400", marginLeft: "12px" }}>
        <span style={{ fontWeight: "500" }}>example: </span>
        <span>https://some.url?phicode=</span>
        <span style={{ color: "red" }}>__PHICODE__</span>
      </div>
      <div className="btn_wrap">
        <div className="left"></div>
        <div className="center"></div>
        <div className="right">
          <button className="btn btn_gray_line" onClick={closeModal}>
            취소
          </button>
          <button className="btn btn_black" onClick={saveInstall}>
            저장
          </button>
        </div>
      </div>
    </>
  ) : (
    <>
      <ul className="form_wrap mb50">
        <li>
          <label className="name">DTx 코드</label>
          <Input
            type="text"
            name="dtx_code"
            value={appData.dtx_code}
            onChange={onChangeAppData}
            disabled
            errorMessage={errorMessage}
          />
        </li>
        <li>
          <label className="name">DTx 기업</label>
          <div style={{ marginBottom: "0px" }} className="search_box">
            <div key={appData.org_id} className="search_wrap">
              <Input
                type="text"
                name={"org_id"}
                value={appData.org_name}
                className="input_search_modal"
                placeholder={"DTx 기업 선택"}
                style={
                  modalType === "createApps"
                    ? {
                        backgroundColor: "#FAFAFA",
                        color: "#666670",
                        cursor: "pointer",
                      }
                    : {
                        backgroundColor: "#FAFAFA",
                        color: "#666670",
                      }
                }
                onClick={
                  modalType === "createApps" ? openSelectOrgModal : () => {}
                }
                onChange={() => {}}
                errorMessage={errorMessage}
              />
              {modalType === "createApps" ? (
                <button
                  className="btn_search"
                  onClick={openSelectOrgModal}
                ></button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </li>
        <li>
          <label className="name">DTx 명</label>
          <Input
            type="text"
            name="dtx_name"
            value={appData.dtx_name}
            onChange={onChangeAppData}
            errorMessage={errorMessage}
          />
        </li>
        <li>
          <label className="name">총회차</label>
          <Input
            type="text"
            style={modalType === "createApps" ? { width: "555px" } : {}}
            name="total_turn"
            value={appData.total_turn}
            onChange={onChangeAppData}
            disabled
            errorMessage={errorMessage}
          ></Input>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            {modalType !== "modifyApps" ? (
              <>
                <ExpandLessIcon onClick={() => changeTurn(1)}></ExpandLessIcon>
                <ExpandMoreIcon onClick={() => changeTurn(-1)}></ExpandMoreIcon>
              </>
            ) : (
              <></>
            )}
          </div>
        </li>
        <li style={{ marginBottom: "0px" }}>
          <label className="name">사용여부</label>
          <select
            name="enable"
            value={appData.enable}
            onChange={onChangeAppData}
          >
            {Object.entries(stateEnum).map((state) => {
              return (
                <option key={state[0]} value={state[1]}>
                  {state[0]}
                </option>
              );
            })}
          </select>
        </li>
        <li>
          <DynamicTable
            rows={turnRows}
            columns={turnColumns}
            totalElements={totalTurnElements}
          ></DynamicTable>
        </li>
      </ul>
      <div className="btn_wrap">
        <div className="left"></div>
        <div className="center"></div>
        <div className="right">
          <button className="btn btn_gray_line" onClick={closeModal}>
            취소
          </button>
          <button
            className="btn btn_black"
            onClick={modalType === "createApps" ? createApp : modifyApp}
          >
            저장
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateDTxAPPModal;
