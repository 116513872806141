import React, {useEffect, useState} from "react";
import request from "../config/Axios";
import History from "../components/organisms/History/History";
import {Table} from "../components/organisms/Table/Table";
import Modal from "../components/organisms/Modal/Modal";
import {useRecoilState} from "recoil";
import {AlertErrorState, AlertInfoState, UserDataState} from "../index";
import CreateOrgInModal from "../components/templates/CreateOrgInModal/CreateOrgInModal";
import {
    DefaultPageSize,
    DefaultSortParams,
    MessageCollection,
    MessageColor,
    checkMaxLength,
    checkNumberAndHyphen,
    isValidEmail,
    isValidPhoneNumber,
} from "../components/Default/DefaultValue";

const ManageOrganization = () => {
    const [refresh, setRefresh] = useState(false);
    const [alart, setAlert] = useRecoilState(AlertErrorState);
    const [errorMessage, setErrorMessage] = useState([]);
    const [message, setMessage] = useRecoilState(AlertInfoState);
    const [userData, setUserData] = useRecoilState(UserDataState);

    // 제목, 브레드크럼
    const title = "기관 정보(의료&DTx)";
    const breadcrumb = [
        {name: "HOME", link: "./"},
        {name: "플랫폼 운영"},
        {name: "기관 정보(의료&DTx)", link: "./ManageOrganization"},
    ];

    const pageSize = DefaultPageSize;

    // 모달
    const [modalType, setModalType] = useState("");
    const [detailModalType, setDetailModalType] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const closeModal = () => {
        setErrorMessage([]);
        setCheckedList(new Set());
        setAgreeCheckList(new Set());
        setOpenModal((openModal) => !openModal);
    };

    // 서브 모달
    const [openSubModal, setOpenSubModal] = useState(false);
    const closeSubModal = () => {
        setOpenSubModal((openModal) => !openModal);
    };

    //Alert 모달
    const [openAlert, setOpenAlert] = useState("");
    const closeAlert = () => {
        setOpenAlert((openModal) => !openModal);
    };

    // 테이블 관련 변수
    const [totalElements, setTotalElements] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(5);
    const [sortParams, setSortParams] = useState(DefaultSortParams.DEFAULT);

    // 검색 조회
    const [tableSearchItems, setTableSearchItems] = useState([]);

    // ENUM
    const [hospTypeEnum, setHospTypeEnum] = useState([]);

    //checkbox
    const agreeList = [
        {
            id: "ConnectDTxServiceRequest",
            display: "DTx 처방정보"
        },
        {
            id: "ConnectDTxEncounter",
            display: "진료정보",
            desc: "(진료의료기관, 진료과, 진료일)"
        },
        {
            id: "ConnectDTxPatient",
            display: "개인정보",
            desc:"(성별, 나이)"
        },
        {
            id: "ConnectDTxCondition",
            display: "진단정보"
        },
        {
            id: "ConnectDTxMedicationRequest",
            display: "약품처방정보",
            desc: "(처방약품)"
        },
        {
            id: "ConnectDTxVitalSignsObservation",
            display: "신체계측정보",
            desc: "(키, 몸무게, 혈압, 심박수) "
        },
        {
            id: "ConnectDTxObservation",
            display: "검사결과정보"
        }
        ];
    const [agreeCheckList, setAgreeCheckList] = useState(new Set());

    const reportList = ["PDF", "JPG", "PNG"];
    const [checkedList, setCheckedList] = useState(new Set());


    //주소
    const [postalCode, setPostalCode] = useState("");
    const [roadAddress, setRoadAddress] = useState("");
    const [detailAddress, setDetailAddress] = useState("");

    const [selectedId, setSelectedId] = useState("");

    const initOrganizationData = {
        org_name: "",
        biz_no: "",
        president_name: "",
        class_code: Object.values(hospTypeEnum)[0],
        postal_code: "",
        road_address: "",
        address_detail: "",
        tel_no: "",
        deposit_bank_name: "",
        deposit_bank_account: "",
        oid: "",
        cms_customer_no: "",
        tax_bill_email: "",
        oauth_client_id: "",
        oauth_client_secret: "",
        org_type: "",
        health_info_agree_list: [],
        result_type: []
    };

    const [organizationData, setOrganizationData] =
        useState(initOrganizationData);

    // td 내용
    const [rows, _setRows] = useState([]);

    // header,body 변경 th 내용
    const columns = [
        {
            name: "org_id",
            header: "아이디",
            width: "9%",
        },
        {
            name: "org_name",
            header: "기관명",
            width: "18%",
            customBody: (row) => <td key="org_name">{row.org_name}</td>,
        },

        {
            name: "org_type",
            header: "유형",
            width: "6%",
            customBody: (row) => (
                <td key="org_type">
                    {row.org_type === "HOSP" ? (
                        <span className="badge badge_blue">{row.org_type}</span>
                    ) : row.org_type === "CORP" ? (
                        <span className="badge badge_green">{row.org_type}</span>
                    ) : (
                        <span className="badge badge_black">{row.org_type}</span>
                    )}
                </td>
            ),
        },
        {
            name: "biz_no",
            header: "사업자 번호",
            width: "10%",
        },
        {
            name: "president_name",
            header: "대표자",
            width: "8%",
        },
        {
            name: "road_address",
            header: "주소",
            width: "27%",
        },
        {
            name: "create_at",
            header: "등록일",
            width: "15%",
            customBody: (row) => <td key="create_at">{row.update_at}</td>,
        },
        {
            name: "update",
            header: "수정",
            width: "7%",
            sort: false,
            customBody: (row) => (
                <td key={"update"}>
                    <label
                        className="btn btn_sm btn_gray_line"
                        onClick={() => {
                            openEditModal(row.org_id);
                        }}
                    >
                        수정
                    </label>
                </td>
            ),
        },
        {
            name: "org_id",
            header: "ID",
            width: "0%",
            customHeader: () => <th key="ord_id" style={{display: "none"}}></th>,
            customBody: () => <td key="ord_id" style={{display: "none"}}></td>,
        },
    ];

    const openEditModal = async (id) => {
        const selectedOrganResponse = await request.get(`organs/${id}`);


        const selectedOrgData = {...selectedOrganResponse.data.data};
        for (const key in selectedOrgData) {
            if (selectedOrgData[key] === null || selectedOrgData[key] === undefined) {
                selectedOrgData[key] = "";
            }
        }

        setDetailModalType("modify");
        if (selectedOrganResponse.data.data.org_type === "CORP") {
            setModalType("registryCorp");
        } else if (selectedOrganResponse.data.data.org_type === "HOSP") {
            //기존값 checked list에 넣어주기
            if (selectedOrgData.health_info_agree_list !== undefined && selectedOrgData.health_info_agree_list.length > 0) {
                const addList = new Set();
                selectedOrgData.health_info_agree_list.forEach(item => addList.add(item));
                setAgreeCheckList(addList);
            }
            if (selectedOrgData.result_type !== undefined && selectedOrgData.result_type.length > 0) {
                const addList = new Set();
                selectedOrgData.result_type.forEach(item => addList.add(item));
                setCheckedList(addList);
            }
            setModalType("registryHosp");
        }
        if (selectedOrganResponse.data.data.org_type === "PLAT") {
            setModalType("registryCorp");
            setDetailModalType("platform");
        } else {
            try {
                const oauthResponse = await request.get(
                    `organs/oauth/${selectedOrganResponse.data.data.org_id}`,
                    {
                        params: {
                            type: selectedOrganResponse.data.data.org_type,
                        },
                    }
                );
                selectedOrgData.oauth_client_id = oauthResponse.data.data.client_id;
                selectedOrgData.oauth_client_secret =
                    oauthResponse.data.data.secret_key;
            } catch (error) {
                setAlert({visible: true, message: error.response.data.msg});
            }
        }

        setPostalCode(selectedOrgData.postal_code);
        setRoadAddress(selectedOrgData.road_address);
        setDetailAddress(selectedOrgData.address_detail);
        setOrganizationData(selectedOrgData);
        setSelectedId(id);
        setOpenModal(true);
    };

    // 입력창값이 변경될때
    const onChangeSearchValue = (event) => {
        if (event.key === "Enter") {
            onClickSearchButton();
        }
        const {name, value} = event.target;
        setTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === name) {
                    return {...item, value};
                }
                return item;
            })
        );
    };

    // 검색 영역에서 검색버튼 눌렀을때 뜨는 알림창
    const onClickSearchButton = () => {
        setPage(1);
        setRefresh((prev) => !prev);
    };

    const createOrg = () => {
        setOrganizationData(initOrganizationData);
        setDetailModalType("create");
        setPostalCode("");
        setRoadAddress("");
        setDetailAddress("");
        setOpenModal(true);
    };

    // 테이블에다가 사용자 등록하는 모달 버튼
    const tableButton = {
        name: "의료기관 등록",
        onClick: () => {
            setModalType("registryHosp");
            createOrg();
        },
    };

    const tableButton2 = {
        name: "DTx 기업 등록",
        onClick: () => {
            setModalType("registryCorp");
            createOrg();
        },
    };

    const registryInst = async (e) => {
        setErrorMessage([]);

        const refinedOrganizationData = {
            ...organizationData,
            postal_code: postalCode,
            road_address: roadAddress,
            address_detail: detailAddress,
            health_info_agree_list: Array.from(agreeCheckList),
            result_type: Array.from(checkedList),
        };
        delete refinedOrganizationData.oauth_client_id;
        delete refinedOrganizationData.oauth_client_secret;

        refinedOrganizationData.org_type =
            modalType === "registryHosp" ? "HOSP" : "CORP";
        try {
            let flag = true;
            if (modalType === "registryHosp") {
                delete refinedOrganizationData.deposit_bank_name;
                delete refinedOrganizationData.deposit_bank_account;
                const checkItems = [
                    "org_name",
                    "biz_no",
                    "president_name",
                    "postal_code",
                    "address_detail",
                    "tel_no",
                    "oid",
                    "cms_customer_no",
                    "tax_bill_email",
                ];
                checkItems.map((data) => {
                    if (flag === true && refinedOrganizationData[data] === "") {
                        setErrorMessage([data, "공백일 수 없습니다."]);
                        flag = false;
                    }
                });
                if (flag === false) {
                    return;
                }
                if (!checkMaxLength(refinedOrganizationData.org_name, 32)) {
                    setErrorMessage(["org_name", "기관명은 32자 이하로 입력해주세요."]);
                    return;
                }
                if (!checkMaxLength(refinedOrganizationData.president_name, 16)) {
                    setErrorMessage([
                        "president_name",
                        "대표명은 16자 이하로 입력해주세요.",
                    ]);
                    return;
                }
                if (!checkNumberAndHyphen(refinedOrganizationData.biz_no, 16)) {
                    setErrorMessage([
                        "biz_no",
                        "숫자와 하이픈으로 구성하여 16자 이하로 입력해주세요.",
                    ]);
                    return;
                }
                if (
                    !checkNumberAndHyphen(refinedOrganizationData.cms_customer_no, 32)
                ) {
                    setErrorMessage([
                        "cms_customer_no",
                        "고객번호는 32자 이하로 입력해주세요.",
                    ]);
                    return;
                }
                if (!checkMaxLength(refinedOrganizationData.org_name, 32)) {
                    setErrorMessage(["org_name", "기관명은 32자 이하로 입력해주세요."]);
                    return;
                }
                if (!isValidPhoneNumber(refinedOrganizationData.tel_no)) {
                    setErrorMessage(["tel_no", "올바른 전화번호 형식을 입력해주세요."]);
                    return;
                }
                if (!isValidEmail(refinedOrganizationData.tax_bill_email)) {
                    setErrorMessage([
                        "tax_bill_email",
                        "올바른 이메일 형식을 입력해주세요.",
                    ]);
                    return;
                }
                if (refinedOrganizationData.result_type.length === 0) {
                    setErrorMessage([
                        "result",
                        "결과 레포트 형식을 선택해주세요.",
                    ]);
                    return;
                }
            }
            if (modalType === "registryCorp") {
                delete refinedOrganizationData.oid;
                delete refinedOrganizationData.cms_customer_no;
                const checkItems = [
                    "org_name",
                    "biz_no",
                    "president_name",
                    "postal_code",
                    "address_detail",
                    "tel_no",
                    "deposit_bank_name",
                    "deposit_bank_account",
                    "tax_bill_email",
                ];
                checkItems.map((data) => {
                    if (flag === true && refinedOrganizationData[data] === "") {
                        setErrorMessage([data, "공백일 수 없습니다."]);
                        flag = false;
                    }
                });
                if (flag === false) {
                    return;
                }
                if (!checkMaxLength(refinedOrganizationData.org_name, 32)) {
                    setErrorMessage(["org_name", "기업명은 32자 이하로 입력해주세요."]);
                    return;
                }
                if (!checkNumberAndHyphen(refinedOrganizationData.biz_no, 16)) {
                    setErrorMessage([
                        "biz_no",
                        "숫자와 하이픈으로 구성하여 16자 이하로 입력해주세요.",
                    ]);
                    return;
                }
                if (!checkMaxLength(refinedOrganizationData.president_name, 16)) {
                    setErrorMessage([
                        "president_name",
                        "대표명은 16자 이하로 입력해주세요.",
                    ]);
                    return;
                }
                if (!isValidPhoneNumber(organizationData.tel_no)) {
                    setErrorMessage(["tel_no", "올바른 전화번호 형식을 입력해주세요."]);
                    return;
                }
                if (!checkMaxLength(organizationData.deposit_bank_name, 16)) {
                    setErrorMessage([
                        "deposit_bank_name",
                        "은행명은 16자 이하로 입력해주세요.",
                    ]);
                    return;
                }
                if (!checkNumberAndHyphen(organizationData.deposit_bank_account, 24)) {
                    setErrorMessage([
                        "deposit_bank_account",
                        "숫자와 하이픈으로 구성하여 24자 이하로 입력해주세요.",
                    ]);
                    return;
                }
                if (!isValidEmail(organizationData.tax_bill_email)) {
                    setErrorMessage([
                        "tax_bill_email",
                        "올바른 이메일 형식을 입력해주세요.",
                    ]);
                    return;
                }
            }
            if (openAlert !== true) {
                setOpenAlert(true);
                return;
            }

            if (modalType === "registryCorp" && detailModalType === "platform") {
                refinedOrganizationData.org_type = "PLAT";
                await request.put(`organs/${selectedId}`, {
                    ...refinedOrganizationData,
                });
                if (userData.org_id === refinedOrganizationData.org_id) {
                    setUserData({...userData, ...refinedOrganizationData.org_name});
                }
                closeAlert();
                closeModal();
                setMessage({message: MessageCollection.MODIFY});
                setRefresh((prev) => !prev);
                return;
            }
            detailModalType === "create"
                ? await request.post("organs", {...refinedOrganizationData})
                : await request.put(`organs/${selectedId}`, {
                    ...refinedOrganizationData,
                });

            const customMessage =
                detailModalType === "create"
                    ? MessageCollection.CREATE
                    : MessageCollection.MODIFY;

            if (detailModalType === "create") {
                setTableSearchItems((prevState) =>
                    prevState.map((item, index) => {
                        if (index === 0) {
                            return {...item, value: ""};
                        }
                        return item;
                    })
                );
                setPage(1);
            }
            setMessage({message: customMessage});
            setRefresh((prev) => !prev);
            closeAlert();
            closeModal();
            setCheckedList(new Set());
        } catch (error) {
            if (error.response.status === 400) {
                let matchFound = false;
                Object.keys(initOrganizationData).forEach((orderKey) => {
                    if (matchFound) {
                        return;
                    }
                    Object.entries(error.response.data.detail).forEach((data) => {
                        for (const [key, value] of Object.entries(data[1])) {
                            if (orderKey === key) {
                                setErrorMessage([key, value]);
                                matchFound = true;
                                return;
                            }
                        }
                    });
                });
            } else {
                setAlert(error);
            }
        }
    };
    const onchangeOrganizationData = (event) => {
        const {name, value} = event.target;
        setOrganizationData((prevState) => ({
            ...prevState,
            [name]: value,
        }));

    };

    const onChangeBlur = async (event) => {
        const {name, value} = event.target;
        if (name === "biz_no" && value !== "") {
            const checkDuplicateBizno = await request.get(`organs/bizno-is-duplicated/${value}`);
            if (checkDuplicateBizno === undefined) {
                setOrganizationData((prev) => ({...prev, biz_no: ""}));
                setMessage({
                    message: "해당 사업자등록번호는 이미 등록 되어있습니다.",
                    color: MessageColor.RED,
                });
            }
        }
        if (name === "oid" && value !== "") {
            const checkDuplicateOid = await request.get(`organs/oid-is-duplicated/${value}`);
            if (checkDuplicateOid === undefined) {
                setOrganizationData((prev) => ({...prev, oid: ""}));
                setMessage({
                    message: "해당 oid는 이미 등록 되어있습니다.",
                    color: MessageColor.RED,
                });
            }
        }
    };

    const openResetOauth = () => {
        setOpenSubModal(true);
    };

    const resetOauth = async () => {
        try {
            await request.get(`organs/oauth-reset/${organizationData.org_id}`);

            const orgOauthResponse = await request.get(
                `organs/oauth/${organizationData.org_id}`,
                {
                    params: {
                        type: organizationData.org_id.substr(0, 4),
                    },
                }
            );
            const updatedOrganizationData = {...organizationData};
            updatedOrganizationData.oauth_client_id =
                orgOauthResponse.data.data.client_id;
            updatedOrganizationData.oauth_client_secret =
                orgOauthResponse.data.data.secret_key;
            setOrganizationData(updatedOrganizationData);
            setMessage({message: MessageCollection.INITOAUTH});
            closeSubModal();
        } catch (error) {
            setAlert(error);
        }
    };

    useEffect(() => {
        (async () => {
            const updateHospTypeEnum = {};
            const hospEnumResponse = await request.get(`commons/type/hosp`);
            hospEnumResponse.data.data.map((data) => {
                updateHospTypeEnum[data.display] = data.code;
            });
            setHospTypeEnum(updateHospTypeEnum);


            const updatedOrgTypeEnum = {전체: "*"};
            const orgEnumResponse = await request.get(`commons/type/organ`);
            orgEnumResponse.data.data.map((data) => {
                updatedOrgTypeEnum[data.display] = data.codePrefix;
            });


            setTableSearchItems([
                {
                    name: ["orgFilter"],
                    filter: updatedOrgTypeEnum,
                    value: Object.values(updatedOrgTypeEnum)[0],
                    type: "select",
                },
                {
                    name: ["org_name"],
                    placeholder: ["기관명"],
                    value: "",
                    type: "text",
                },
            ]);
        })();
    }, []);

    useEffect(() => {
        if (tableSearchItems.length > 0) {
            (async () => {
                const organsResponse = await request.get(`organs`, {
                    params: {
                        page: page - 1,
                        size: pageSize,
                        type: tableSearchItems[0].value,
                        name: tableSearchItems[1].value.trim(),
                        sort: sortParams,
                    },
                });
                _setRows(organsResponse.data.data.content);
                setTotalPages(organsResponse.data.data.totalPages);
                setTotalElements(organsResponse.data.data.totalElements);
            })();
        } else {
            setTimeout(() => {
                setRefresh((prev) => !prev);
            }, [10]);
        }
    }, [refresh, page, sortParams]);

    return (
        <>
            <div className="wrapper">
                {<History title={title} breadcrumb={breadcrumb}/>}
                <hr/>
                {
                    <Table
                        rows={rows}
                        columns={columns}
                        getTotalRows={totalElements}
                        search={tableSearchItems}
                        onChangeSearchValue={onChangeSearchValue}
                        onClickSearchButton={onClickSearchButton}
                        button={tableButton}
                        button2={tableButton2}
                        page={page}
                        totalPages={totalPages}
                        changePage={setPage}
                        sortParams={sortParams}
                        setSortParams={setSortParams}
                    />
                }
            </div>
            {
                <Modal
                    open={openModal}
                    close={closeModal}
                    title={
                        (modalType === "registryCorp") & (detailModalType === "create")
                            ? "DTx기업 정보 등록"
                            : (modalType === "registryCorp") & (detailModalType === "modify")
                                ? "DTx기업 정보 수정"
                                : (modalType === "registryHosp") & (detailModalType === "create")
                                    ? "의료기관 등록"
                                    : (modalType === "registryHosp") & (detailModalType === "modify")
                                        ? "의료기관 수정"
                                        : "플랫폼 수정"
                    }
                    content={
                        <CreateOrgInModal
                            organizationData={organizationData}
                            onchangeOrganizationData={onchangeOrganizationData}
                            onChangeBlur={onChangeBlur}
                            hospTypeEnum={hospTypeEnum}
                            reportList={reportList}
                            checkedList={checkedList}
                            setCheckedList={setCheckedList}
                            agreeList={agreeList}
                            agreeCheckList={agreeCheckList}
                            setAgreeCheckList={setAgreeCheckList}
                            postalCode={postalCode}
                            detailAddress={detailAddress}
                            setDetailAddress={setDetailAddress}
                            setPostalCode={setPostalCode}
                            roadAddress={roadAddress}
                            setRoadAddress={setRoadAddress}
                            registryInst={registryInst}
                            openResetOauth={openResetOauth}
                            closeModal={closeModal}
                            modalType={modalType}
                            detailModalType={detailModalType}
                            errorMessage={errorMessage}
                        ></CreateOrgInModal>
                    }
                />
            }
            {
                <Modal
                    size="sm"
                    open={openSubModal}
                    close={closeSubModal}
                    title={"Oauth 초기화"}
                    content={
                        <div>
                            <div style={{marginBottom: "30px"}}>
                                client_secret이 초기화되면 현재 사용중인 서비스에 장애가 발생할
                                수 있습니다. 진행하시겠습니까?
                            </div>
                            <div style={{display: "flex", justifyContent: "right"}}>
                                <button className="btn btn_gray_line" onClick={closeSubModal}>
                                    취소
                                </button>
                                <button
                                    style={{marginLeft: "30px"}}
                                    className="btn btn_black"
                                    onClick={resetOauth}
                                >
                                    확인
                                </button>
                            </div>
                        </div>
                    }
                />
            }
            {
                <Modal
                    size={"sm"}
                    open={openAlert}
                    close={closeAlert}
                    title={"기관 정보"}
                    content={
                        <div>
                            <div style={{marginBottom: "30px"}}>정말 저장하시겠습니까?</div>
                            <div style={{display: "flex", justifyContent: "right"}}>
                                <button className="btn btn_gray_line" onClick={closeAlert}>
                                    취소
                                </button>
                                <button
                                    style={{marginLeft: "30px"}}
                                    className="btn btn_black"
                                    onClick={registryInst}
                                >
                                    확인
                                </button>
                            </div>
                        </div>
                    }
                />
            }
        </>
    );
};

export default ManageOrganization;
