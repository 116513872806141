import React, { useEffect, useState } from "react";
import request from "../config/Axios";
import History from "../components/organisms/History/History";
import { Table } from "../components/organisms/Table/Table";
import Modal from "../components/organisms/Modal/Modal";
import moment from "moment";
import {
  ButtonPlaceHolder,
  DefaultModalPageSize,
  DefaultPageSize,
  DefaultSearchDay,
  DefaultSortParams,
  MessageCollection,
  OrgModalName,
  copyToClipboard,
} from "../components/Default/DefaultValue";

import { useRecoilState } from "recoil";
import { AlertInfoState } from "..";
import SelectOrgModal from "../components/templates/SelectOrgModal/SelectOrgModal";

const InquireDtxUsage = () => {
  const [refresh, setRefresh] = useState(false); // 검색에서 페이지 새로고침 되니까 있는게 좋음
  const [modalRefresh, setModalRefresh] = useState(false);
  const [message, setMessage] = useRecoilState(AlertInfoState);

  // 제목, 브레드크럼
  const title = "DTx사용 정보";
  const breadcrumb = [
    { name: "HOME", link: "./" },
    { name: "데이터 관리" },
    { name: "DTx사용 정보", link: "./InquireDtxUsage" },
  ];

  const pageSize = DefaultPageSize;

  // 모달
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const closeModal = () => {
    setOpenModal((openModal) => !openModal);
  };

  // 테이블 관련 변수
  const [totalElements, setTotalElements] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const [sortParams, setSortParams] = useState(DefaultSortParams.LAST);

  // 모달 테이블 관련 변수
  const [modalTotalElements, setModalTotalElements] = useState(0);
  const [modalPage, setModalPage] = useState(1);
  const [modalTotalPages, setModalTotalPages] = useState(5);
  const [modalSortParams, setModalSortParams] = useState(
    DefaultSortParams.DEFAULT
  );

  // 검색조회부분
  const [tableSearchItems, setTableSearchItems] = useState([]);
  // 서브 모달 검색 조회
  const [modalTableSearchItems, setModalTableSearchItems] = useState([
    {
      name: ["orgName"],
      placeholder: ["기관명"],
      value: "",
      type: "text",
    },
  ]);

  // td 내용
  const [rows, _setRows] = useState([]);
  const [orgRows, _setOrgRows] = useState([]);

  // th 내용
  const columns = [
    {
      name: "hosp_name",
      header: "기관",
      width: "*",
    },
    {
      name: "hosp_prcp_seq",
      header: "번호",
      width: "*",
      customBody: (row) => (
        <td key="hosp_prcp_seq">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="ellipsis">{row.hosp_prcp_seq}</div>
            <div
              className="attachIcon"
              style={{ width: "18px" }}
              onClick={() => {
                copyToClipboard(row.hosp_prcp_seq);
                setMessage({ message: MessageCollection.COPY });
              }}
            ></div>
          </div>
        </td>
      ),
    },
    {
      name: "prcp_recv_dt",
      header: "수신일",
      width: "*",
    },
    {
      name: "status",
      header: "상태",
      width: "*",
      customBody: (row) => (
          <td key="status">
            <div>{row.status.desc}</div>
          </td>
      ),
    },
    {
      name: "phi_code",
      header: "PHI코드",
      width: "*",
      customBody: (row) => (
        <td key="phi_code">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="ellipsis">{row.phi_code}</div>
            <div
              className="attachIcon"
              style={{ width: "18px" }}
              onClick={() => {
                copyToClipboard(row.phi_code);
                setMessage({ message: MessageCollection.COPY });
              }}
            ></div>
          </div>
        </td>
      ),
    },
    {
      name: "dtx_corp_name",
      header: "DTx 기업",
      width: "*",
    },
    {
      name: "dtx_name",
      header: "DTx명",
      width: "*",
    },
    {
      name: "turn_no",
      header: "사용/총 회차",
      width: "*",
      customBody: (row) => (
        <td key="turn_no">{`${row.turn_no} / ${row.turn_total}`}</td>
      ),
      sort: false,
    },
    {
      name: "result_last_recv_dt",
      header: "수신일",
      width: "*",
    },
    {
      name: "result",
      header: "결과",
      width: "*",
      sort: false,
      customBody: (row) => (
        <td key={"update"}>
          <label
            className="btn btn_sm btn_gray_line"
            onClick={() => {
              openResult(row.phi_code);
            }}
          >
            보기
          </label>
        </td>
      ),
    },
  ];

  const spanColumns = [
    {
      name: "prescriptionInformation",
      header: "처방 정보",
      colSpan: 4,
    },
    {
      name: "dtx",
      header: "DTx",
      colSpan: 4,
    },
    {
      name: "dtxResult",
      header: "DTx 결과",
      colSpan: 3,
    },
  ];

  const openResult = async (id) => {
    const resultResponse = await request.get(`use/results/${id}`);

    const base64Data = resultResponse.data.data.body;

    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const blobURL = URL.createObjectURL(blob);

    window.open(blobURL, "_blank");
  };

  // 입력창값이 변경될때
  const onChangeSearchValue = (event) => {
    if (event.key === "Enter") {
      onClickSearchButton();
    }
    const { name, value } = event.target;
    setTableSearchItems((prevState) =>
      prevState.map((item) => {
        if (item.name.join(",") === name) {
          return { ...item, value };
        }
        return item;
      })
    );
    if (name === "startDate") {
      setTableSearchItems((prevState) =>
        prevState.map((item) => {
          if (item.name.join(",") === "endDate") {
            return {
              ...item,
              value:
                item.value > moment(value).add(30, "day").format("YYYY-MM-DD")
                  ? moment(value).add(30, "day").format("YYYY-MM-DD")
                  : item.value < value
                  ? value
                  : item.value,
            };
          }
          return item;
        })
      );
    }
    if (name === "endDate") {
      setTableSearchItems((prevState) =>
        prevState.map((item) => {
          if (item.name.join(",") === "startDate") {
            return {
              ...item,
              value:
                item.value <
                moment(value).subtract(30, "day").format("YYYY-MM-DD")
                  ? moment(value).subtract(30, "day").format("YYYY-MM-DD")
                  : item.value > value
                  ? value
                  : item.value,
            };
          }
          return item;
        })
      );
    }
  };

  const onClickSearchButton = () => {
    setPage(1);
    setRefresh((prev) => !prev);
  };

  const selectOrg = (row) => {
    if (modalType === "HOSP") {
      setTableSearchItems((prevState) =>
        prevState.map((item) => {
          if (item.name.join(",") === "hospFilter") {
            return { ...item, value: row.org_id, display: row.org_name };
          }
          return item;
        })
      );
    }
    if (modalType === "CORP") {
      setTableSearchItems((prevState) =>
        prevState.map((item) => {
          if (item.name.join(",") === "corpFilter") {
            return { ...item, value: row.org_id, display: row.org_name };
          }
          return item;
        })
      );
    }

    closeModal();
  };

  const onClickHospFilter = () => {
    setModalPage(1);
    setModalTableSearchItems((prevState) =>
      prevState.map((item) => {
        if (item.name.join(",") === "orgName") {
          return { ...item, value: "" };
        }
        return item;
      })
    );
    setModalType("HOSP");
    setOpenModal(true);
  };

  const onClickCorpFilter = () => {
    setModalPage(1);
    setModalTableSearchItems((prevState) =>
      prevState.map((item) => {
        if (item.name.join(",") === "orgName") {
          return { ...item, value: "" };
        }
        return item;
      })
    );
    setModalType("CORP");
    setOpenModal(true);
  };

  const onClickOrgFilterForRemove = (value) => {
    if (value === "HOSP") {
      setTableSearchItems((prevState) =>
        prevState.map((item) => {
          if (item.name.join(",") === "hospFilter") {
            return { ...item, value: "*", display: ButtonPlaceHolder.HOSP };
          }
          return item;
        })
      );
    }
    if (value === "CORP") {
      setTableSearchItems((prevState) =>
        prevState.map((item) => {
          if (item.name.join(",") === "corpFilter") {
            return { ...item, value: "*", display: ButtonPlaceHolder.CORP };
          }
          return item;
        })
      );
    }
  };

  const onChangeModalSearchValue = (event) => {
    if (event.key === "Enter") {
      onClickModalSearchButton();
    }
    const { name, value } = event.target;
    setModalTableSearchItems((prevState) =>
      prevState.map((item) => {
        if (item.name.join(",") === name) {
          return { ...item, value };
        }
        return item;
      })
    );
  };

  const onClickModalSearchButton = () => {
    setModalPage(1);
    setModalRefresh((prev) => !prev);
  };

  useEffect(() => {
    (async () => {
      const organsReponse = await request.get(`organs`, {
        params: { page: 0, size: 99999 },
      });
      const updateHospOrgEnum = { "의료기관 선택": "*" };
      organsReponse.data.data.content.map((data) => {
        if (data.org_type === "HOSP") {
          updateHospOrgEnum[data.org_name] = data.org_id;
        }
      });
      let searchItems = [
        {
          name: ["startDate"],
          value: moment(new Date())
            .subtract(DefaultSearchDay, "day")
            .format("YYYY-MM-DD"),
          type: "date",
          label: "수신일",
        },
        {
          name: ["endDate"],
          value: moment(new Date()).format("YYYY-MM-DD"),
          type: "date",
        },
        {
          name: ["hospFilter"],
          type: "labelButton",
          onClick: onClickHospFilter,
          onClickRemove: () => {
            onClickOrgFilterForRemove("HOSP");
          },
          display: ButtonPlaceHolder.HOSP,
          value: "*",
        },
        {
          name: ["corpFilter"],
          type: "labelButton",
          onClick: onClickCorpFilter,
          onClickRemove: () => {
            onClickOrgFilterForRemove("CORP");
          },
          display: ButtonPlaceHolder.CORP,
          value: "*",
        },
        {
          name: ["hosp_prcp_seq", "phiCode", "dtxName"],
          placeholder: ["처방번호, PHI코드"],
          value: "",
          type: "text",
        },
      ];

      setTableSearchItems(searchItems);
      setRefresh((prev) => !prev);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const organsResponse = await request.get("organs", {
        params: {
          page: modalPage - 1,
          size: DefaultModalPageSize,
          sort: modalSortParams,
          name: modalTableSearchItems[0].value.trim(),
          type: modalType,
        },
      });
      _setOrgRows(organsResponse.data.data.content);
      setModalTotalPages(organsResponse.data.data.totalPages);
      setModalTotalElements(organsResponse.data.data.totalElements);
    })();
  }, [openModal, modalPage, modalSortParams, modalRefresh]);

  useEffect(() => {
    if (tableSearchItems.length > 0) {
      (async () => {
        //유효하지 않은 날짜 체크
        if(!moment(tableSearchItems[0].value).isValid() || !moment(tableSearchItems[1].value).isValid()){
          return;
        }

        const resultsData = await request.get(`use/results`, {
          params: {
            page: page - 1,
            size: pageSize,
            sort: sortParams,
            start_date: moment(tableSearchItems[0].value).format("YYYYMMDD"),
            end_date: moment(tableSearchItems[1].value).format("YYYYMMDD"),
            hosp_id: tableSearchItems[2].value,
            corp_id: tableSearchItems[3].value,
            search_word_like:
              tableSearchItems[4].value === ""
                ? "*"
                : tableSearchItems[4].value.trim(),
          },
        });
        _setRows(resultsData.data.data.content);
        setTotalPages(resultsData.data.data.totalPages);
        setTotalElements(resultsData.data.data.totalElements);
      })();
    }
  }, [refresh, page, sortParams]);

  // 최종 화면 나오는 곳
  return (
    <>
      <div className="wrapper">
        {<History title={title} breadcrumb={breadcrumb} />}
        <hr />
        {
          <Table
            rows={rows}
            spanColumns={spanColumns}
            columns={columns}
            getTotalRows={totalElements}
            search={tableSearchItems}
            onChangeSearchValue={onChangeSearchValue}
            onClickSearchButton={onClickSearchButton}
            page={page}
            totalPages={totalPages}
            changePage={setPage}
            sortParams={sortParams}
            setSortParams={setSortParams}
          />
        }
      </div>
      {
        <Modal
          open={openModal}
          close={closeModal}
          title={modalType === "HOSP" ? OrgModalName.HOSP : OrgModalName.CORP}
          content={
            <SelectOrgModal
              rows={orgRows}
              orgType={modalType}
              selectOrg={selectOrg}
              getTotalRows={modalTotalElements}
              search={modalTableSearchItems}
              onChangeSearchValue={onChangeModalSearchValue}
              onClickSearchButton={onClickModalSearchButton}
              page={modalPage}
              limit={DefaultModalPageSize}
              totalPages={modalTotalPages}
              changePage={setModalPage}
              sortParams={modalSortParams}
              setSortParams={setModalSortParams}
            ></SelectOrgModal>
          }
        />
      }
    </>
  );
};

export default InquireDtxUsage;
