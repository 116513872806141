export const localLogTypeEnum = {
  code: "0",
  msg: "공용 코드 로그 기록x",
  detail: "",
  data: [
    {
      display: "공용 코드 로그 기록x",
      value: "operation:000",
    },
    {
      display: "사용자 로그인",
      value: "operation:001",
    },
    {
      display: "사용자 로그아웃",
      value: "operation:002",
    },
    {
      display: "패스워드 만료",
      value: "operation:003",
    },
    {
      display: "패스워드 초기화",
      value: "operation:004",
    },
    {
      display: "사용자 정보 조회",
      value: "operation:101",
    },
    {
      display: "기관/업체 정보 조회",
      value: "operation:111",
    },
    {
      display: "대시보드 조회",
      value: "operation:115",
    },
    {
      display: "DTx 조회",
      value: "operation:131",
    },
    {
      display: "로컬코드 조회",
      value: "operation:132",
    },
    {
      display: "dtx price 조회",
      value: "operation:133",
    },
    {
      display: "DTx 결과 조회",
      value: "operation:134",
    },
    {
      display: "DTx 처방 조회",
      value: "operation:135",
    },
    {
      display: "정산 정보 조회",
      value: "operation:136",
    },
    {
      display: "client id/secret 조회",
      value: "operation:151",
    },
    {
      display: "로그 조회",
      value: "operation:161",
    },
    {
      display: "설정 정보 조회",
      value: "operation:181",
    },
    {
      display: "정보제공동의 조회",
      value: "operation:171",
    },
    {
      display: "사용자 등록",
      value: "operation:200",
    },
    {
      display: "Platform 사용자 등록",
      value: "operation:201",
    },
    {
      display: "DTx 사용자 등록",
      value: "operation:202",
    },
    {
      display: "기관 사용자 등록",
      value: "operation:203",
    },
    {
      display: "기관/업체 등록",
      value: "operation:210",
    },
    {
      display: "플랫폼 등록",
      value: "operation:211",
    },
    {
      display: "기업 등록",
      value: "operation:212",
    },
    {
      display: "병원 등록",
      value: "operation:213",
    },
    {
      display: "DTx 등록",
      value: "operation:230",
    },
    {
      display: "DTx App 등록",
      value: "operation:231",
    },
    {
      display: "DTx App 회차정보 등록",
      value: "operation:232",
    },
    {
      display: "DTx App 설치 정보 등록",
      value: "operation:233",
    },
    {
      display: "사용자 정보 수정",
      value: "operation:300",
    },
    {
      display: "Platform 사용자 수정",
      value: "operation:301",
    },
    {
      display: "DTx 사용자 수정",
      value: "operation:302",
    },
    {
      display: "기관 사용자 수정",
      value: "operation:303",
    },
    {
      display: "패스워드 변경",
      value: "operation:309",
    },
    {
      display: "조직/기관 정보 수정",
      value: "operation:310",
    },
    {
      display: "PLATFORM 정보 수정",
      value: "operation:311",
    },
    {
      display: "DTx 기업 수정",
      value: "operation:312",
    },
    {
      display: "의료 기관 정보 수정",
      value: "operation:313",
    },
    {
      display: "DTx App 수정",
      value: "operation:330",
    },
    {
      display: "DTx App 수정(유효기간, 단가 포함)",
      value: "operation:331",
    },
    {
      display: "로컬 코드 업데이트",
      value: "operation:332",
    },
    {
      display: "DTx 단가 업데이트",
      value: "operation:333",
    },
    {
      display: "정보제공동의 업데이트",
      value: "operation:371",
    },
    {
      display: "시스템 설정 정보 업데이트",
      value: "operation:381",
    },
    {
      display: "사용자 삭제",
      value: "operation:400",
    },
    {
      display: "Platform 사용자 삭제",
      value: "operation:401",
    },
    {
      display: "DTx 사용자 삭제",
      value: "operation:402",
    },
    {
      display: "기관 사용자 삭제",
      value: "operation:403",
    },
    {
      display: "기관/업체 삭제",
      value: "operation:410",
    },
    {
      display: "플랫폼 삭제",
      value: "operation:411",
    },
    {
      display: "DTx 업체 삭제",
      value: "operation:412",
    },
    {
      display: "기관 삭제",
      value: "operation:413",
    },
    {
      display: "DTx 삭제",
      value: "operation:430",
    },
    {
      display: "정보제공동의 철회",
      value: "operation:470",
    },
    {
      display: "정보제공동의 전체 철회",
      value: "operation:471",
    },
    {
      display: "인증 서버 요청",
      value: "operation:500",
    },
    {
      display: "Client Secret 초기화 요청",
      value: "operation:502",
    },
    {
      display: "FHIR 서버 요청",
      value: "operation:530",
    },
    {
      display: "정산 상태 변경",
      value: "operation:700",
    },
    {
      display: "이슈",
      value: "operation:800",
    },
    {
      display: "메일 전송",
      value: "operation:801",
    },
    {
      display: "가입신청 메일 전송",
      value: "operation:802",
    },
    {
      display: "잘못된 접근",
      value: "operation:891",
    },
    {
      display: "본인 인증",
      value: "privacy:10",
    },
    {
      display: "oauth 요청",
      value: "audit:10",
    },
    {
      display: "Client ID 발급",
      value: "auth:10",
    },
    {
      display: "Secret ID 발급",
      value: "auth:11",
    },
    {
      display: "Access Token 발급",
      value: "auth:12",
    },
    {
      display: "Access Token 검증",
      value: "auth:13",
    },
    {
      display: "Refresh token 폐기",
      value: "auth:14",
    },
    {
      display: "인증 사용자 추가",
      value: "auth:15",
    },
    {
      display: "PHI Code 생성",
      value: "auth:20",
    },
    {
      display: "PHI Code 활성화",
      value: "auth:21",
    },
    {
      display: "환자 정보를 이용한 PHI code 조회",
      value: "auth:22",
    },
    {
      display: "PIN Code 재발급",
      value: "auth:23",
    },
    {
      display: "PHI Code 요청",
      value: "auth:24",
    },
    {
      display: "DTx 처방 이력 조회(EMR)",
      value: "auth:25",
    },
    {
      display: "DTx 처방 이력 조회(DTx 기업)",
      value: "auth:26",
    },
    {
      display: "병원등록정보 조회",
      value: "emr:11",
    },
    {
      display: "처방전 전달",
      value: "emr:21",
    },
    {
      display: "처방 취소",
      value: "emr:22",
    },
    {
      display: "DTx 사용 결과 요청",
      value: "emr:31"
    },
    {
      display: "DTx 등록정보 조회",
      value: "dtx:11",
    },
    {
      display: "DTx 처방 정보 조회",
      value: "dtx:21",
    },
    {
      display: "DTx 사용 결과 정보 전달",
      value: "dtx:31",
    },
  ],
};
