const CheckBox = ({ id, disabled, checked, onChange }) => {
  return (
    <>
      <input
        id={id}
        type="checkbox"
        className="check_btn"
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id}></label>
    </>
  );
};
export default CheckBox;
