import React from "react";

export const Card = ({ cardListArray }) => {
  return (
    <div className="state_card_wrap">
      {cardListArray.map((cardList) => (
        <div key={cardList.name} className="state_card">
          <div className="card_info">
            <div className="card_name">{cardList.name}</div>
            <div className="count">{cardList.count}</div>
            <span className="txt_sub">{cardList.sub}</span>
          </div>
          <div className="card_icon"></div>
        </div>
      ))}
    </div>
  );
};

export default Card;
