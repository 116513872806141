import React from "react";
import Input from "../../atoms/Input/Input";
import Postcode from "../../organisms/Postcode/Postcode";

export const CreateOrgInModal = ({
                                     organizationData,
                                     onchangeOrganizationData,
                                     onChangeBlur,
                                     hospTypeEnum,
                                     reportList,
                                     checkedList,
                                     setCheckedList,
                                     agreeList,
                                     agreeCheckList,
                                     setAgreeCheckList,
                                     checkValue,
                                     postalCode,
                                     detailAddress,
                                     setDetailAddress,
                                     setPostalCode,
                                     roadAddress,
                                     setRoadAddress,
                                     registryInst,
                                     openResetOauth,
                                     closeModal,
                                     modalType,
                                     detailModalType,
                                     errorMessage,
                                 }) => {
    const updateSet = (set, id) => {
        const updatedSet = new Set(set);
        if (updatedSet.has(id)) {
            updatedSet.delete(id);
        } else {
            updatedSet.add(id);
        }

        return updatedSet;
    };
    const handleOnChange = (index, id, name) => {
       if(name === "agree"){
           setAgreeCheckList((prevSet) => updateSet(prevSet, id));
       }else{
           setCheckedList((prevSet) => updateSet(prevSet, id));
       }


    };
    return modalType === "registryCorp" ? (
        <>
            <ul className="form_wrap">
                {detailModalType === "modify" || detailModalType === "platform" ? (
                    <li>
                        <label className="name">기관 아이디</label>
                        <Input
                            type="text"
                            name="org_id"
                            value={organizationData.org_id || ""}
                            disabled
                            required
                            errorMessage={errorMessage}
                        />
                    </li>
                ) : (
                    <></>
                )}
                <li>
                    <label className="name">기업명</label>
                    <Input
                        type="text"
                        name="org_name"
                        value={organizationData.org_name}
                        onChange={onchangeOrganizationData}
                        required
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name">사업자등록번호</label>
                    <Input
                        type="text"
                        name="biz_no"
                        value={organizationData.biz_no || ""}
                        onChange={onchangeOrganizationData}
                        onBlur={onChangeBlur}
                        disabled={
                            detailModalType === "modify" || detailModalType === "platform"
                                ? true
                                : false
                        }
                        required
                        placeholder="ex) 000-00-0000"
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name">대표자</label>
                    <Input
                        type="text"
                        name="president_name"
                        value={organizationData.president_name}
                        onChange={onchangeOrganizationData}
                        required
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name">주소</label>
                    <Postcode
                        postalCode={postalCode}
                        setPostalCode={setPostalCode}
                        roadAddress={roadAddress}
                        setRoadAddress={setRoadAddress}
                        detailAddress={detailAddress}
                        setDetailAddress={setDetailAddress}
                        errorMessage={errorMessage}
                    ></Postcode>
                </li>
                <li>
                    <label className="name">전화번호</label>
                    <Input
                        type="tel"
                        name="tel_no"
                        value={organizationData.tel_no}
                        onChange={onchangeOrganizationData}
                        required
                        placeholder={"ex) 000-0000-0000"}
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name">입금은행명</label>
                    <Input
                        type="text"
                        name="deposit_bank_name"
                        value={organizationData.deposit_bank_name}
                        onChange={onchangeOrganizationData}
                        required
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name">입금계좌</label>
                    <Input
                        type="text"
                        name="deposit_bank_account"
                        value={organizationData.deposit_bank_account}
                        onChange={onchangeOrganizationData}
                        required
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name">세금계산서발행 이메일</label>
                    <Input
                        type="email"
                        name="tax_bill_email"
                        value={organizationData.tax_bill_email}
                        onChange={onchangeOrganizationData}
                        required
                        errorMessage={errorMessage}
                    />
                </li>
                {detailModalType === "modify" ? (
                    <>
                        <li>
                            <label className="name">oAuth2 client_id</label>
                            <Input
                                type="text"
                                name="oauth_client_id"
                                value={organizationData.oauth_client_id}
                                onChange={onchangeOrganizationData}
                                disabled
                            />
                        </li>
                        <li>
                            <label className="name">oAuth2 client_secret</label>
                            <Input
                                type="text"
                                className="w490"
                                name="oauth_client_secret"
                                value={organizationData.oauth_client_secret}
                                onChange={onchangeOrganizationData}
                                disabled
                            />
                            <label className="btn btn_gray ml10" onClick={openResetOauth}>
                                초기화
                            </label>
                        </li>
                    </>
                ) : (
                    <></>
                )}
            </ul>
            <div className="btn_wrap">
                <div className="left"></div>
                <div className="center"></div>
                <div className="right">
                    <button className="btn btn_gray_line" onClick={closeModal}>
                        취소
                    </button>
                    <button className="btn btn_black" onClick={registryInst}>
                        저장
                    </button>
                </div>
            </div>
        </>
    ) : modalType === "registryHosp" ? (
        <div>
            <ul className="form_wrap">
                {detailModalType === "modify" ? (
                    <li>
                        <label className="name">기관 아이디</label>
                        <Input
                            type="text"
                            name="org_id"
                            value={organizationData.org_id || ""}
                            disabled
                            errorMessage={errorMessage}
                        />
                    </li>
                ) : (
                    <></>
                )}
                <li>
                    <label className="name">기관명</label>
                    <Input
                        type="text"
                        name="org_name"
                        value={organizationData.org_name}
                        onChange={onchangeOrganizationData}
                        required
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name">사업자등록번호</label>
                    <Input
                        type="text"
                        name="biz_no"
                        value={organizationData.biz_no || ""}
                        onChange={onchangeOrganizationData}
                        onBlur={onChangeBlur}
                        disabled={
                            detailModalType === "modify" || detailModalType === "platform"
                                ? true
                                : false
                        }
                        required
                        placeholder="ex) 000-00-0000"
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name">기관장명</label>
                    <Input
                        type="text"
                        name="president_name"
                        value={organizationData.president_name}
                        onChange={onchangeOrganizationData}
                        required
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name">기관구분</label>
                    <select
                        id="class_code"
                        name="class_code"
                        required="required"
                        value={organizationData.class_code}
                        onChange={onchangeOrganizationData}
                    >
                        {Object.entries(hospTypeEnum).map((org) => {
                            return (
                                <option key={org[0]} value={org[1]}>
                                    {org[0]}
                                </option>
                            );
                        })}
                    </select>
                </li>
                <li>
                    <label className="name">주소</label>
                    <Postcode
                        postalCode={postalCode}
                        setPostalCode={setPostalCode}
                        roadAddress={roadAddress}
                        setRoadAddress={setRoadAddress}
                        detailAddress={detailAddress}
                        setDetailAddress={setDetailAddress}
                        errorMessage={errorMessage}
                    ></Postcode>
                </li>
                <li>
                    <label className="name">전화번호</label>
                    <Input
                        type="tel"
                        name="tel_no"
                        value={organizationData.tel_no}
                        onChange={onchangeOrganizationData}
                        required
                        placeholder="ex) 000-0000-0000"
                        errorMessage={errorMessage}
                    />
                </li>
                {detailModalType !== "platform" ? (
                    <>
                        <li>
                            <label className="name">OID</label>
                            <Input
                                type="text"
                                name="oid"
                                value={organizationData.oid}
                                onChange={onchangeOrganizationData}
                                onBlur={onChangeBlur}
                                disabled={
                                    detailModalType === "modify" || detailModalType === "platform"
                                        ? true
                                        : false
                                }
                                required
                                errorMessage={errorMessage}
                            />
                        </li>
                        <li>
                            <label className="name">CMS고객번호</label>
                            <Input
                                type="text"
                                name="cms_customer_no"
                                value={organizationData.cms_customer_no}
                                onChange={onchangeOrganizationData}
                                required
                                errorMessage={errorMessage}
                            />
                        </li>
                    </>
                ) : (
                    <></>
                )}

                <li>
                    <label className="name">이메일</label>
                    <Input
                        type="email"
                        name="tax_bill_email"
                        value={organizationData.tax_bill_email}
                        onChange={onchangeOrganizationData}
                        required
                        placeholder="세금계산서 발행용 이메일"
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name">정보 제공 항목</label>
                    <ul className={"check_box_ul"}>
                            {agreeList.map((item,idx)=>(
                                <li key={idx}>
                                    <label htmlFor={item.display}>{item.display}<span>{item.desc? item.desc : ""}</span></label>
                                    <input
                                        id={item.id}
                                        type="checkbox"
                                        checked={agreeCheckList.has(item.id)}
                                        onChange={() => handleOnChange(idx,item.id, "agree")}
                                    />
                                </li>
                            ))}
                    </ul>
                </li>
                <li>
                    <label className="name">지원 검사 결과 레포트 형식</label>
                    <ul className={"result_ul"}>
                        {reportList.map((item,idx)=>(
                            <li key={idx}>
                                <label htmlFor={item}>{item}</label>
                                <input
                                    id={item}
                                    type="checkbox"
                                    checked={checkedList.has(item)}
                                    onChange={() => handleOnChange(idx,item,"result")}
                                />
                            </li>
                        ))}
                    </ul>
                    {errorMessage.length !== 0 && errorMessage[0] === "result"?
                        <p className="warning"
                           style={{marginLeft:"235px"}}
                        >결과 레포트 형식을 선택해주세요.</p> : "" }
                </li>
                {detailModalType === "modify" ? (
                    <>
                        <li>
                            <label className="name">oAuth2 client_id</label>
                            <Input
                                type="text"
                                name="oauth_client_id"
                                value={organizationData.oauth_client_id}
                                disabled
                            />
                        </li>
                        <li>
                            <label className="name">oAuth2 client_secret</label>
                            <Input
                                type="text"
                                className="w490"
                                name="oauth_client_secret"
                                value={organizationData.oauth_client_secret}
                                disabled
                            />
                            <label className="btn btn_gray ml10" onClick={openResetOauth}>
                                초기화
                            </label>
                        </li>
                    </>
                ) : (
                    <></>
                )}
            </ul>
            <div className="btn_wrap">
                <div className="left"></div>
                <div className="center"></div>
                <div className="right">
                    <button className="btn btn_gray_line" onClick={closeModal}>
                        취소
                    </button>
                    <button className="btn btn_black" onClick={registryInst}>
                        저장
                    </button>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default CreateOrgInModal;
