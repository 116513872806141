import { useEffect, useState } from "react";
import Input from "../../atoms/Input/Input";
import Table from "../../organisms/Table/Table";

export const CreateDTxPriceInModal = ({
  dtxData,
  commonRows,
  commonColumns,
  detailPriceRows,
  detailPriceColums,
  detailLocalCodePriceColums,
  closeModal,
  modifyPrices,
}) => {
  const [ediRows, _setEdiRows] = useState([]);
  const [localCodeRows, _setLocalCodeRows] = useState([]);

  useEffect(() => {
    const updatedEdiRows = detailPriceRows.filter(
      (data) => data.edi_code !== undefined
    );
    const updatedLocalCodeRows = detailPriceRows.filter(
      (data) => data.edi_code === undefined
    );
    _setEdiRows(updatedEdiRows);
    _setLocalCodeRows(updatedLocalCodeRows);
  }, [detailPriceRows]);

  return (
    <>
      <div className="wrapper">
        <ul className="form_wrap mb50">
          {Object.keys(dtxData).length !== 0 && (
            <>
              <li>
                <label className="name">기관 ID</label>
                <Input
                  type="text"
                  name="manage_org_id"
                  value={dtxData.manage_org_id}
                  disabled
                />
              </li>
              <li>
                <label className="name">DTx 코드</label>
                <Input
                  type="text"
                  name="dtx_code"
                  value={dtxData.dtx_code}
                  disabled
                />
              </li>
              <li>
                <label className="name">기관명</label>
                <Input
                  type="text"
                  name="org_name"
                  value={dtxData.org_name}
                  disabled
                />
              </li>
              <li>
                <label className="name">Dtx명</label>
                <Input
                  type="text"
                  name="dtx_name"
                  value={dtxData.dtx_name}
                  disabled
                />
              </li>
            </>
          )}
        </ul>
        <div className="left mt30">
          <b>기본가격</b>
          <i className="txt_sub">
            (※ 가격을 설정하지 않을 경우 기본적으로 적용되는 가격)
          </i>
        </div>
        <Table
          rows={commonRows}
          columns={commonColumns}
          hideHeader={true}
          getTotalRows={0}
        ></Table>

        <div style={{ marginTop: "20px" }} className="left">
          <b>EDI코드 단가 설정</b>
        </div>
        <Table
          rows={ediRows}
          columns={detailPriceColums}
          hideHeader={true}
          getTotalRows={0}
        ></Table>

        <div style={{ marginTop: "20px" }} className="left">
          <b>로컬코드 단가 설정</b>
        </div>
        <Table
          rows={localCodeRows}
          columns={detailLocalCodePriceColums}
          hideHeader={true}
          getTotalRows={0}
        ></Table>
        <div className="btn_wrap">
          <div className="left"></div>
          <div className="center"></div>
          <div className="right">
            <button className="btn btn_gray_line" onClick={closeModal}>
              취소
            </button>
            <button className="btn btn_black" onClick={modifyPrices}>
              저장
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateDTxPriceInModal;
