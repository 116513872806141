import React, { Fragment, useEffect, useState } from "react";
import Pagenation from "../../molecules/Pagenation";
import CheckBox from "./CheckBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CloseIcon from "@mui/icons-material/Close";

export const Table = ({
  columns, // th 내용
  rows, //td 내용
  spanColumns, // 컬럼 위 상위 컬럼
  getTotalRows, //테이블 전체 목록 몇개인지?
  search, // 검색조회(필터나..그런거들어감)
  onChangeSearchValue, // 검색조회 검색창
  onClickSearchButton, // 검색조회에서 조회버튼
  button, // 모달용 버튼들
  button2, // 모달용 버튼들
  checkbox, // 체크박스를 활성화 하겠다. + 어떤 컬럼을 기준으로 잡겠다.(string: column_name)
  checkMode, // 한번에 여러개의 체크박스를 활성화 할수 있음(string : multiple)
  checkedIdsSet, // 체크된 항목의 ID 리스트 (new Set())
  setCheckedIdsSet, // 체크된 항목의 ID 리스트를 바꾸기 위한 변수
  // 페이지네이션용
  page,
  limit,
  totalPages,
  changePage,
  // 정렬 용
  sortParams,
  setSortParams,
  hideHeader = false,
  hidePagenation = false,
  overflow = false,
}) => {
  const [checkedAll, setCheckedAll] = useState(false);
  const handleOnChange = (index) => {
    if (checkMode === "multiple") {
      setCheckedIdsSet((prevSet) => updateSet(prevSet, rows[index][checkbox]));
    } else {
      setCheckedIdsSet(() => updateSet([], rows[index][checkbox]));
    }
  };

  const toggleAllCheckedById = ({ target: { checked } }) => {
    const allChecked = new Set(rows.map((id) => id[checkbox]));
    if (checked) {
      setCheckedIdsSet(setPlus(checkedIdsSet, allChecked));
    } else {
      setCheckedIdsSet((checkedIdsSet) => setMinus(checkedIdsSet, allChecked));
    }
    setCheckedAll(checked);
  };

  const updateSet = (set, id) => {
    const updatedSet = new Set(set);

    if (updatedSet.has(id)) {
      updatedSet.delete(id);
    } else {
      updatedSet.add(id);
    }

    return updatedSet;
  };

  const setMinus = (setA, setB) => {
    const difference = new Set(setA);
    for (const item of setB) {
      difference.delete(item);
    }
    return difference;
  };

  const setPlus = (setA, setB) => {
    const difference = new Set(setA);
    for (const item of setB) {
      difference.add(item);
    }
    return difference;
  };

  const [sortColumn, setSortColumn] = useState(
    sortParams && sortParams.split(",")[0]
  );
  const [sortOrder, setSortOrder] = useState(
    sortParams && sortParams.split(",")[1]
  );

  const sortColumns = (colName) => {
    let orderVal = "";
    if (sortColumn === colName) {
      orderVal = sortOrder === "asc" ? "desc" : "asc";
    } else {
      orderVal = "asc";
    }

    setSortColumn(colName);
    setSortOrder(orderVal);
    setSortParams(`${colName},${orderVal}`);
  };

  useEffect(() => {
    setCheckedAll(false);
  }, [rows]);

  return (
    <Fragment>
      {search && search.length > 0 && (
        <div className="search_box">
          {search.map((data) => {
            {
              /* 일반 검색창 */
            }
            if (data.type === "text") {
              return (
                <div key={data.name} className="search_wrap">
                  {/* <label>{data.label}</label> */}
                  <input
                    type="text"
                    name={data.name}
                    placeholder={data.placeholder}
                    value={data.value}
                    onKeyDown={(event) => {
                      onChangeSearchValue(event);
                    }}
                    onChange={(event) => {
                      onChangeSearchValue(event);
                    }}
                    className="input_search"
                  />
                </div>
              );
            } else if (data.type === "date") {
              {
                /* 날짜 검색 */
              }
              return (
                <div key={data.name} className="search_wrap">
                  <label
                    style={{
                      position: "absolute",
                      marginLeft: "10px",
                      bottom: "30px",
                      color: "#bab5b5",
                      backgroundColor: "#fff",
                    }}
                    htmlFor={data.name}
                  >
                    {data.label}
                  </label>
                  <input
                    type="date"
                    id={data.name}
                    name={data.name}
                    placeholder={data.placeholder}
                    value={data.value}
                    onChange={(event) => {
                      onChangeSearchValue(event);
                    }}
                    className="input_date"
                    min={data.min}
                    max={data.max}
                  />
                </div>
              );
            } else if (data.type === "select") {
              {
                /* 셀렉트창 검색 */
              }
              return (
                <div key={data.name} className="search_wrap">
                  {/* <label>{data.label}</label> */}
                  <select
                    name={data.name}
                    value={data.value}
                    onChange={(event) => {
                      onChangeSearchValue(event);
                    }}
                  >
                    {Object.entries(data.filter).map((item) => {
                      return (
                        <option key={item[0]} value={item[1]}>
                          {item[0]}
                        </option>
                      );
                    })}
                  </select>
                </div>
              );
            } else if (data.type === "labelButton") {
              return (
                <div key={data.name} className="search_wrap">
                  <input
                    type="text"
                    name={data.name}
                    value={data.display}
                    onChange={() => {}}
                    className="input_search"
                    style={{
                      backgroundColor: "#FAFAFA",
                      color: "#666670",
                      cursor: "pointer",
                    }}
                    onClick={data.onClick}
                  />
                  {data.value === "" || data.value === "*" ? (
                    <button
                      className="btn_search"
                      onClick={data.onClick}
                    ></button>
                  ) : (
                    <CloseIcon
                      className="btn_init"
                      onClick={data.onClickRemove}
                    ></CloseIcon>
                  )}
                </div>
              );
            } else if (data.type === "autoCompleteSelect") {
              {
                /* 자동완성 셀렉트창 검색 */
              }
              // 리스트 수 자동으로 만들기
              const options = [];
              for (let i = 0; i < data.value.length; i++) {
                options.push(<option key={i} value={data.value[i]} />);
              }
              return (
                <div key={data.name} className="search_wrap">
                  <input
                    type="text"
                    placeholder={data.placeholder}
                    list={data.name}
                  />
                  <datalist id={data.name}>{options}</datalist>
                </div>
              );
            }
            return null;
          })}
          <button className="btn btn_gray_line" onClick={onClickSearchButton}>
            검색
          </button>
        </div>
      )}

      {/* 전체 목록 수, 등록 모달 버튼 등 */}
      {hideHeader ? (
        <></>
      ) : (
        <div className="btn_wrap">
          <div className="left">전체 목록 : {getTotalRows}개</div>
          <div className="center"></div>
          <div className="right">
            {button && (
              <label className="btn btn_black ml5" onClick={button.onClick}>
                {button.name}
              </label>
            )}
            {button2 && (
              <label className="btn btn_black ml5" onClick={button2.onClick}>
                {button2.name}
              </label>
            )}
          </div>
        </div>
      )}

      {/* 테이블 */}
      <div style={overflow ? { maxHeight: "384px", overflow: "auto" } : {}}>
        <table
          style={overflow ? { margin: "0px" } : {}}
          className="table_basic mt30"
        >
          <colgroup>
            {spanColumns &&
              spanColumns.map((column, index) => {
                return <col key={index} width={column.width}></col>;
              })}
            {checkbox !== undefined && <col key={-1} width="5%"></col>}
            {columns &&
              columns.map((column, index) => {
                return <col key={index} width={column.width}></col>;
              })}
          </colgroup>
          <thead>
            <tr>
              {spanColumns &&
                spanColumns.map((column, index) => {
                  // map 함수 이거는? 최대 2개만 쓸수있음
                  return (
                    <th colSpan={column.colSpan} key={index}>
                      {column.header}
                    </th>
                  );
                })}
            </tr>
            <tr>
              {checkbox === undefined ? (
                <></>
              ) : checkMode === "multiple" ? (
                <th>
                  <CheckBox
                    id="all"
                    checked={checkedAll}
                    onChange={toggleAllCheckedById}
                  ></CheckBox>
                </th>
              ) : (
                <th></th>
              )}
              {columns &&
                columns.map((column, index) => {
                  if (column.customHeader !== undefined) {
                    return column.customHeader(column);
                  } else {
                    return (
                      <th
                        style={
                          column.sort !== false ? { cursor: "pointer" } : null
                        }
                        key={column.name}
                        onClick={
                          column.sort !== false
                            ? () => sortColumns(column.name)
                            : null
                        }
                      >
                        {column.sort === false ? (
                          column.header
                        ) : sortColumn === column.name &&
                          sortOrder === "asc" ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div>{column.header}</div>
                            <ExpandLessIcon></ExpandLessIcon>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div>{column.header}</div>

                            <ExpandMoreIcon></ExpandMoreIcon>
                          </div>
                        )}
                      </th>
                    );
                  }
                })}
            </tr>
          </thead>
          <tbody>
            {rows && rows.length > 0 ? (
              rows.map((row, index) => {
                return (
                  <tr key={index}>
                    {checkbox !== undefined && (
                      <td>
                        <CheckBox
                          id={row[checkbox]}
                          checked={checkedIdsSet.has(rows[index][checkbox])}
                          onChange={() => handleOnChange(index)}
                        />
                      </td>
                    )}
                    {columns.map((column, index2) => {
                      if (column.rowSpan !== undefined) {
                        if (index === 0) {
                          return column.customBody(row);
                        }
                        if (index % column.rowSpan === 0) {
                          return column.customBody(row);
                        }
                      } else if (column.customBody !== undefined) {
                        return column.customBody(row);
                      } else {
                        return (
                          <td key={index2}>
                            <span>{row[column.name]}</span>
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={checkbox !== undefined ? columns.length+1: columns.length}>데이터 없음</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!hidePagenation && getTotalRows !== 0 ? (
        <Pagenation
          page={page}
          limit={limit}
          totalPages={totalPages}
          changePage={changePage}
        />
      ) : null}
    </Fragment>
  );
};

export default Table;
