import React, { useEffect, useState } from "react";

const Pagenation = ({
  page,
  limit = 10,
  totalPages,
  changePage,
  btmButton, //정산요청 등 체크박스 체크 후에 누르는 버튼
}) => {
  const [currentPageArray, setCurrentPageArray] = useState([]);
  const [totalPageArray, setTotalPageArray] = useState([]);

  useEffect(() => {
    if (page === totalPages) {
      setCurrentPageArray(totalPageArray[Math.floor(page / limit)]);
    }
    if (page % limit === 1) {
      setCurrentPageArray(totalPageArray[Math.floor(page / limit)]);
    } else if (page % limit === 0) {
      setCurrentPageArray(totalPageArray[Math.floor(page / limit) - 1]);
    }
  }, [page, limit, totalPageArray]);

  useEffect(() => {
    const slicedPageArray = sliceArrayByLimit(totalPages, limit);
    setTotalPageArray(slicedPageArray);
    setCurrentPageArray(slicedPageArray[0]);
  }, [totalPages, limit]);

  return (
    <div className="btn_wrap">
      <div className="left"></div>
      <div className="center">
        <ul className="paging">
          <li className="bg_none" onClick={() => changePage(1)}>
            <i className="first"></i>
          </li>
          <li
            className="bg_none"
            onClick={() => changePage(page === 1 ? 1 : page - 1)}
          >
            <i className="pre"></i>
          </li>
          {currentPageArray?.map((i) => {
            return (
              <li
                key={i + 1}
                className={`btn_pg ${i === page - 1 ? "clicked" : ""}`}
                onClick={() => changePage(i + 1)}
              >
                {i + 1}
              </li>
            );
          })}
          <li
            className="bg_none"
            onClick={() =>
              changePage(page === totalPages ? totalPages : page + 1)
            }
          >
            <i className="next"></i>
          </li>
          <li className="bg_none" onClick={() => changePage(totalPages)}>
            <i className="end"></i>
          </li>
        </ul>
      </div>
      <div className="right">
        {btmButton && (
          <label className="btn btn_black" onClick={btmButton.onClick}>
            {btmButton.name}
          </label>
        )}
      </div>
    </div>
  );
};

export default Pagenation;

const sliceArrayByLimit = (totalPages, limit) => {
  const totalPageArray = Array.from({ length: totalPages }, (_, i) => i);
  return Array.from({ length: Math.ceil(totalPages / limit) }, () =>
    totalPageArray.splice(0, limit)
  );
};
