import React, { useEffect, useState } from "react";
import request from "../config/Axios";
import History from "../components/organisms/History/History";
import { Table } from "../components/organisms/Table/Table";
import Modal from "../components/organisms/Modal/Modal";
import moment from "moment";
import {
  ButtonPlaceHolder,
  DefaultModalPageSize,
  DefaultPageSize,
  DefaultSearchDay,
  DefaultSortParams,
  MessageCollection,
  OrgModalName,
  copyToClipboard,
} from "../components/Default/DefaultValue";
import { AlertInfoState } from "..";
import { useRecoilState } from "recoil";
import SelectOrgModal from "../components/templates/SelectOrgModal/SelectOrgModal";

const InquirePrcpView = () => {
  const [refresh, setRefresh] = useState(false); // 검색에서 페이지 새로고침 되니까 있는게 좋음
  const [modalRefresh, setModalRefresh] = useState(false);
  const [message, setMessage] = useRecoilState(AlertInfoState);

  // 제목, 브레드크럼
  const title = "처방&진료 정보";
  const breadcrumb = [
    { name: "HOME", link: "./" },
    { name: "데이터 관리" },
    { name: "처방&진료 정보", link: "./InquirePrcpView" },
  ];

  const pageSize = DefaultPageSize;

  // 모달
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal((openModal) => !openModal);
  };

  // 테이블 관련 변수
  const [totalElements, setTotalElements] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const [sortParams, setSortParams] = useState(DefaultSortParams.PRCP);

  // 모달 테이블 관련 변수
  const [modalTotalElements, setModalTotalElements] = useState(0);
  const [modalPage, setModalPage] = useState(1);
  const [modalTotalPages, setModalTotalPages] = useState(5);
  const [modalSortParams, setModalSortParams] = useState(
    DefaultSortParams.DEFAULT
  );

  // 검색조회부분
  const [tableSearchItems, setTableSearchItems] = useState([]);
  // 서브 모달 검색 조회
  const [modalTableSearchItems, setModalTableSearchItems] = useState([
    {
      name: ["orgName"],
      placeholder: ["기관명"],
      value: "",
      type: "text",
    },
  ]);

  // td 내용
  const [rows, _setRows] = useState([]);
  const [orgRows, _setOrgRows] = useState([]);

  // header,body 변경 th 내용
  const columns = [
    {
      name: "hosp_name",
      header: "의료기관",
      width: "12%",
    },

    {
      name: "dtx_name",
      header: "DTx명",
      width: "17%",
    },
    {
      name: "turn_no",
      header: "사용/총 회차",
      width: "11%",
      customBody: (row) => (
        <td key="turn_no">{`${row.turn_no} / ${row.turn_total}`}</td>
      ),
      sort: false,
    },
    {
      name: "hosp_prcp_seq",
      header: "처방번호",
      width: "14%",
      customBody: (row) => (
        <td key="hosp_prcp_seq">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="ellipsis">{row.hosp_prcp_seq}</div>
            <div
              className="attachIcon"
              style={{ width: "18px" }}
              onClick={() => {
                copyToClipboard(row.hosp_prcp_seq);
                setMessage({ message: MessageCollection.COPY });
              }}
            ></div>
          </div>
        </td>
      ),
    },
    {
      name: "phi_code",
      header: "PHI코드",
      width: "16%",
      customBody: (row) => (
        <td key="phi_code">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="ellipsis">{row.phi_code}</div>
            <div
              className="attachIcon"
              style={{ width: "18px" }}
              onClick={() => {
                copyToClipboard(row.phi_code);
                setMessage({ message: MessageCollection.COPY });
              }}
            ></div>
          </div>
        </td>
      ),
    },
    {
      name: "prcp_dt",
      header: "처방일",
      width: "15%",
    },
    {
      name: "prcp_recv_dt",
      header: "수신일",
      width: "15%",
    },
    {
      name: "status",
      header: "상태",
      width: "15%",
      customBody: (row) => (
          <td key="status">
            <div>{row.status.desc}</div>
          </td>
      ),
    },
  ];

  // 입력창값이 변경될때
  const onChangeSearchValue = (event) => {
    if (event.key === "Enter") {
      onClickSearchButton();
    }
    const { name, value } = event.target;
    setTableSearchItems((prevState) =>
      prevState.map((item) => {
        if (item.name.join(",") === name) {
          return { ...item, value };
        }
        return item;
      })
    );
    if (name === "startDate") {
      setTableSearchItems((prevState) =>
        prevState.map((item) => {
          if (item.name.join(",") === "endDate") {
            return {
              ...item,
              value:
                item.value > moment(value).add(30, "day").format("YYYY-MM-DD")
                  ? moment(value).add(30, "day").format("YYYY-MM-DD")
                  : item.value < value
                  ? value
                  : item.value,
            };
          }
          return item;
        })
      );
    }
    if (name === "endDate") {
      setTableSearchItems((prevState) =>
        prevState.map((item) => {
          if (item.name.join(",") === "startDate") {
            return {
              ...item,
              value:
                item.value <
                moment(value).subtract(30, "day").format("YYYY-MM-DD")
                  ? moment(value).subtract(30, "day").format("YYYY-MM-DD")
                  : item.value > value
                  ? value
                  : item.value,
            };
          }
          return item;
        })
      );
    }
  };

  const selectOrg = (row) => {
    setTableSearchItems((prevState) =>
      prevState.map((item) => {
        if (item.name.join(",") === "hospFilter") {
          return { ...item, value: row.org_id, display: row.org_name };
        }
        return item;
      })
    );
    closeModal();
  };

  // 검색 영역에서 검색버튼 눌렀을때 뜨는 알림창
  const onClickSearchButton = () => {
    setPage(1);
    setRefresh((prev) => !prev);
  };

  const onChangeModalSearchValue = (event) => {
    if (event.key === "Enter") {
      onClickModalSearchButton();
    }
    const { name, value } = event.target;
    setModalTableSearchItems((prevState) =>
      prevState.map((item) => {
        if (item.name.join(",") === name) {
          return { ...item, value };
        }
        return item;
      })
    );
  };

  const onClickModalSearchButton = () => {
    setModalPage(1);
    setModalRefresh((prev) => !prev);
  };

  const onClickOrgFilter = () => {
    setModalPage(1);
    setModalTableSearchItems((prevState) =>
      prevState.map((item) => {
        if (item.name.join(",") === "orgName") {
          return { ...item, value: "" };
        }
        return item;
      })
    );
    setOpenModal(true);
  };

  const onClickOrgFilterForRemove = () => {
    setTableSearchItems((prevState) =>
      prevState.map((item) => {
        if (item.name.join(",") === "hospFilter") {
          return { ...item, value: "*", display: ButtonPlaceHolder.HOSP };
        }
        return item;
      })
    );
  };

  useEffect(() => {
    (async () => {
      let searchItems = [
        {
          name: ["startDate"],
          value: moment(new Date())
            .subtract(DefaultSearchDay, "day")
            .format("YYYY-MM-DD"),
          type: "date",
          label: "수신일",
        },
        {
          name: ["endDate"],
          value: moment(new Date()).format("YYYY-MM-DD"),
          type: "date",
        },
        {
          name: ["hospFilter"],
          type: "labelButton",
          onClick: onClickOrgFilter,
          onClickRemove: onClickOrgFilterForRemove,
          display: ButtonPlaceHolder.HOSP,
          value: "*",
        },
        {
          name: ["hosp_prcp_seq", "phiCode", "dtxName"],
          placeholder: ["DTx명, 처방번호, PHI코드"],
          value: "",
          type: "text",
        },
      ];

      setTableSearchItems(searchItems);
      setRefresh((prev) => !prev);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const organsResponse = await request.get("organs", {
        params: {
          page: modalPage - 1,
          size: DefaultModalPageSize,
          sort: modalSortParams,
          name: modalTableSearchItems[0].value.trim(),
          type: "HOSP",
        },
      });
      _setOrgRows(organsResponse.data.data.content);
      setModalTotalPages(organsResponse.data.data.totalPages);
      setModalTotalElements(organsResponse.data.data.totalElements);
    })();
  }, [openModal, modalPage, modalSortParams, modalRefresh]);

  useEffect(() => {
    if (tableSearchItems.length > 0) {
      (async () => {
        //유효하지 않은 날짜 체크
        if(!moment(tableSearchItems[0].value).isValid() || !moment(tableSearchItems[1].value).isValid()){
          return;
        }
        const codesData = await request.get(`use/prcps`, {
          params: {
            page: page - 1,
            size: pageSize,
            sort: sortParams,
            start_date: moment(tableSearchItems[0].value).format("YYYYMMDD"),
            end_date: moment(tableSearchItems[1].value).format("YYYYMMDD"),
            hosp_id: tableSearchItems[2].value,
            search_word_like:
              tableSearchItems[3].value === ""
                ? "*"
                : tableSearchItems[3].value.trim(),
          },
        });
        _setRows(codesData.data.data.content);
        setTotalPages(codesData.data.data.totalPages);
        setTotalElements(codesData.data.data.totalElements);
      })();
    }
  }, [refresh, page, sortParams]);

  // 최종 화면 나오는 곳
  return (
    <>
      <div className="wrapper">
        {<History title={title} breadcrumb={breadcrumb} />}
        <hr />
        {
          <Table
            rows={rows}
            columns={columns}
            getTotalRows={totalElements}
            search={tableSearchItems}
            onChangeSearchValue={onChangeSearchValue}
            onClickSearchButton={onClickSearchButton}
            page={page}
            totalPages={totalPages}
            changePage={setPage}
            sortParams={sortParams}
            setSortParams={setSortParams}
          />
        }
      </div>
      {
        <Modal
          open={openModal}
          close={closeModal}
          title={OrgModalName.HOSP}
          content={
            <SelectOrgModal
              rows={orgRows}
              orgType={"HOSP"}
              selectOrg={selectOrg}
              getTotalRows={modalTotalElements}
              search={modalTableSearchItems}
              onChangeSearchValue={onChangeModalSearchValue}
              onClickSearchButton={onClickModalSearchButton}
              page={modalPage}
              limit={DefaultModalPageSize}
              totalPages={modalTotalPages}
              changePage={setModalPage}
              sortParams={modalSortParams}
              setSortParams={setModalSortParams}
            ></SelectOrgModal>
          }
        />
      }
    </>
  );
};

export default InquirePrcpView;
