import LayOuts from "./Pages/LayOuts.js";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// 페이지 목록
import ErrorPage from "./Pages/error-page.js";
import ManageOrganization from "./Pages/ManageOrganization.js"; // 사용자 정보
import ManageUser from "./Pages/ManageUser.js"; // 사용자 정보
import ManageDtxApp from "./Pages/ManageDtxApp.js"; // DTx앱 정보
import ManageHospLocalCode from "./Pages/ManageHospLocalCode.js"; // 병원 별 로컬코드
import InquirePrcpView from "./Pages/InquirePrcpView.js"; // 처방&진료 정보
import InquireDtxUsage from "./Pages/InquireDtxUsage.js"; // DTx사용 정보
import UserSelfAuth from "./Pages/UserSelfAuth.js"; // 본인 확인
import PersonalInfoAgreement from "./Pages/PersonalInfoAgreement.js"; // 정보제공 동의
import HospSettlement from "./Pages/HospSettlement.js"; // 의료기관
import DtxCorpSettlement from "./Pages/DtxCorpSettlement.js"; // DTx 기업
import OprLog from "./Pages/OprLog.js"; // 운영로그
import AuditLog from "./Pages/AuditLog.js"; // 감사로그
import SystemConfig from "./Pages/SystemConfig.js"; // 시스템 설정
import ManageDtxPrice from "./Pages/ManageDtxPrice.js"; // 병원 별 DTx 단가
import Mainpage from "./Pages/Mainpage.js"; // HOME
import Login from "./Pages/Login.js"; // 로그인

const Router = () => {
  return <RouterProvider router={router} />;
};

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/",
    element: <LayOuts />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Mainpage />,
      },
      {
        path: "ManageOrganization",
        element: <ManageOrganization />,
      },
      {
        path: "ManageUser",
        element: <ManageUser />,
      },
      {
        path: "ManageDtxApp",
        element: <ManageDtxApp />,
      },
      {
        path: "ManageHospLocalCode",
        element: <ManageHospLocalCode />,
      },
      {
        path: "ManageDtxPrice",
        element: <ManageDtxPrice />,
      },
      {
        path: "InquirePrcpView",
        element: <InquirePrcpView />,
      },
      {
        path: "InquireDtxUsage",
        element: <InquireDtxUsage />,
      },
      {
        path: "UserSelfAuth",
        element: <UserSelfAuth />,
      },
      {
        path: "PersonalInfoAgreement",

        element: <PersonalInfoAgreement />,
      },
      {
        path: "DtxCorpSettlement",
        element: <DtxCorpSettlement />,
      },
      {
        path: "HospSettlement",
        element: <HospSettlement />,
      },
      {
        path: "AuditLog",
        element: <AuditLog />,
      },
      {
        path: "OprLog",
        element: <OprLog />,
      },
      {
        path: "SystemConfig",
        element: <SystemConfig />,
      },
    ],
  },
]);

export default Router;
