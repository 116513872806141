import {useRecoilState} from "recoil";
import {AlertErrorState} from "../../../index";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CloseIcon from "@mui/icons-material/Close";
import {useEffect} from "react";
import request from "../../../config/Axios";


const Snackbar = () => {
    const [errorState, setErrorState] = useRecoilState(AlertErrorState);

    const handleClose = () => {
        if(errorState.response?.status === 401){
            window.location.href = "/Login";
        }
        setErrorState({error: ""});
    };

    return (
        <div
            style={{
                position: "fixed",
                top: 50,
                right: 50,
                background: "#cf2323",
                opacity: "70%",
                color: "white",
                zIndex: 99999,
                padding: "10px",
                minWidth: "100px",
                maxWidth: "400px",
                wordBreak: "break-all",
                borderRadius: "5px",
                display: errorState.response ? "flex" : "none",
            }}
        >
            <WarningAmberIcon style={{marginRight: "10px"}}></WarningAmberIcon>
            <div>
                <div>
                    Error ({errorState.response ? errorState.response.status : ""})
                </div>
                <div>
                    Code: {errorState.response ? errorState.response.data.code : ""}
                </div>
                <div>Message :</div>
                <div>{errorState.response ? errorState.response.data.msg : ""}</div>
            </div>
            <CloseIcon
                style={{width: "25px", height: "25px", cursor: "pointer"}}
                onClick={handleClose}
            ></CloseIcon>
        </div>
    );
};

export default Snackbar;
