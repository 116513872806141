import React, { useEffect, useState } from "react";
import request from "../config/Axios";
import History from "../components/organisms/History/History";
import { Table } from "../components/organisms/Table/Table";
import moment from "moment";
import {
  DefaultPageSize,
  DefaultSearchDay,
  DefaultSortParams,
  MessageCollection,
  copyToClipboard,
} from "../components/Default/DefaultValue";

import { AlertInfoState } from "..";
import { useRecoilState } from "recoil";

const UserSelfAuth = () => {
  const [refresh, setRefresh] = useState(false); // 검색에서 페이지 새로고침 되니까 있는게 좋음
  const [message, setMessage] = useRecoilState(AlertInfoState);

  // 제목, 브레드크럼
  const title = "본인 확인";
  const breadcrumb = [
    { name: "HOME", link: "./" },
    { name: "동의서 관리" },
    { name: "본인 확인", link: "./UserSelfAuth" },
  ];

  const pageSize = DefaultPageSize;

  // 테이블 관련 변수
  const [totalElements, setTotalElements] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const [sortParams, setSortParams] = useState(DefaultSortParams.DEFAULT);

  // td 내용
  const [rows, _setRows] = useState([]);

  // th 내용
  const columns = [
    {
      name: "birth_day",
      header: "생년월일",
      width: "10%",
      sort: false,
    },
    {
      name: "agree_dt",
      header: "본인확인일시",
      width: "15%",
    },
    {
      name: "phi_code",
      header: "PHI코드",
      width: "15%",
      customBody: (row) => (
        <td key="phi_code">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="ellipsis">{row.phi_code}</div>
            <div
              className="attachIcon"
              style={{ width: "18px" }}
              onClick={() => {
                copyToClipboard(row.phi_code);
                setMessage({ message: MessageCollection.COPY });
              }}
            ></div>
          </div>
        </td>
      ),
    },
    {
      name: "result_code",
      header: "응답코드",
      width: "10%",
    },
    {
      name: "result_msg",
      header: "본인확인결과",
      width: "35%",
    },
    {
      name: "corp_name",
      header: "제공동의기관",
      width: "15%",
    },
  ];

  // 검색조회부분
  const [tableSearchItems, setTableSearchItems] = useState([]);

  // 입력창값이 변경될때
  const onChangeSearchValue = (event) => {
    if (event.key === "Enter") {
      onClickSearchButton();
    }
    const { name, value } = event.target;
    setTableSearchItems((prevState) =>
      prevState.map((item) => {
        if (item.name.join(",") === name) {
          return { ...item, value };
        }
        return item;
      })
    );
    if (name === "startDate") {
      setTableSearchItems((prevState) =>
        prevState.map((item) => {
          if (item.name.join(",") === "endDate") {
            return {
              ...item,
              value:
                item.value > moment(value).add(30, "day").format("YYYY-MM-DD")
                  ? moment(value).add(30, "day").format("YYYY-MM-DD")
                  : item.value < value
                  ? value
                  : item.value,
            };
          }
          return item;
        })
      );
    }
    if (name === "endDate") {
      setTableSearchItems((prevState) =>
        prevState.map((item) => {
          if (item.name.join(",") === "startDate") {
            return {
              ...item,
              value:
                item.value <
                moment(value).subtract(30, "day").format("YYYY-MM-DD")
                  ? moment(value).subtract(30, "day").format("YYYY-MM-DD")
                  : item.value > value
                  ? value
                  : item.value,
            };
          }
          return item;
        })
      );
    }
  };

  // 검색 영역에서 검색버튼 눌렀을때 뜨는 알림창
  const onClickSearchButton = () => {
    setPage(1);
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    (async () => {
      let searchItems = [
        {
          name: ["startDate"],
          value: moment(new Date())
            .subtract(DefaultSearchDay, "day")
            .format("YYYY-MM-DD"),
          type: "date",
          label: "본인확인일시",
        },
        {
          name: ["endDate"],
          value: moment(new Date()).format("YYYY-MM-DD"),
          type: "date",
        },
        {
          name: ["name"],
          placeholder: ["환자 이름"],
          value: "",
          type: "text",
        },
        {
          name: ["phone"],
          placeholder: ["전화번호"],
          value: "",
          type: "text",
        },
      ];

      setTableSearchItems(searchItems);
      setRefresh((prev) => !prev);
    })();
  }, []);

  useEffect(() => {
    if (tableSearchItems.length > 0) {
      (async () => {
        //유효하지 않은 날짜 체크
        if(!moment(tableSearchItems[0].value).isValid() || !moment(tableSearchItems[1].value).isValid()){
          return;
        }
        const agreeSelfData = await request.get(`agrees/self`, {
          params: {
            page: page - 1,
            size: pageSize,
            sort: sortParams,
            start_date: moment(tableSearchItems[0].value).format("YYYYMMDD"),
            end_date: moment(tableSearchItems[1].value).format("YYYYMMDD"),
            name: tableSearchItems[2].value.trim(),
            phone: tableSearchItems[3].value.trim(),
          },
        });
        _setRows(agreeSelfData.data.data.content);
        setTotalPages(agreeSelfData.data.data.totalPages);
        setTotalElements(agreeSelfData.data.data.totalElements);
      })();
    }
  }, [refresh, page, sortParams]);

  // 최종 화면 나오는 곳
  return (
    <>
      <div className="wrapper">
        {<History title={title} breadcrumb={breadcrumb} />}
        <hr />
        {
          <Table
            rows={rows}
            columns={columns}
            getTotalRows={totalElements}
            search={tableSearchItems}
            onChangeSearchValue={onChangeSearchValue}
            onClickSearchButton={onClickSearchButton}
            page={page}
            totalPages={totalPages}
            changePage={setPage}
            sortParams={sortParams}
            setSortParams={setSortParams}
          />
        }
      </div>
    </>
  );
};

export default UserSelfAuth;
