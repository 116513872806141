import {useRecoilState, useSetRecoilState} from "recoil";
import request from "../config/Axios";
import {useEffect, useState} from "react";
import {AlertErrorState, AlertInfoState} from "../"
import {useMutex} from 'react-context-mutex';
import axios from "axios";
import {MessageColor} from "../components/Default/DefaultValue";

const AxiosHandler = () => {
    const setErrorState = useSetRecoilState(AlertErrorState);
    const [message, setMessage] = useRecoilState(AlertInfoState);
    const MutexRunner = useMutex();
    const mutex = new MutexRunner('myUniqueKey1');
    const errorHandler = (error) => {
        setErrorState({error});
    };

    const responseHandler = (response) => {
        return response;
    };


    const responseInterceptor = request.interceptors.response.use(
        async (response) => responseHandler(response),
        async (error) => {
            const {
                config,
                response: {status},
            } = error;

            if (error.response?.status === 401) {
                mutex.run(async () => {
                    mutex.lock();
                    try {
                        const response = await postRefreshToken(config);
                        mutex.unlock();
                    } catch (e) {
                        mutex.unlock();
                        sessionStorage.setItem("message", "중복로그인");
                        window.location.href = "/Login";
                    }
                });
            } else if (error.response?.status === 404) {
                window.location.href = "/Login";
            } else {
                errorHandler(error);
            }
        });

    //refresh token api
    const postRefreshToken = async (config) => {
        try {
            const response = await request.post('auth/renewal',
                {
                    uuid: localStorage.getItem('uuid'),
                },
            );
            localStorage.setItem('jwt', `Bearer ${response.data.data.jwt}`);
            localStorage.setItem('uuid', response.data.data.uuid);

            window.location.reload();
            config.headers.Authorization = localStorage.getItem("jwt");
            return request(config);
        } catch (error) {
            if (error.response.status !== 200) {
                window.location.href = "/Login";
            }

        }
    }

    const requestInterceptor = request.interceptors.request.use((config) => {
        const token = localStorage.getItem("jwt");
        if (token === null) {
            window.location.href = "/Login";
        } else {
            config.headers.Authorization = token;
        }
        return config;
    });

    useEffect(() => {
        return () => {
            request.interceptors.request.eject(requestInterceptor);
            request.interceptors.response.eject(responseInterceptor);
        };
    }, [responseInterceptor, requestInterceptor]);


};
export default AxiosHandler;
