import {useEffect, useRef, useState} from "react";
import requestForLogin from "../config/AxiosForLogin";
import Input from "../components/atoms/Input/Input";
import {useNavigate} from "react-router-dom";
import {useRecoilState} from "recoil";
import {AlertErrorState, AlertInfoState} from "..";
import {isValidPassword} from "../components/Default/DefaultValue";
import request from "../config/Axios";
import {MessageColor} from "../components/Default/DefaultValue";


const Login = () => {
    const savedUserConfig = JSON.parse(localStorage.getItem("userConInfo"));
    const savedCheckStatus = JSON.parse(localStorage.getItem("isSave"));

    const [mode, setMode] = useState("login");
    const [message, setMessage] = useRecoilState(AlertInfoState);
    const [alart, setAlert] = useRecoilState(AlertErrorState);


    const [userData, setUserData] = useState({
        username: savedUserConfig === null ? "" : savedUserConfig.username,
        password: savedUserConfig === null ? "" : savedUserConfig.password,
        checkPassword: "",
    });
    const [errorMessage, setErrorMessage] = useState([]);

    const [isCheckedSaveInfo, setIsCheckedSaveInfo] = useState(
        savedCheckStatus === null ? false : savedCheckStatus
    );

    let navigate = useNavigate();

    const passwordInputRef = useRef(null);

    const onChangeUserData = (event) => {
        const {name, value} = event.target;
        if (event.key === "Enter" && mode === "login") {
            if (name === "username" && userData.password === "") {
                passwordInputRef.current.focus();
                return;
            }
            onClickLoginButton();
        }
        if (event.key === "Enter" && mode !== "login") {
            onClickChangePasswordButton();
        }
        setUserData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const onClickLoginButton = async () => {
            //비어있는지 체크
            if (userData.username.trim() === "" || userData.password.trim() === "") {
                setErrorMessage(["password", "아이디 또는 비밀번호를 입력해주세요."]);
            } else {
                const loginData = `Basic ${btoa(`${userData.username.trim()}:${userData.password.trim()}`)}`;
                try {
                    const loginResponse = await requestForLogin.post(
                        "auth/loginProc",
                        {},
                        {
                            headers: {
                                Authorization: loginData,
                                "Content-Type": "application/json",
                            },
                        }
                    );
                    if (localStorage.getItem("isSave") === "true") {
                        localStorage.setItem(`userConInfo`, JSON.stringify(userData));
                    } else {
                        localStorage.removeItem(`userConInfo`);
                    }

                    localStorage.setItem("jwt", `Bearer ${loginResponse.data.data.jwt}`);
                    localStorage.setItem("name", loginResponse.data.data.name);
                    localStorage.setItem("uuid", loginResponse.data.data.uuid);
                    navigate("/");
                } catch (error) {
                    //계정 만료 code 2
                    if (error.response.data.code === "2") {
                        // 임시 토큰 값 저장하여 패스워드 변경
                        localStorage.setItem("jwt", `Bearer ${error.response.data.detail.jwt}`);
                        localStorage.setItem("name", error.response.data.detail.name);
                        setMode("changePassword");
                        sessionStorage.setItem("mode","changePassword");
                        sessionStorage.setItem("username",userData.username);
                        window.location.reload();

                    } else {
                        //code 1 아이디 또는 비밀번호 틀림
                        setErrorMessage(["password", error.response.data.msg]);
                    }
                }finally {
                    localStorage.setItem(`LoginData`, loginData);
                }

            }
    };

    const onClickChangePasswordButton = async () => {
        if (userData.password !== userData.checkPassword) {
            setErrorMessage(["password", "확인 비밀번호가 일치하지 않습니다."]);
            return;
        } else {
            if (!isValidPassword(userData.password)) {
                setErrorMessage(["password", "비밀번호는 영문 대소문자+숫자+특수문자 조합의 9-16자로 입력해주세요."]);
                return;
            }

            try {
                await request.put(`users-pwd-change/${userData.username}`, {
                    pwd: userData.password,
                    verify: userData.checkPassword,
                });
                setMessage({message: "비밀번호가 변경되었습니다."});
                localStorage.removeItem("jwt");
                localStorage.removeItem("name");
                setMode("login");
                const updatedUserData = {...userData};
                updatedUserData.password = "";
                updatedUserData.checkPassword = "";
                setUserData(updatedUserData);
                setErrorMessage([]);
            } catch (error) {
                if (error.response.status === 500) {
                    setAlert(error);
                } else {
                    setErrorMessage([
                        "password", error.response.data.msg,
                    ]);
                }
            }
        }
    };

    const onChangeCheckSaveInfo = (event) => {
        setIsCheckedSaveInfo(event);
        if (event === true) {
            localStorage.setItem("isSave", true);
        }
        if (event === false) {
            localStorage.setItem("isSave", false);
            localStorage.removeItem("userConInfo");
        }
    };
    useEffect(() => {
        if(sessionStorage.getItem("mode") ==="changePassword"){
            setMode("changePassword");
            const updatedUserData = {
                username: sessionStorage.getItem("username"),
                password:"",
                checkPassword: "",
            };
            setErrorMessage([]);
            setUserData(updatedUserData);

            sessionStorage.removeItem("username");
            sessionStorage.removeItem("mode");

        }

        if ( sessionStorage.getItem("message") === "중복로그인" ) {
            setMessage({
                message:
                    "다른 기기에서 로그인되었습니다.",
                color: MessageColor.RED,
            });

            sessionStorage.clear();
        }
    }, []);
    return (
        <>
            <div id="login">
                <h2 className="title">
                    {mode === "login" ? "로그인" : "패스워드 변경"}
                </h2>
                <ul>
                    <form>
                        <li>
                            <label className="name">아이디</label>
                            <Input
                                type="text"
                                name="username"
                                placeholder="아이디"
                                value={userData.username}
                                disabled={mode === "login" ? false : true}
                                onChange={onChangeUserData}
                                onKeyDown={onChangeUserData}
                                autoComplete="username"
                            />
                        </li>
                        <li>
                            <label className="name">비밀번호</label>
                            <input
                                type="password"
                                name="password"
                                autoComplete="current-password"
                                value={userData.password}
                                onChange={onChangeUserData}
                                onKeyDown={onChangeUserData}
                                placeholder="비밀번호"
                                ref={passwordInputRef}
                            />
                            {mode === "login" && errorMessage.length !== 0 && (
                                <p className="warning">{errorMessage[1]}</p>
                            )}
                        </li>
                        {mode !== "login" && (
                            <li>
                                <input
                                    type="password"
                                    id="new-password"
                                    name="checkPassword"
                                    autoComplete="current-password"
                                    value={userData.checkPassword}
                                    onChange={onChangeUserData}
                                    onKeyDown={onChangeUserData}
                                    placeholder="비밀번호 확인"
                                ></input>
                                {mode !== "login" && errorMessage.length !== 0 && (
                                    <p className="warning">{errorMessage[1]}</p>
                                )}
                            </li>
                        )}
                    </form>
                    <li>
                        <input
                            id="check_btn"
                            className="check_btn"
                            type="checkbox"
                            checked={isCheckedSaveInfo}
                            onChange={({target: {checked}}) =>
                                onChangeCheckSaveInfo(checked)
                            }
                        />
                        {mode === "login" && (
                            <label htmlFor="check_btn">아이디/비밀번호 저장</label>
                        )}
                    </li>
                    <label htmlFor="check_btn">
                        * 본 페이지는 1920x1080에 최적화 되어있습니다.
                    </label>
                </ul>
                <button
                    className="btn btn_blue"
                    onClick={
                        mode === "login" ? onClickLoginButton : onClickChangePasswordButton
                    }
                >
                    {mode === "login" ? "로그인" : "확인"}
                </button>
            </div>
        </>
    );
};

export default Login;
